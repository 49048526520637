import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,  
  ModalFooter,
  FormGroup,
  Input,
  Button,
  Form,
} from "reactstrap";

const FormComponent = ({updateValue, inputData}) => {

  console.log("inputData", inputData)

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="table-shorting">
                <Form>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                                      <div className="mt-5 mt-lg-4">
                                      <Form>
                        <Row>
                          <Col lg={4}>
                            <FormGroup>
                              <Label for="startDate">Start Date:</Label>
                              <Input type="date" id="startDate" value={inputData?.dateStart} onChange={(e) => updateValue('dateStart', e.target.value)} name="startDate" />
                            </FormGroup>
                          </Col>
                          <Col lg={4}>
                            <FormGroup>
                              <Label for="endDate">End Date:</Label>
                              <Input type="date" id="endDate"  value={inputData?.dateEnd} onChange={(e) => updateValue('dateEnd', e.target.value)} name="endDate" />
                            </FormGroup>
                          </Col>
                          <Col lg={4}>
                            <FormGroup>
                              <Label for="interval">Interval:</Label>
                              <Input value={inputData?.interval} onChange={(e) => updateValue('interval', e.target.value)}  type="select" id="interval" name="interval">
                                <option value="day">Day</option>
                                <option value="month">Month</option>
                                <option value="year">Year</option>
                              </Input>
                            </FormGroup>
                          </Col>
                        </Row>
                      </Form>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const CustomDateModal = ({ show, closeModal, updateValue, onSubmit, inputData }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Select Date Range
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <FormComponent inputData={inputData} updateValue={updateValue}/>
      </div>
      <ModalFooter>
        <Button color="info" onClick={onSubmit}>Submit</Button>
      </ModalFooter>
    </Modal>
  );
};

export default CustomDateModal;
