import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";

//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
  putRequestForm,
  postRequestForm,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

const filterObj = {
  title: "",
  description: "",
  city: "",
  categoryIds: "",
  bookingType: "",
  subCategoryIds: "",
  createdAt: "",
};

export default function ItineraryManagementlist() {
  const [modal_center, setmodal_center] = useState(false);
  const [ratingdata, setratingdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [value, setValue] = React.useState(10);

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });
  const [canExport, setCanExport] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [categoryOptions, setCategoryData] = useState([]);
  const [CategoryCity, setCategoryCity] = useState([]);
  const [subCategoryOptions, setSubCategoryData] = useState([]);

  const GetValueOnChange = async (value, _id) => {
    let actId = _id;
    let status = value == 1 ? 1 : 0;
    // const formData = new FormData();
    // formData.append("customTicketId", actId);
    // formData.append("status", status);
    let payload = {
      customTicketId: actId,
      status,
    };

    const res = await putRequest("ticket/custom/update", payload);

    if (res.status == 1) {
      toast.success("Custom Ticket Status updated Successfully");
      CategorylistManagment();
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  const CategorylistFilter = async () => {
    const payload = {
      limit: 100000,
      page: 1,
    };
    try {
      const response = await postRequest(`ticket/category/list`, payload);
      const data = response.data;
      let filter = data.list.map((item) => ({
        name: item?.name,
        id: item?._id,
      }));
      setCategoryData(filter);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
    }
  };

  const CategoryCityFilter = async () => {
    try {
      const response = await getRequest("admin/get-cities");
      const data = response?.data;
      console.log("Data City", { data });
      let filter = data?.map((item) => ({
        name: item?.name,
        id: item?.name,
      }));
      setCategoryCity(filter);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
    }
  };
  const SubCategorylistManagment = async () => {
    const payload = {
      limit: 100000,
      page: 1,
    };
    try {
      const response = await postRequest(`ticket/sub-category/list`, payload);
      const data = response.data;
      let filter = data.list.map((item) => ({
        name: item?.name,
        id: item?._id,
      }));
      setSubCategoryData(filter);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
    }
  };
  useEffect(() => {
    CategorylistFilter();
    CategoryCityFilter();
    SubCategorylistManagment();
  }, []);

  useEffect(() => {
    CategorylistManagment();
  }, [currentPage]);

  useEffect(() => {
    CategorylistManagment();
  }, [sort, sortOrder]);

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }

    console.log("type", type);

    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (
          key === "city" ||
          key === "title" ||
          key === "bookingType" ||
          key == "description"
        ) {
          filterObject.type = "regex";
        } else if (key == "createdAt") {
          filterObject.type = "date-eq";
        } else if (key == "categoryIds") {
          filterObject.type = "objectId";
        } else if (key == "subCategoryIds") {
          filterObject.type = "objectId";
        }

        filterArgs.push(filterObject);
      }
    });

    console.log("filterArgs 444", filterArgs);
    toggleModel();
    CategorylistManagment(filterArgs);
  };

  const getFilters = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (
          key === "city" ||
          key === "title" ||
          key === "bookingType" ||
          key == "description"
        ) {
          filterObject.type = "regex";
        } else if (key == "createdAt") {
          filterObject.type = "date-eq";
        } else if (key == "categoryIds") {
          filterObject.type = "objectId";
        } else if (key == "subCategoryIds") {
          filterObject.type = "objectId";
        }

        filterArgs.push(filterObject);
      }
    });

    console.log("filterArgs", filterArgs);
    toggleModel();
    CategorylistManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    CategorylistManagment();
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function activeFormatter(cellContent, row) {
    return (
      <>
        {row.status == 1 ? (
          <>
            <button
              className="btn btn-active"
              type="button"
              onClick={() => GetValueOnChange((row.status = false), row._id)}
            >
              Active
            </button>
          </>
        ) : (
          <>
            <button
              className="btn btn-deactive"
              type="button"
              onClick={() => GetValueOnChange((row.status = true), row._id)}
            >
              Inactive
            </button>
          </>
        )}
      </>
    );
  }

  function createdAtdata(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p>{moment(row.createdAt).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }
  const components = [
    <div className="row mb-4" key="amount">
      <label className="col-sm-3 col-form-label">title</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.title}
          onChange={(e) => updateFilter("title", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Description</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={filter.Description}
          onChange={(e) => updateFilter("description", e.target.value)}
          name="description"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Type</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("bookingType", e.target.value)}
          className="form-select"
          value={filter.bookingType}
        >
          <option value="">Select Type</option>
          <option value="elevate">Elevate</option>
          <option value="juniper">juniper</option>
        </select>
      </div>
    </div>,

    <div className="row mb-4">
      <label className="col-sm-3 col-form-label">City</label>

      <div className="col-sm-6">
        <select
          className="form-control"
          onChange={(e) => updateFilter("city", e.target.value)}
          name="customCategoryId"
          class="form-select"
        >
          <option value="">Select City</option>
          {CategoryCity?.map((option) => {
            return <option value={option.id}>{option.name}</option>;
          })}
        </select>
      </div>
    </div>,
    <div className="row mb-4">
      <label className="col-sm-3 col-form-label">Category</label>

      <div className="col-sm-6">
        <select
          className="form-control"
          onChange={(e) => updateFilter("categoryIds", e.target.value)}
          name="categoryIds"
          class="form-select"
        >
          <option value="">Select Category</option>
          {categoryOptions.map((option) => {
            return <option value={option.id}>{option.name}</option>;
          })}
        </select>
      </div>
    </div>,
    <div className="row mb-4">
      <label className="col-sm-3 col-form-label"> Sub Category</label>

      <div className="col-sm-6">
        <select
          className="form-control"
          onChange={(e) => updateFilter("subCategoryIds", e.target.value)}
          name="subCategoryIds"
          class="form-select"
        >
          <option value="">Select Sub Category</option>
          {subCategoryOptions.map((option) => {
            return <option value={option.id}>{option.name}</option>;
          })}
        </select>
      </div>
    </div>,

    //  <div className="row mb-4" key="title">
    //  <label className="col-sm-3 col-form-label">Type</label>
    //  <div className="col-sm-6">
    //    <input
    //      type="text"
    //      value={ filter.bookingType }
    //      onChange={(e) => updateFilter("bookingType", e.target.value)}
    //      name="type"
    //      className="form-control"
    //    />
    //  </div>
    // </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={filter.createdAt}
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const stripHtmlTags = (html) => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "ticketName",

      text: "Ticket Name",
      sort: false,
    },

    {
      dataField: "images",
      formatter: userFormatter,
      text: "Images",
      sort: false,
    },

    {
      dataField: "createdAt",
      text: "Created At",
      formatter: createdAtdata,
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  function userFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <img
          src={row?.images?.[0] || ""}
          onClick={() => showLightBox(row?.images?.[0] || "")}
          alt="Image"
          width={"50px"}
          style={{ borderRadius: "20%" }} // This makes the image round
        />
        <p>{row.userName}</p>
      </div>
    );
  }

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
    if (currentPage != 1) {
      setCurrentPage(1);
    } else {
      CategorylistManagment(null, event.target.value);
    }
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setSearchText("");
    CategorylistManagment(null, null, true);
  };

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex align-items-center justify-content-center">
          <button type="button" className=" btn btn-info  mx-2">
            <Link
              to={{
                pathname: `/Itinerary/update/${row._id}`,
                state: { row },
              }}
            >
              <i className="fal fa-pencil fs-5 text-light"></i>
            </Link>
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i className="fal fa-trash  fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const exportCSV = async () => {
    setLoader(true);

    const filterArgs = getFilters();
    const payload = {
      sortField: sort,
      sortOrder: sortOrder,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }

    try {
      const response = await postRequest(`cron/export-csv`, payload);
      const data = response.data;
      window.location.href = data.url;
      console.log("data", data);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const CategorylistManagment = async (
    filterArgs = [],
    limit = null,
    isReload = false
  ) => {
    console.log(currentPage);
    setLoader(true);
    const payload = {
      limit: limit ? limit : sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
    };
    if (!isReload) {
      if (filterArgs?.length) {
        payload.filterArgs = filterArgs;
      } else {
        if (searchText) {
          payload.search = searchText;
        }
      }
    }
    try {
      const response = await postRequest(`user/get-itinerary-images`, payload);
      const data = response.data;
      setTotalPage(data?.count);
      setCurrentFPage(data?.page);
      setratingdata(data?.images);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`user/delete-itinerary-images`, {
           id,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          CategorylistManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Ticket Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Ticket Management</li>:{" "}
                <li className="breadcrumb-item">Itinerary Images List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-4 text-end">
            {canExport && (
              <button
                onClick={() => {
                  exportCSV();
                }}
                type="button"
                className="btn btn-primary mx-2"
              >
                <i class="fas fa-upload"></i> Export CSV
              </button>
            )}

            <Link to="/Itinerary/create">
              <Button>
                <i className="fas fa-plus"></i> Create
              </Button>
            </Link>
          </div>

          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={ratingdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>
                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "7%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="ticketName">
                                      {" "}
                                      Ticket Name
                                    </option>

                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                    value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        CategorylistManagment();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger mx-2"
                                    >
                                      Reset
                                    </button>

                                    {/* <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info"
                                    >
                                      Filter
                                    </button> */}
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    remote={true}
                                    classes="table-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
}
