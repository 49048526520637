import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const authType = localStorage.getItem("authType");
  let authRoles = localStorage.getItem("adminRole");

  try {
    if (authRoles) {
      authRoles = JSON.parse(authRoles);
    }
  } catch (error) {
    authRoles = [];
  }

  const ref = useRef();

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {/* <li className="menu-title">{props.t("Main")} </li> */}

            {authType === "agent" ? (
              <>
                <li>
                  <Link to="/agent/dashboard" className="waves-effect">
                    <i className="mdi mdi-view-dashboard"></i>
                    <span>{props.t("Agent Dashboard")}</span>
                  </Link>
                </li>

                {authRoles[0].allow == true && (
                  <li>
                    <Link to="/dashboard" className="waves-effect">
                      <i className="mdi mdi-view-dashboard"></i>
                      {/* <span className="badge rounded-pill bg-primary float-end">
                    2
                  </span> */}
                      <span>{props.t("Main Dashboard")}</span>
                    </Link>
                  </li>
                )}

                {authRoles[1].allow == true && (
                  <li>
                    <Link to="/users" className=" waves-effect">
                      <i className="mdi mdi-account"></i>
                      <span>{props.t("User Management")}</span>
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/deleteAccount" className=" waves-effect">
                    <i className="mdi mdi-account"></i>
                    <span>{props.t("Delete Account Request")}</span>
                  </Link>
                </li>

                {authRoles[2].allow == true && (
                  <li>
                    <Link to="/Component" className="waves-effect">
                      <i className="mdi mdi-city"></i>
                      <span>{props.t("Component ")}</span>
                    </Link>
                  </li>
                )}

                {authRoles[3].allow == true && (
                  <li>
                    <Link to="/homeblock" className="waves-effect">
                      <i className="mdi mdi-cube-outline"></i>
                      <span>{props.t("Homeblocks ")}</span>
                    </Link>
                  </li>
                )}

                {authRoles[4].allow == true && (
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="mdi mdi-apps"></i>
                      <span>{props.t("Hotel Management")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/hotels" className="waves-effect">
                          {/* <i className="mdi mdi-cube-outline"></i> */}
                          <span>{props.t("Hotels")}</span>
                        </Link>
                      </li>

                      {authRoles[4].pageAccess[4].allow == true && (
                        <li>
                          <Link to="/hotelroom" className=" waves-effect">
                            <span>{props.t("Hotel Room")}</span>
                          </Link>
                        </li>
                      )}

                      {/* {authRoles[4].pageAccess[5].allow == true && (
                        <li>
                          <Link to="/hotelBookings" className="waves-effect">
                            <span>{props.t("Bookings")}</span>
                          </Link>
                        </li>
                      )} */}

                      {authRoles[4].pageAccess[7].allow == true && (
                        <li>
                          <Link to="/hotelratings" className="waves-effect">
                            <span>{props.t("Reviews ")}</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}

                {authRoles[5].allow == true && (
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="mdi mdi-cube-outline"></i>
                      <span>{props.t("Ticket Management")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/tickets" className="waves-effect">
                          {/* <i className="mdi mdi-cube-outline"></i> */}
                          <span>{props.t("Tickets")}</span>
                        </Link>
                      </li>
                      {/* 
                      {authRoles[5].pageAccess[3].allow == true && (
                        <li>
                          <Link to="/ticketBookings" className="waves-effect">
                            <span>{props.t("Bookings")}</span>
                          </Link>
                        </li>
                      )} */}

                      {authRoles[5].pageAccess[5].allow == true && (
                        <li>
                          <Link to="/ticketratings" className="waves-effect">
                            {/* <i className="mdi mdi-star"></i> */}
                            <span>{props.t("Reviews ")}</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}

                {authRoles[6].allow == true && (
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="mdi mdi-cube-outline"></i>
                      <span>{props.t("Transfer Management")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/transfers" className="waves-effect">
                          {/* <i className="mdi mdi-cube-outline"></i> */}
                          <span>{props.t("Transfers")}</span>
                        </Link>
                      </li>
                      {/* 
                      {authRoles[6].pageAccess[3].allow == true && (
                        <li>
                          <Link to="/transferBookings" className="waves-effect">
                            <span>{props.t("Bookings")}</span>
                          </Link>
                        </li>
                      )} */}

                      {authRoles[6].pageAccess[5].allow == true && (
                        <li>
                          <Link to="/transferratings" className="waves-effect">
                            {/* <i className="mdi mdi-star"></i> */}
                            <span>{props.t("Reviews ")}</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}

                {authRoles[7].allow == true && (
                  <li>
                    <Link to="/Category" className="waves-effect">
                      <i className="mdi mdi-city"></i>
                      <span>{props.t("Category")}</span>
                    </Link>
                  </li>
                )}

                {authRoles[8].allow == true && (
                  <li>
                    <Link to="/SubAdminlist" className="waves-effect">
                      <i className="mdi mdi-city"></i>
                      <span>{props.t("Sub Admin")}</span>
                    </Link>
                  </li>
                )}

                {authRoles[9].allow == true && (
                  <li>
                    <Link to="/notification" className=" waves-effect">
                      <i className="mdi mdi-bell-ring"></i>
                      <span>{props.t("Notifications")}</span>
                    </Link>
                  </li>
                )}
                {authRoles[11].allow == true && (
                  <li>
                    <Link to="/app-transaction" className=" waves-effect">
                      <i className="mdi mdi-bell-ring"></i>
                      <span>{props.t("App Transactions")}</span>
                    </Link>
                  </li>
                )}

                {authRoles[10].allow == true && (
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i class="fas fa-comments"></i>
                      <span>{props.t("Complaint")}</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/conversation" className=" waves-effect">
                          <span>{props.t("Complaint List")}</span>
                        </Link>
                      </li>

                      {authRoles[10].pageAccess[3].allow == true && (
                        <li>
                          <Link to="/autoresponder" className=" waves-effect">
                            <span>{props.t("Auto Responder")}</span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                )}

                <li>
                  <Link to="/agent/conversation" className="waves-effect">
                    <i className="mdi mdi-view-dashboard"></i>
                    <span>{props.t("Agent Conversation")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/agent/chat-window" className="waves-effect">
                    <i className="mdi mdi-view-dashboard"></i>
                    <span>{props.t("Complaint Management")}</span>
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link to="/dashboard" className="waves-effect">
                    <i className="mdi mdi-view-dashboard"></i>
                    {/* <span className="badge rounded-pill bg-primary float-end">
                  2
                </span> */}
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/banner" className=" waves-effect">
                    <i className="mdi mdi-map"></i>
                    <span>{props.t("Banners")}</span>
                  </Link>
                </li>

                {/* 
            <li>
              <Link to="/deals" className=" waves-effect">
                <i className="mdi mdi-map"></i>
                <span>{props.t("Deals")}</span>
              </Link>              
            </li>

            <li>
              <Link to="/DealBookingList" className=" waves-effect">
                <i className="mdi mdi-map"></i>
                <span>{props.t("Deals Management ")}</span>
              </Link>              
            </li> */}


                <li>
                  <Link to="/users" className=" waves-effect">
                    <i className="mdi mdi-account"></i>
                    <span>{props.t("User Management")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/deleteAccount" className=" waves-effect">
                    <i className="mdi mdi-account"></i>
                    <span>{props.t("Delete Account Request")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/Icon-management-list" className=" waves-effect">
                    {/* <i className="mdi mdi-account"></i> */}
                    <i className="fa-solid fa-icons"></i>
                    <span>{props.t("Icon management")}</span>
                  </Link>
                </li>

                {/* <li>
                  <Link to="/Component" className="waves-effect">
                    <i className="mdi mdi-city"></i>
                    <span>{props.t("Component ")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/homeblock" className="waves-effect">
                    <i className="mdi mdi-cube-outline"></i>
                    <span>{props.t("Homeblocks ")}</span>
                  </Link>
                </li> */}

                <li>
                  <Link to="/homeblock" className="has-arrow waves-effect">
                    <i className="mdi mdi-cube-outline"></i>
                    <span>{props.t("Homeblocks Manage..")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/homeblock" className="waves-effect">
                        {/* <i className="mdi mdi-calendar"></i> */}
                        <span>{props.t("Home Blocks")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/search-block" className="waves-effect">
                        {/* <i className="mdi mdi-calendar"></i> */}
                        <span>{props.t("Search Blocks")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/Component" className="waves-effect">
                        {/* <i className="mdi mdi-calendar"></i> */}
                        <span>{props.t("Component")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/blocksize" className=" waves-effect">
                        <span>{props.t("Size Management")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>




                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-calendar"></i>
                    <span>{props.t("Booking Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/bookings" className="waves-effect">
                        {/* <i className="mdi mdi-calendar"></i> */}
                        <span>{props.t("Juniper Bookings")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/elevatebookings" className="waves-effect">
                        {/* <i className="mdi mdi-calendar"></i> */}
                        <span>{props.t("Elevate Bookings")}</span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/orders" className="waves-effect">
                        <i className="mdi mdi-calendar"></i>
                        <span>{props.t("Orders")}</span>
                      </Link>
                    </li> */}
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-apps"></i>
                    <span>{props.t("Hotel Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/hotels" className="waves-effect">
                        {/* <i className="mdi mdi-cube-outline"></i> */}
                        <span>{props.t("Hotels")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/hotelroom" className=" waves-effect">
                        <span>{props.t("Hotel Room")}</span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/hotelBookings" className="waves-effect">
                        <span>{props.t("Bookings")}</span>
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/hotelratings" className="waves-effect">
                        <span>{props.t("Reviews ")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>



                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-map"></i>
                    <span>{props.t("Deals Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/deals" className="waves-effect">
                        {/* <i className="mdi mdi-calendar"></i> */}
                        <span>{props.t("Deals")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/DealBookingList" className="waves-effect">
                        {/* <i className="mdi mdi-calendar"></i> */}
                        <span>{props.t(" Deal Booking ")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>




                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-cube-outline"></i>
                    <span>{props.t("Ticket Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/tickets" className="waves-effect">
                        {/* <i className="mdi mdi-cube-outline"></i> */}
                        <span>{props.t("Tickets")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/custom/tickets" className="waves-effect">
                        <span>{props.t("Custom Ticket ")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/CategoryList" className="waves-effect">
                        <span>{props.t("Category")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/SubCategoryList" className="waves-effect">
                        <span>{props.t("SubCategory")}</span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/ticketBookings" className="waves-effect">
                        <span>{props.t("Bookings")}</span>
                      </Link>
                    </li> */}

                    <li>
                      <Link to="/ticketratings" className="waves-effect">
                        {/* <i className="mdi mdi-star"></i> */}
                        <span>{props.t("Reviews ")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/Itinerary/list" className="waves-effect">
                        {/* <i className="mdi mdi-star"></i> */}
                        <span>{props.t("Itinerary Images")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="mdi mdi-cube-outline"></i>
                    <span>{props.t("Transfer Management")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/transfers" className="waves-effect">
                        {/* <i className="mdi mdi-cube-outline"></i> */}
                        <span>{props.t("Transfers")}</span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/transferBookings" className="waves-effect">
                        <span>{props.t("Bookings")}</span>
                      </Link>
                    </li> */}
                    <li>
                      <Link to="/transferratings" className="waves-effect">
                        {/* <i className="mdi mdi-star"></i> */}
                        <span>{props.t("Reviews ")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>

                {/* <li>
              <Link to="/Category" className="waves-effect">
                <i className="mdi mdi-city"></i>
                <span>{props.t("Category")}</span>
              </Link>       
            </li> */}

                <li>
                  <Link to="/notification" className=" waves-effect">
                    <i className="mdi mdi-bell-ring"></i>
                    <span>{props.t("Notifications")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/app-transaction" className=" waves-effect">
                    <i className="mdi mdi-cash"></i>
                    <span>{props.t("App Transactions")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i class="fas fa-comments"></i>
                    <span>{props.t("Customer Support")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/conversation" className=" waves-effect">
                        {/* <i class="fas fa-comments"></i> */}
                        <span>{props.t("Live Chat")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/autoresponder" className=" waves-effect">
                        <span>{props.t("Auto Responder")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <Link to="/SubAdminlist" className="waves-effect">
                    <i className="mdi mdi-city"></i>
                    <span>{props.t("Sub Admin")}</span>
                  </Link>
                </li>
                <li>
                  <Link to="/FeedBackList" className="waves-effect">
                    <i className="mdi mdi-city"></i>
                    <span>{props.t("FeedBack")}</span>
                  </Link>
                </li>

                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-cog"></i>
                    <span>{props.t("Settings")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/jp" className=" waves-effect">
                        <span>{props.t("JP Setting")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/app-theme" className=" waves-effect">
                        <span>{props.t("App Theme")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>

                <li>
                  <ul className="sub-menu" aria-expanded="false"></ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
