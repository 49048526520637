import React, { useState, useEffect } from "react";
import { useHistory,useParams, useLocation  } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, FormGroup, Form, Table, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  postRequestForm, postRequest } from "../../components/Common/Utils.js";

export default function CreateMenu() {
  
  const location = useLocation();
  const state = location.state?.row;
  
  const [previewCover, setPreviewCover] = useState("");
  const [menuData, setMenuData] = useState(null);
  
  const [loading, setLoading] = useState(false);  
  const [venueOptions, setVenueOptions] = useState([
    { value: "", label: "None" },
  ]);

 
  const [selectedCover, setSelectedCover] = useState(""); 

  let history = useHistory();
 

  const { id } = useParams(); 
  
  
  useEffect(() => {
    if(!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result)
    }
    reader.readAsDataURL(selectedCover)
  }, [selectedCover])
  
  useEffect(() => {
    fetchList();
   }, []);
  
 
  

  const Createmenu = async (e) => {
    
    e.preventDefault();
   setLoading(true);
  
    const formData = new FormData();
    formData.append("venueId", id);
    formData.append("title", menuData.title);   
    formData.append("image", selectedCover);   
    
   
    
    const response = await postRequestForm("venue/menu/add", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/menu/" + id);
      }, 1000);
      
    } else {
      toast.error(response.message);
    }  
    
  };

  const fetchList = async () => {
    setLoading(true);
    const param = { page: 1, limit: 100000 };
    const venue = await postRequest("venue/list", param);    
    if (venue.status == 1) {     
      const options = venue.data.list.map((item) => {
        return { value: item._id, label: item.name };
      });

      
      setVenueOptions(options);
    }
    
   
    setLoading(false);
 }

  const updateValue = (index, value) => {
    const list = {...menuData}
    list[index] = value
    setMenuData(list)
  } 
    
 
    

  return (
    <React.Fragment>

      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Menu Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Menu Management</li>:{" "}
              <li className="breadcrumb-item">
                Create Menu
              </li>
            </ol>
          </div>
        </div>
      </div>
     
      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>

          { loading ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :

            <CardBody>
              <h4 className="card-title"> Create Menu </h4>
              <Form onSubmit={Createmenu}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">                   
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Title</label>
                            <div className="col-sm-9">
                            <input type="text" onChange={ (e) => updateValue('title', e.target.value) } name='title'  className="form-control"  />
                            </div>
                        </div>                         

                        
                        <div className="row mb-4">
                            <label htmlFor="horizontal-password-input" className="col-sm-3 col-form-label">Image</label>
                            <div className="col-sm-9">
                            <input type="file" onChange={(e) => setSelectedCover(e.target.files[0])}  name='cover' className="form-control" id="horizontal-password-input" required="required" />
                           <br/>
                            {previewCover && (                            
                                  <img src={previewCover} style={{width: "100px", height: "100px"}}/>                              
                              )}
                            </div>                           
                        </div>   

                       
                        <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                            <div className="col-sm-9">
                            <div>
                                <button type="submit"  className="btn btn-primary w-md">Submit</button>
                            </div>
                            </div>
                            </div>
                        </div>

                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody> }
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
