import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { postRequest, postRequestForm } from "../../components/Common/Utils.js";

import HotelModal from "../../components/Modal/HotelModal.js";
import TicketModal from "components/Modal/TicketModal.js";
import TransferModal from "components/Modal/TransferModal.js";
import DealModal from "components/Modal/DealModal.js";
import MultipleSelectUserList from "components/Modal/MultipleUserSelect.js";
import SelectedUsers from "./SelectedUsers.js";
import CustomTicketlistModal from "components/Modal/CustomTicketlistModal.js";

export default function CreateNotification() {
  const [notificationData, setNotificationData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(new Date()); 
  const [selectedTarget, setSelectedTarget] = useState([]);
  const [userModel, setUserModel] = useState(false);
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedImage, setSelectedImage] = useState(""); 
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [model, setModel] = useState(false);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState(""); 
  const [offerModel, setOfferModel] = useState(false);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [activityModel, setActivityModel] = useState(false);
  const [eventId, setEventId] = useState("");
  const [eventText, setEventText] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [eventModel, setEventModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [VenueId, setVenueId] = useState("");


  let history = useHistory();

  const msUpdateTarget = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedTarget(ids);
  };

  const updateNotificationData = async (e) => {
    e.preventDefault();
    if (!notificationData.title) {
      toast.error("Please enter title");
      return;
    }
    setLoading(true);
    const x = new FormData();
    x.append("image", selectedImage);
    const imgRes = await postRequestForm("homepage/upload/image", x);
    const dataObj = {
      title: notificationData.title,
      titleFrench: notificationData.titleFrench,
      titleGerman: notificationData.titleGerman,
      description: notificationData.description,
      descriptionFrench: notificationData.descriptionFrench,
      descriptionGerman: notificationData.descriptionGerman,
      scheduled: notificationData.scheduled,
      platform: notificationData.platform,
      type: notificationData.type,
      targetUser: notificationData.targetUser,
      status: notificationData.status,
      image: imgRes?.data.url,
      createdBy : "admin"
    };

    if (notificationData.scheduled === 1) {
      dataObj.date = notificationData.date;
      dataObj.time = notificationData.time;
    }

    if (notificationData.type === "link") {
      dataObj.link = notificationData.link;
    }
    if (notificationData.targetUser === "all") {
      dataObj.users = [];
    }
    if (notificationData.targetUser === "specific") {
      const users = selectedTarget.map((item) => item?.id);
      dataObj.users = users;
    } 

    if (notificationData.type === "ticket" || notificationData.type === "hotel" || notificationData.type === "transfer" ||notificationData.type === "deal") {
      dataObj.selectedId = selectedId;
    }
    const response = await postRequest("notification", dataObj);
    setLoading(false);
    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/notification");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  // useEffect(() => {
  //   if (!selectedImage) return;
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setNotificationData({...notificationData, image: reader.result});
  //     setPreviewImage(reader.result);
  //   };
  //   reader.readAsDataURL(selectedImage);
  // }, [selectedImage]);

  const updateValue = (index, value) => {
    const list = { ...notificationData };
    list[index] = value;
    setNotificationData(list);
  };

  const toggleUserModel = () => {
    console.log("toggleUserModel", userModel);
    setUserModel(!userModel);
  };

  const closeUserModal = () => {
    setUserModel(false);
  };

  const popSelectedUser = (id) => {
    const newList = selectedUserIds.filter((item) => item._id !== id);

    console.log("newList", newList, selectedUserIds);
    setSelectedUserIds(newList);
  };

  const onSelectedUser = (selectedList) => {
    setSelectedUserIds(selectedList);
  };

  const selectUserRow = (id, name) => {
    console.log("selectUserRow", id, name);

    setUserId(id);
    setUserText(name);
    closeUserModal();
  };
  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };

  const closeModal = () => {
    setModel(false);
  };
  const toggleOfferModel = () => {
    console.log("toggleOfferModel", offerModel);
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const toggleActivityModel = () => {
    console.log("toggleActivityModel", activityModel);
    setActivityModel(!activityModel);
  };
  const closeActivityModal = () => {
    setActivityModel(false);
  };

  const toggleEventModel = () => {
    console.log("toggleEventModel", eventModel);
    setEventModel(!eventModel);
  };
  const closeEventModal = () => {
    setEventModel(false);
  };

  const selectRow = (id) => {
    setVenueImage(id?.coverImage);
    setVenueText(id?.name);
    setSelectedId(id?._id);  
    closeModal();
  };

  const selectOfferRow = (offer) => {
    console.log(offer)
    closeOfferModal()
     setSelectedId(offer?._id); 
    setVenueId(offer._id);
    setOfferText(offer?.title);
    setOfferImage(offer?.image);
    const formInput = { ...bannerData };
    formInput.item = offer?._id;   
   
  };

  const selectActivityRow = (id, name, activity) => {
    setVenueId(id);
    console.log({ id, name, activity });
    setSelectedId(id?._id);  
    setActivityText(id?.title);
    setActivityImage(id?.images[0] || "");
    console.log("id", id);
    // const formInput = { ...bannerData };
    // formInput.item = id;
    // if (bannerData.title == "") formInput.title = name;
    // if (bannerData.description == "") formInput.description = id?.description;
    // formInput.subHeading = id?.name;
    // formInput.subText = id?.address;
    // formInput.buttonText = "View";
    // formInput.logo = id?.logo;
    // formInput.background = id?.images?.length ? id?.images[0] : "";
    // setBannerData(formInput);
    // setPreviewImage(activity?.galleries?.length ? activity?.galleries[0] : "");
    // setPreviewLogo(activity?.provider?.logo);
    closeActivityModal();
  };

  const selectEventRow = (id) => {
    setEventText(id?.name);   
    setEventImage(id?.coverImage);
    setSelectedId(id?._id); 
    closeEventModal();
  };


  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Notification Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Notification Management
              </li>
              :{" "}
              <li className="breadcrumb-item">
                <Link to="#">Notification create</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title ms-5">Notification Create </h4>
                <Form onSubmit={updateNotificationData}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title (English)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              defaultValue={notificationData?.title}
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title (French)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("titleFrench", e.target.value)
                              }
                              name="titleFrench"
                              defaultValue={notificationData?.titleFrench}
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title (German)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("titleGerman", e.target.value)
                              }
                              name="titleGerman"
                              defaultValue={notificationData?.titleGerman}
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>



                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Description (English)
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              name="description"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Description (French)
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("descriptionFrench", e.target.value)
                              }
                              name="descriptionFrench"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Description (German)
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("descriptionGerman", e.target.value)
                              }
                              name="descriptionGerman"
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>
                        
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>                       
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Scheduled
                          </label>
                          <div className="col-sm-9">
                            <select
                              value={notificationData?.scheduled}
                              onChange={(e) =>
                                updateValue("scheduled", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>
                        {notificationData?.scheduled === "true" && (
                          <>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Push Date
                              </label>
                              <div className="col-sm-9">
                                <DatePicker
                                  type="text"
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                  name="pushDate"
                                  className="form-control"
                                  id="horizontal-firstname-input"
                                />
                              </div>
                            </div>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-firstname-input"
                                className="col-sm-3 col-form-label"
                              >
                                Push Time
                              </label>
                              <div className="col-sm-9">
                              <DatePicker
                                selected={startTime}
                                onChange={(date) => setStartTime(date)}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeCaption="Time"
                                dateFormat="h:mm aa"
                                name="pushTime"
                                className="form-control"
                                id="horizontal-time-input"
                              />
                              </div>
                            </div>
                          </>
                        )}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Platform
                          </label>
                          <div className="col-sm-9">
                            <select
                              onChange={(e) =>
                                updateValue("platform", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="">All</option>
                              <option value="android">Android</option>
                              <option value="ios">IOS</option>
                              <option value="huawei">Huawei</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-9">
                            <select
                              value={notificationData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="">Select Type</option>    
                              <option value="general">General</option>                         
                              <option value="link">Link</option>                             
                              <option value="ticket">Ticket</option> 
                              <option value="hotel">Hotel</option>
                              <option value="transfer">Transfer</option>
                              <option value="deal">Deal</option>
                            </select>
                          </div>
                        </div> 

                        {notificationData?.type === "link" && (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Link
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("link", e.target.value)
                                }
                                defaultValue={notificationData?.link}
                                name="link"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                         {notificationData?.type == "ticket" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Ticket
                            </label>
                            <div
                              className="col-sm-9"
                              onClick={() => toggleActivityModel()}
                            >
                              {activityText ? (                               

                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleActivityModel()}
                                  >
                                    Choose Ticket
                                  </button>
                                  {/* <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div> */}
                                  <img
                                    src={activityImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={activityText}
                                    id="selectedVenue"
                                    placeholder="No Ticket chosen"
                                  />                                 
                                </div>
                              ) : (                             

                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleActivityModel()}
                                  >
                                    Choose Ticket
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No Ticket chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )} 
                         {notificationData?.type == "hotel" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Hotel
                            </label>

                            <div
                              className="col-sm-9"
                              onClick={() => toggleModel()}
                            >
                              {venueText ? (
                               
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Hotel
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedOffer"
                                    placeholder="No Hotel chosen"
                                  />
                                 
                                </div>
                              ) : (
                               
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Hotel
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedOffer"
                                    placeholder="No Hotel chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )} 
                        {notificationData?.type == "transfer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Transfer
                            </label>

                            <div

                              className="col-sm-9"
                              onClick={() => toggleEventModel()}
                            >
                              {eventText ? (

                                <div class="input-group">
                                  <button

                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",

                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() =>toggleEventModel()}
                                  >
                                    Choose Transfer
                                  </button>
                                  <div

                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img

                                    src={eventImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={eventText}
                                    id="selectedOffer"
                                    placeholder="No Transfer chosen"
                                  />

                                </div>
                              ) : (

                                <div class="input-group">
                                  <button


                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",

                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleEventModel()}
                                  >
                                    Choose Transfer
                                  </button>
                                  <input

                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={eventText}
                                    id="selectedOffer"
                                    placeholder="No Transfer chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                         {notificationData?.type == "deal" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Deal
                            </label>

                            <div

                              className="col-sm-9"
                              onClick={() => toggleOfferModel()}
                            >
                              {offerText ? (

                                <div class="input-group">
                                  <button

                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",

                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() =>toggleOfferModel()}
                                  >
                                    Choose Deal
                                  </button>
                                  <div

                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img

                                    src={offerImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No Transfer chosen"
                                  />

                                </div>
                              ) : (

                                <div class="input-group">
                                  <button


                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",

                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Deal
                                  </button>
                                  <input

                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No Deal chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Target:User
                          </label>
                          <div className="col-sm-9">
                            <select
                              onChange={(e) =>
                                updateValue("targetUser", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="">Select</option>
                              <option value="all">All User</option>
                              <option value="specific">Specific User</option>
                            </select>
                          </div>
                        </div>
                       
                        {notificationData?.targetUser === "specific" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Users
                            </label>
                            <div
                              className="col-sm-9"
                              onClick={() => toggleUserModel()}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Select User
                                  </a>
                                </div>
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            </div>                            
                          </div>
                        )}

                       {selectedUserIds?.length > 0 && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Selected Users
                            </label>
                            <div className="col-sm-9">
                              <SelectedUsers
                                selectedUser={selectedUserIds}
                                deleteHandler={popSelectedUser}
                              />
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-9">
                            <select
                              value={notificationData?.status}
                              onChange={(e) =>
                                updateValue("status", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="">Select Status</option>
                              <option value="true">Published</option>
                              <option value="false">Unpublished</option>                             
                            </select>
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <HotelModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <CustomTicketlistModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
      />
      <TransferModal
        show={eventModel}
        closeModal={closeEventModal}
        selectRow={selectEventRow}
      />    
      <DealModal
        show={offerModel}
        closeModal={closeOfferModal}
        selectRow={selectOfferRow}
      />
      <MultipleSelectUserList
        showUser={userModel}
        onSelectUser={onSelectedUser}
        closeUserModal={closeUserModal}
        selectUserRow={selectUserRow}
      />

    </React.Fragment>
  );
}
