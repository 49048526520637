import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap";

import {
  getRequest,
  postRequest,
  deleteRequest,
} from "../../components/Common/Utils.js";
import DonutChart from "../AllCharts/DonutChart";
import CountUp from "react-countup";
import { useHistory, useParams, useLocation } from "react-router-dom";
import ChartComponets from "./ChartData.js";
const MonthlyEarnings = (props) => {
  let history = useHistory();
  const [userdata, setuserdata] = useState({});
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    dsManagment();
  }, []);

  const dsManagment = async (page = 0) => {
    setLoader(true);
    const response = await postRequest(`admin/dashboard`);
    const data = response;

    console.log(data);

    // setTotalPage(parseInt(data.total));
    // setCurrentPage(parseInt(currentPage));

    // wait for 2 seconds

    //await new Promise((resolve) => setTimeout(resolve, 2000));

    setuserdata(data);
    // setSearchText('');

    setLoader(false);
  };

  return (
    <React.Fragment>
      <Row>
      <Col xl={3}>
  <Card>
  <CardBody onClick={() => history("/banner")}>
  <CardTitle className="h4 mb-4">Users</CardTitle>
  <Row className="text-center mt-4">
    <div className="col-4">
      {/* Blank space in the leftmost section */}
    </div>
    <div className="col-4 align-items-center">
      {/* Middle section */}
      <span>
        <h2>
          <CountUp 
            start={0}
            end={userdata?.data?.userCount || 0}
            duration={1.5}
          />
        </h2>
        <a href="/users">
          <p className="text-muted">Users</p>
        </a>
      </span>
    </div>
    <div className="col-4 align-items-center">
      {/* Rightmost section */}
        {/* <Link to={{ 
            pathname: '/chart', 
            state: { component: 'user' } 
          }}
        >
          <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
        </Link>     */}
    </div>
  </Row>
</CardBody>
  </Card>
</Col>
        <Col xl={3}>
          <Card>
            <CardBody onClick={() => history("/Component")}>
              <CardTitle className="h4 mb-4">Hotels</CardTitle>

              <Row className="text-center mt-4">
              <div className="col-4">
                {/* Blank space in the leftmost section */}
              </div>
              <div className="col-4 align-items-center">
                {/* Middle section */}
                <span>
                    <h2>
                <CountUp 
                    start={0}
                     end={userdata?.data?.hotelCount || 0}
                    duration={1.5}
                  />
                </h2>
                <a href="/hotels">
                  <p className="text-muted">Hotels</p>
                </a>
                  </span>
    </div>
    <div className="col-4 align-items-center">
      {/* Rightmost section */}
      {/* <Link to={{ 
            pathname: '/chart', 
            state: { component: 'hotel' } 
          }}
        >
          <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
        </Link> */}
                </div>
              </Row>
            </CardBody>
          </Card>
        </Col>

        <Col xl={3}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Transfers</CardTitle>

                    <Row className="text-center mt-4">
                    <div className="col-4">
                        {/* Blank space in the leftmost section */}
                    </div>
                    <div className="col-4 align-items-center">
                    {/* Middle section */}
                    <span>
                        <h2>
                <CountUp 
                    start={0}
                     end={userdata?.data?.transferCount || 0}
                    duration={1.5}
                  />
                </h2>
                            <a href="/transfers">
                     
                            <p className="text-muted">Transfers</p>
                            </a>
                        </span>
                        </div>
                        <div className="col-4 align-items-center">
                        {/* Rightmost section */}
                        {/* <Link to={{ 
                            pathname: '/chart', 
                            state: { component: 'transfer' } 
                          }}
                        >
                          <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
                        </Link> */}
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={3}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Tickets</CardTitle>

                    <Row className="text-center mt-4">
                    <div className="col-4">
                    {/* Blank space in the leftmost section */}
                    </div>
                    <div className="col-4 align-items-center">
                    {/* Middle section */}
                     <span>
                        <h2>
                        <CountUp 
                            start={0}
                            end={userdata?.data?.ticketCount || 0}
                            duration={1.5}
                        />
                        </h2>        

                        <a href="/tickets">
                          <p className="text-muted">Tickets</p>
                        </a>                
      </span>
    </div>
    <div className="col-4 align-items-center">
      {/* Rightmost section */}
      {/* <Link to={{ 
                            pathname: '/chart', 
                            state: { component: 'ticket' } 
                          }}
                        >
                          <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
                        </Link> */}
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
                      
               <Col xl={3}>
           
            <Card>
            <CardBody>
              <CardTitle className="h4 mb-4">Deals</CardTitle>

              <Row className="text-center mt-4">
                <div className="col-4">
                  {/* Blank space in the leftmost section */}
                </div>
                <div className="col-4 align-items-center">
                  {/* Middle section */}
                  <span>
                    <h2>
                      <CountUp
                        start={0}
                        end={userdata?.data?.dealCount || 0}
                        duration={1.5}
                      />
                    </h2>
                    <a href="/deals">
                      <p className="text-muted">Deals</p>
                    </a>
                  </span>
                </div>
                <div className="col-4 align-items-center">
                  {/* Rightmost section */}
                  {/* <Link to={{
                    pathname: '/chart',
                    state: { component: 'deals' }
                  }}>
                    <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
                  </Link> */}
                </div>

              </Row>

            </CardBody>

          </Card>
            </Col>
            <Col xl={3}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Hotel Bookings</CardTitle>

                    <Row className="text-center mt-4">
                    <div className="col-4">
      {/* Blank space in the leftmost section */}
    </div>
    <div className="col-4 align-items-center">
      {/* Middle section */}
      <span>
                        <h2>
                            <CountUp
                                start={0}
                                end={userdata?.data?.hotelBookingCount || 0}
                                duration={1.5}
                            />
                            </h2>
                            <a href="/hotelBookings">                           
                               <p className="text-muted">Bookings</p>
                            </a>
      </span>
    </div>
    <div className="col-4 align-items-center">
      {/* Rightmost section */}
      {/* <Link to={{ 
            pathname: '/chart', 
            state: { component: 'hotel-booking'} 
          }}
        >
          <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
        </Link> */}
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={3}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Transfer Bookings</CardTitle>

                    <Row className="text-center mt-4">
                    <div className="col-4">
      {/* Blank space in the leftmost section */}
    </div>
    <div className="col-4 align-items-center">
      {/* Middle section */}
      <span>
                        <h2>
                            <CountUp
                                start={0}
                                end={userdata?.data?.transferBookingCount || 0}
                                duration={1.5}
                            />
                            </h2>
                            <a href="/bookings">
                            <p className="text-muted">Bookings</p>
                            </a>  
      </span>
    </div>
    <div className="col-4 align-items-center">
      {/* Rightmost section */}
      {/* <Link to={{ 
            pathname: '/chart', 
            state: { component: 'transfer-booking' } 
          }}
        >
          <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
        </Link> */}
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>


            </Col>
            <Col xl={3}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Ticket Bookings</CardTitle>

                    <Row className="text-center mt-4">
                    <div className="col-4">
      {/* Blank space in the leftmost section */}
    </div>
    <div className="col-4 align-items-center">
      {/* Middle section */}
      <span>
                        <h2>
                            <CountUp
                                start={0}
                                end={userdata?.data?.ticketBookingCount || 0}
                                duration={1.5}
                            />
                            </h2>
                            <a href="/ticketBookings">                           
                            <p className="text-muted">Bookings</p>
                            </a>
      </span>
    </div>
    <div className="col-4 align-items-center">
      {/* Rightmost section */}
      {/* <Link to={{ 
            pathname: '/chart', 
            state: { component: 'ticket-booking' } 
          }}
        >
          <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
        </Link> */}
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>

            </Col>         
          
           
         
            {/* <Col xl={3}>


            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Bookings</CardTitle>

                    <Row className="text-center mt-4">

                    <div className="col-4">
      
    </div>
    <div className="col-4 align-items-center">
      
      <span>
                        <h2>
                            <CountUp
                                start={0}
                                end={userdata?.data?.allBookingCount || 0}
                                duration={1.5}
                            />
                            </h2>
                            <a href="/bookings">                           
                               <p className="text-muted">Bookings</p>
                            </a>
                           
      </span>
    </div>
    <div className="col-4 align-items-center">
      
      <Link to={{ 
                                  pathname: '/chart', 
                                  state: { component: 'booking' } 
                                }}
                              >
                                <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
                              </Link>
                        </div>
                        
                    </Row>      

                </CardBody>
                
            </Card>


            </Col>
            <Col xl={3}>

            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">Banners</CardTitle>

                <Row className="text-center mt-4">
                  <div className="col-4">
                   
                  </div>
                  <div className="col-4 align-items-center">
                    
                    <span>
                      <h2>
                        <CountUp
                          start={0}
                          end={userdata?.data?.bannerCount || 0}
                          duration={1.5}
                        />
                      </h2>
                      <a href="/banner">
                        <p className="text-muted">Banners</p>
                      </a>
                    </span>
                  </div>
                  <div className="col-4 align-items-center">
                   
                    <Link to={{
                      pathname: '/chart',
                      state: { component: 'banners' }
                    }}>
                      <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
                    </Link>
                  </div>
                </Row>           
              </CardBody>
            </Card>



            </Col>
               <Col xl={3}>
               <Card>
                  <CardBody>
                    <CardTitle className="h4 mb-4">Category</CardTitle>

                    <Row className="text-center mt-4">
                      <div className="col-4">
                        
                      </div>
                      <div className="col-4 align-items-center">
                        
                        <span>
                          <h2>
                            <CountUp
                              start={0}
                              end={userdata?.data?.categoryCount || 0}
                              duration={1.5}
                            />
                          </h2>
                          <a href="/Category">
                            <p className="text-muted">Category</p>
                          </a>
                        </span>
                      </div>
                      <div className="col-4 align-items-center">
                        
                        <Link to={{
                          pathname: '/chart',
                          state: { component: 'category' }
                        }}>
                          <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
                        </Link>
                      </div>
                    </Row>           
                  </CardBody>
                </Card>



            </Col>
            <Col xl={3}>
            <Card>
                <CardBody>
                  <CardTitle className="h4 mb-4">HomeBlock</CardTitle>

                  <Row className="text-center mt-4">
                    <div className="col-4">
                     
                    </div>
                    <div className="col-4 align-items-center">
                      
                      <span>
                        <h2>
                          <CountUp
                            start={0}
                            end={userdata?.data?.homeBlockCount || 0}
                            duration={1.5}
                          />
                        </h2>
                        <a href="/homeblock">
                          <p className="text-muted">HomeBlock</p>
                        </a>
                      </span>
                    </div>
                    <div className="col-4 align-items-center">
                      
                      <Link to={{
                        pathname: '/chart',
                        state: { component: 'home-blocks' }
                      }}>
                        <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
                      </Link>
                    </div>
                  </Row>           
                </CardBody>
              </Card>

            </Col> */}
           
      </Row>
      <Row>
        
            {/* <Col xl={3}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">Notifications</CardTitle>

                <Row className="text-center mt-4">
                  <div className="col-4">
                   
                  </div>
                  <div className="col-4 align-items-center">
                    
                    <span>
                      <h2>
                        <CountUp
                          start={0}
                          end={userdata?.data?.notificationCount || 0}
                          duration={1.5}
                        />
                      </h2>
                      <a href="/notification">
                        <p className="text-muted">Notification</p>
                      </a>
                    </span>
                  </div>
                  <div className="col-4 align-items-center">
                    
                    <Link to={{
                      pathname: '/chart',
                      state: { component: 'notifications' }
                    }}>
                      <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
                    </Link>
                  </div>
                </Row>           
              </CardBody>
            </Card>
            </Col> */}
         {/* <Col xl={3}>
         <Card>
          <CardBody>
            <CardTitle className="h4 mb-4">Hotel Reviews</CardTitle>

            <Row className="text-center mt-4">
              <div className="col-4">
              
              </div>
              <div className="col-4 align-items-center">
                
                <span>
                  <h2>
                    <CountUp
                      start={0}
                      end={userdata?.data?.hotelReviewCount || 0}
                      duration={1.5}
                    />
                  </h2>
                  <a href="/hotelratings">
                    <p className="text-muted">Reviews</p>
                  </a>
                </span>
              </div>
              <div className="col-4 align-items-center">
                
                <Link to={{
                  pathname: '/chart',
                  state: { component: 'hotel-review' }
                }}>
                  <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
                </Link>
              </div>
            </Row>           
          </CardBody>
        </Card>

            </Col> */}
            {/* <Col xl={3}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">Transfer Reviews</CardTitle>

                <Row className="text-center mt-4">
                  <div className="col-4">
                   
                  </div>
                  <div className="col-4 align-items-center">
                    
                    <span>
                      <h2>
                        <CountUp
                          start={0}
                          end={userdata?.data?.transferReviewCount || 0}
                          duration={1.5}
                        />
                      </h2>
                      <a href="/transferratings">
                        <p className="text-muted">Reviews</p>
                      </a>
                    </span>
                  </div>
                  <div className="col-4 align-items-center">
                   
                    <Link to={{
                      pathname: '/chart',
                      state: { component: 'transfer-review' }
                    }}>
                      <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
                    </Link>
                  </div>
                </Row>           
              </CardBody>
            </Card>

            </Col>  */}
            {/* <Col xl={3}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">Ticket Reviews</CardTitle>

                <Row className="text-center mt-4">
                  <div className="col-4">
                    
                  </div>
                  <div className="col-4 align-items-center">
                    
                    <span>
                      <h2>
                        <CountUp
                          start={0}
                          end={userdata?.data?.ticketReviewCount || 0}
                          duration={1.5}
                        />
                      </h2>
                      <a href="/ticketratings">
                        <p className="text-muted">Reviews</p>
                      </a>
                    </span>
                  </div>
                  <div className="col-4 align-items-center">
                   
                    <Link to={{
                      pathname: '/chart',
                      state: { component: 'ticket-review' }
                    }}>
                      <i className="fas fa-chart-bar" style={{ fontSize: "3rem" }}></i>
                    </Link>
                  </div>
                </Row>           
              </CardBody>
            </Card>

            </Col>  */}
            
      </Row>
      <ChartComponets />
      
    </React.Fragment>
  );
};

export default MonthlyEarnings;
