import React from 'react'

export default function Termscondition() {
  return (
    <>
   
    <title>Yalator Mobile Application Terms and Conditions</title>
    
    <div style={{padding : "5px 15px"}}>
      <h1 style={{textAlign : "center", marginBottom : "15px"}}> Terms and Conditions</h1>
      <h2>1. Introduction</h2>
      <p>
        Welcome to Yalator! These terms and conditions outline the rules and
        regulations for the use of the Yalator mobile application. By downloading,
        installing, or using this app, you accept these terms and conditions in
        full. If you do not agree with any part of these terms and conditions,
        please do not use our app.
      </p>
      <h2>2. Business Name and Description</h2>
      <p>
        Yalator is a mobile application that provides users with the ability to
        book hotels and transfers seamlessly. Our goal is to offer a user-friendly
        platform to manage your travel and accommodation needs efficiently.
      </p>
      <h2>3. Contact Information</h2>
      <p>
        If you have any questions or concerns regarding these terms and conditions
        or any other aspect of our service, please contact us at:
      </p>
      <p>Email: <a href="mailto:hello@elevatedmc.com">hello@elevatedmc.com</a></p>
      <h2>4. Account Registration</h2>
<p>
  To use certain features of the Yalator app, users must register for an account. During the registration process, you will be required to provide:
</p>
<ul>
  <li>First name</li>
  <li>Last name</li>
  <li>Valid email address</li>
  <li>Address</li>
  <li>Phone number</li>
  <li>password</li>
</ul>
<p>By registering, you agree to:</p>
<ul>
  <li>Provide accurate, current, and complete information.</li>
  <li>Maintain and promptly update your information.</li>
  <li>
    Protect the security of your password and accept all risks of unauthorized access to your account.
  </li>
</ul>

      <h2>5. User Obligations</h2>
      <p>By using the Yalator app, you agree to:</p>
      <ul>
        <li>Comply with all applicable laws and regulations.</li>
        <li>Use the app only for lawful purposes.</li>
        <li>
          Not engage in any activity that could harm or disrupt the app or other
          users.
        </li>
        <li>
          Not share your account with others or allow others to access your
          account.
        </li>
      </ul>
      <h2>6. Intellectual Property</h2>
      <p>
        All content and materials available on the Yalator app, including but not
        limited to text, graphics, logos, images, and software, are the
        intellectual property of Yalator. Unauthorized use of any content may
        violate copyright, trademark, and other laws.
      </p>
      <h2>7. Payment Terms</h2>
      <p>
        Certain services offered through the Yalator app require payment. By
        making a purchase, you agree to:
      </p>
      <ul>
        <li>Provide accurate and complete payment information.</li>
        <li>
          Authorize us to charge the applicable fees to your payment method.
        </li>
      </ul>
      <p>
        Refunds and cancellations are subject to our refund policy, which will be
        provided at the time of booking.
      </p>
      <h2>8. Termination of Service</h2>
      <p>
        We reserve the right to terminate or suspend your access to the Yalator
        app at any time, without notice, for conduct that we believe:
      </p>
      <ul>
        <li>Violates these terms and conditions.</li>
        <li>Is harmful to other users, us, or third parties.</li>
      </ul>
      <h2>9. Governing Law</h2>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of UAE Dubai. Any disputes relating to these terms and
        conditions will be subject to the exclusive jurisdiction of the courts of
        UAE Dubai.
      </p>
      <h2>10. Limitation of Liability</h2>
      <p>
        To the maximum extent permitted by applicable law, Yalator shall not be
        liable for any indirect, incidental, special, consequential, or punitive
        damages, or any loss of profits or revenues, whether incurred directly or
        indirectly, or any loss of data, use, goodwill, or other intangible
        losses, resulting from:
      </p>
      <ul>
        <li>Your use or inability to use the app.</li>
        <li>
          Any unauthorized access to or use of our servers and/or any personal
          information stored therein.
        </li>
        <li>
          Any bugs, viruses, trojan horses, or similar that may be transmitted to
          or through our app by any third party.
        </li>
        <li>
          Any errors or omissions in any content or for any loss or damage
          incurred as a result of your use of any content posted, emailed,
          transmitted, or otherwise made available through the app.
        </li>
      </ul>
      <h2>11. Changes to Terms</h2>
      <p>
        Yalator reserves the right to update or modify these terms and conditions
        at any time without prior notice. Your continued use of the app following
        any changes constitutes your acceptance of the new terms and conditions.
        It is your responsibility to review these terms periodically.
      </p>
     
      <h2>12. User-Generated Content</h2>
      <p>
        Yalator may allow users to post, upload, and share content, such as
        reviews, comments, and photos. By posting content on the app, you grant
        Yalator a non-exclusive, transferable, sub-licensable, royalty-free,
        worldwide license to use, display, reproduce, and distribute your content
        on and through the app.
      </p>
      <h2>13. Age Restrictions</h2>
      <p>
        The Yalator app is intended for use by individuals who are at least 13
        years old. By using the app, you represent and warrant that you are at
        least 13 years of age.
      </p>
      <h2>14. Miscellaneous</h2>
      <p>
        These terms and conditions constitute the entire agreement between Yalator
        and you regarding your use of the app. If any provision of these terms is
        found to be invalid or unenforceable, the remaining provisions will remain
        in full force and effect.
      </p>
    </div>
  </>
  
  )
}
