import { Modal } from "reactstrap";

import DragDropBanner from './DragDropHomeblock';

const HomeblockDragModal = ({show, closeModal, data,  setData }) => {
    return (
      <Modal
        isOpen={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
       <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="myLargeModalLabel"
                        >
                          Homeblock List Ordering
                              </h5>
                        <button
                          onClick={() => {
                              closeModal()
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
        <div className="modal-body">    
            <DragDropBanner data={data} setData={setData}  />  
        </div>
        
      </Modal>    
    );
  };


export default HomeblockDragModal;