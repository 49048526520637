import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import MultiSelect from "../../components/Multiselect/MultiselectCommon2.js";

//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ReactPaginate from "react-paginate";

const filterObj = {
  name: "",
  "business_name": "",
  email: "",
  dress_code: "",
  open: "",
  cuisines: [],
  themes: [],
  music: [],
  features: [], 
  createdAt: "",
};

const VenueManagementlist = (props) => {
  const [modal_center, setmodal_center] = useState(false);
  const [venuedata, setvenuedata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [filter, setFilter] = useState({ ...filterObj });
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);

  const [themeList, setThemeList] = useState([]);
  const [musicList, setMusicList] = useState([]);
  const [featureList, setFeatureList] = useState([]);
  const [cuisineList, setCuisineList] = useState([]);
  

  useEffect(() => {
    venueManagment();
  }, [currentPage]);

  useEffect(() => {
    venueManagment();
  }, [sizePerPageOrg, sort, sortOrder]);

  const msUpdateTheme = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
   // setSelectedTheme(ids);
    const filterObj = { ...filter };
    filterObj.themes = ids;
    setFilter(filterObj);
  };

  const msUpdateMusic = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    //setSelectedMusic(ids);
    const filterObj = { ...filter };
    filterObj.music = ids;
    setFilter(filterObj);    
  };

  const msUpdateFeature = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
   // setSelectedFeature(ids);
    const filterObj = { ...filter };
    filterObj.features = ids;
    setFilter(filterObj);
    
  };

  const msUpdateCuisine = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
   // setSelectedCuisine(ids);
    const filterObj = { ...filter };
    filterObj.cuisines = ids;

    console.log(ids  )


    setFilter(filterObj);    
  };

  useEffect(() => {   
    fetchTheme();
    fetchMusic();
    fetchFeature();
    fetchCuisine();
  }, []);

  const fetchTheme = async () => {
    const param = { page: 1, limit: 100000 };
    const theme = await postRequest("theme/list", param);
    if (theme.status == 1) {
      const options = theme.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setThemeList(options);
    }
  };

  const fetchMusic = async () => {
    const param = { page: 1, limit: 100000 };
    const music = await postRequest("music/list", param);
    if (music.status == 1) {
      const options = music.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setMusicList(options);
    }
  };

  const fetchFeature = async () => {
    const param = { page: 1, limit: 100000 };
    const feature = await postRequest("feature/list", param);
    if (feature.status == 1) {
      const options = feature.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setFeatureList(options);
    }
  };

  const fetchCuisine = async () => {
    const param = { page: 1, limit: 100000 };
    const cuisine = await postRequest("cuisine/list", param);
    if (cuisine.status == 1) {
      const options = cuisine.data.list.map((item) => {
        return { id: item._id, name: item.title };
      });
      setCuisineList(options);
    }
  };
  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }

    console.log("type", type);

    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {     


      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {



        if(key === "business_name") {
          var filterObject = {
            'business_id.name': filter['business_name'],
          };

        }else{
          var filterObject = {
            [key]: filter[key],
          };
        }

       
       

        if (key === "name" || key === "business_name" || key === "email" || key === "dress_code") {
          filterObject.type = "regex";
        } else if (key === "status") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        } else if(key === "open"){
          filterObject.type = "is-open";
        } else if( key === 'themes' || key === 'music' || key === 'features' || key === 'cuisines' ){
            filterObject.type = "in";   
        }

        filterArgs.push(filterObject);
      }
    });

    console.log("filterArgs", filterArgs);
    toggleModel();
    venueManagment(filterArgs);
  };

  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    venueManagment();
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];
  function MenuFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <Link
          to={{
            pathname: `/menu/${row._id}`,
            state: { row },
          }}
        >
          Menu
        </Link>
      </div>
    );
  }
  function TimeFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <Link
          to={{
            pathname: `/time/${row._id}`,
            state: { row },
          }}
        >
          Time
        </Link>
      </div>
    );
  }
  function StoryFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <Link
          to={{
            pathname: `/story/${row._id}`,
            state: { row },
          }}
        >
          <p>{row?.active_stories}</p>
        </Link>
      </div>
    );
  }
  function ImgLogoFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.logo}
          onClick={() => showLightBox(row.logo)}
          alt="logo img"
          width={"50px"}
        />
      </>
    );
  }

  function ImgCoverFormatter(cellContent, row) {
    return (
      <>
        <img
          src={row.cover}
          onClick={() => showLightBox(row.cover)}
          alt="cover img"
          width={"50px"}
        />
      </>
    );
  }
  function ThemeFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <Link
          to={{
            pathname: `/createVenueTheme/${row._id}`,
            state: { row },
          }}
        >
          Theme
        </Link>
      </div>
    );
  }

  function CuisineFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <Link
          to={{
            pathname: `/createVenueCuisine/${row._id}`,
            state: { row },
          }}
        >
          Cuisine
        </Link>
      </div>
    );
  }
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };
  function WebsiteFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <a href={row.website} target="_blank" rel="noopener noreferrer">
          Website
        </a>
      </div>
    );
  }
  function MenuUrlFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <a href={row.menu_url} target="_blank" rel="noopener noreferrer">
          Menu Url
        </a>
      </div>
    );
  }
  function BusinessFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex" }}>
        <p>{row?.business_id?.name}</p>
      </div>
    );
  }

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }

  const components = [
    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={ filter.name }
          onChange={(e) => updateFilter("name", e.target.value)}
          name="title"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="amount">
      <label className="col-sm-3 col-form-label">Bussiness Name</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={ filter.business_name }
          onChange={(e) => updateFilter("business_name", e.target.value)}
          name="business_name"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Email</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={ filter.email }
          onChange={(e) => updateFilter("email", e.target.value)}
          name="email"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
    <label className="col-sm-3 col-form-label">Dress Code</label>
    <div className="col-sm-6">
      <input
        type="text"
        value={ filter.dress_code }
        onChange={(e) => updateFilter("dress_code", e.target.value)}
        name="dresscode"
        className="form-control"
      />
    </div>
  </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Open</label>
      <div className="col-sm-6">
        <select
          onChange={(e) => updateFilter("open", e.target.value)}
          className="form-select"
        >
          <option value="">Select Open / Close</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>
    </div>,

<div className="row mb-4">
<label className="col-sm-3 col-form-label">
  Select Theme
</label>
<div className="col-sm-6">
  <MultiSelect
    options={themeList}
    onSelect={msUpdateTheme}
    selectedValues={selectedTheme}
    placeholder="Select Theme"
  />
</div>
</div>,

<div className="row mb-4">
<label className="col-sm-3 col-form-label">
  Select Cuisine
</label>
<div className="col-sm-6">
  <MultiSelect
    options={cuisineList}
    onSelect={msUpdateCuisine}
    selectedValues={selectedCuisine}
    placeholder="Select Cuisine"
  />
</div>
</div>,

<div className="row mb-4">
<label className="col-sm-3 col-form-label">
  Select Music
</label>
<div className="col-sm-6">
  <MultiSelect
    options={musicList}
    onSelect={msUpdateMusic}
    selectedValues={selectedMusic}
    placeholder="Select Music"
  />
</div>
</div>,

<div className="row mb-4">
<label className="col-sm-3 col-form-label">
  Select Feature
</label>
<div className="col-sm-6">
  <MultiSelect
    options={featureList}
    onSelect={msUpdateFeature}
    selectedValues={selectedFeature}
    placeholder="Select Feature"
  />
</div>
</div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">Created Date</label>
      <div className="col-sm-6">
        <input
          type="date"
          value={ filter.createdAt }
          onChange={(e) => updateFilter("createdAt", e.target.value)}
          name="createdAt"
          className="form-control"
        />
      </div>
    </div>,

    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
    },
    {
      text: "Bussiness Name",
      formatter: BusinessFormatter,
    },

    {
      dataField: "email",
      text: "Email",
      sort: false,
    },

    
    {
      dataField: "cover",
      text: "Cover",
      formatter: ImgCoverFormatter,
      sort: false,
    },
    {
      dataField: "logo",
      text: "Logo",
      formatter: ImgLogoFormatter,
      sort: false,
    },
    {
      dataField: "website",
      text: "Website",
      formatter: WebsiteFormatter,
      sort: false,
    },
   

    {
      dataField: "menu_url",
      text: "Menu Url",
      formatter: MenuUrlFormatter,
      sort: false,
    },

    {
      dataField: "menu",
      text: "Menu",
      formatter: MenuFormatter,
      sort: false,
    },

    {
      dataField: "time",
      text: "Time",
      formatter: TimeFormatter,
      sort: false,
    },
    {
      dataField: "story",
      text: "Story",
      formatter: StoryFormatter,
      sort: false,
    },
   

    {
      dataField: "createdAt",
      text: "Created At",
      sort: false,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];
  const handleChange = (event) => {
    
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
   
  };
  const handlesortOrder = (event) => {   
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    window.location.reload();
  };
  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex">
        <button type="button" className=" btn btn-primary text-light">
            {" "}
            <Link
              to={{
                pathname: `/venuedetails/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i className="fal fa-eye text-light"></i>
            </Link>{" "}
          </button>
          <button type="button" className=" btn btn-info  mx-2">
            {" "}
            <Link
              to={{
                pathname: `/updateVenue/${row._id}`,
                state: { row },
              }}
            >
              {" "}
              <i class="fal fa-pencil fs-5 text-light"></i>
            </Link>{" "}
          </button>

          <button
            type="button"
            className="btn btn-danger"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
        </div>
      </React.Fragment>
    );
  }

  

  const venueManagment = async (filterArgs = []) => {
    console.log(currentPage);
    setLoader(true);

    const payload = {
      limit: sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
       };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }

    const response = await postRequest(`venue/list`, payload);
    const data = response.data;
    setTotalPage(data.count);
    setCurrentFPage(data.page);
    setvenuedata(data.list);
    setLoader(false);
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`venue/delete`, { venueID: id });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          venueManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Venue Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Venue Management</li>:{" "}
                <li className="breadcrumb-item">Venue List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            <Link to="/createVenue">
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={venuedata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>

                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "7%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="name">Name</option>
                                    <option value="business_name">
                                      Bussiness Name
                                    </option>
                                    <option value="email">Email ID</option>
                                    <option value="createdAt">
                                      Created At
                                    </option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                    value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                    
                                  </select>
                                  <div className="filter-item">
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        venueManagment();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger mx-2"
                                    >
                                      Reset
                                    </button>

                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info"
                                    >
                                      Filter
                                    </button>
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    remote={true}
                                    classes="table-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />
    </>
  );
};

export default VenueManagementlist;
