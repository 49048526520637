import React, { useState, useEffect } from "react";
import { useHistory,useParams, useLocation  } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, FormGroup, Form, Table, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  postRequestForm, postRequest,putRequestForm } from "../../components/Common/Utils.js";

import VenueModal from "../../components/Modal/HotelModal.js";

export default function UpdateVideo() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;
  

  const [videoData, setVideoData] = useState(null); 
  const [previewImage, setPreviewImage] = useState(""); 
  const [imageIcon, setImageIcon] = useState("");
  const [loading, setLoading] = useState(false);  
  const [selectedImage, setSelectedImage] = useState(""); 
  const [venueList, setVenueList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [listFetched, setListFetched] = useState(false);
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [model, setModel] = useState(false);

 

  

  const { id } = useParams(); 
  useEffect(() => {
    setVideoData(state);
    setImageIcon( state?.videoUrl ? state?.videoUrl : "" )
    
  }, [state])

  
  useEffect(() => {
    if(!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result)
      setImageIcon("");
    }
    reader.readAsDataURL(selectedImage)
  }, [selectedImage])

  
  useEffect(() => {
    fetchList();
    setVenueId(state?.venue?._id);
    setVenueText(state?.venue?.name);
    }, [state]);

    
    const fetchList = async () => {
      setLoading(true);
      const response = await postRequest("venue/list", { limit: 10000000 });
      if (response.status == 1) {
        const list = response.data.list.map((item) => {
          return {
            id: item._id,
            name: item.name,
          };
        });
        setVenueList(list);
  
        const defaultItems = list.filter((item) => {
          return selectedVenue.includes(item.id);
        });
  
        console.log("defaultItems", defaultItems);
        setListFetched(true);
      }
      setLoading(false);
    };

    const toggleModel = () => {
      console.log("toggleModel", model)
      setModel(!model);
    };
  
 
    const closeModal = () => {
      setModel(false);
    };

    const selectRow = (id, name) => {
      setVenueId(id);
      setVenueText(name);
      closeModal();
    };  

  const Updatevideo = async (e) => {
    e.preventDefault();
    if (!venueId) {
      toast.error("Please select venue");
      return;
    }
    if (!videoData.title) {
      toast.error("Please enter title");
      return;
    }
    if (!videoData.description) {
      toast.error("Please enter description");
      return;
    }
    
   setLoading(true);
  
    const formData = new FormData();
    formData.append("id", id);
    formData.append("venueId", venueId); 
    formData.append("title", videoData.title);
    formData.append("description", videoData.description); 
    

    if(selectedImage){
      formData.append("videoUrl", selectedImage);
    } 
   
   
   
   
    
    
    const response = await putRequestForm("home-block/video-component/update", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/videos");
      }, 1000);
      
    } else {
      toast.error(response.message);
    }  
    
  };

  const updateValue = (index, value) => {
    const list = {...videoData}
    list[index] = value
    setVideoData(list)
    
  } 
    
 
    

  return (
    <React.Fragment>

      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Video Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Video Management</li>:{" "}
              <li className="breadcrumb-item">
              Update Video
              </li>
            </ol>
          </div>
        </div>
      </div>
     
      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>

          { loading ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :

            <CardBody>
              <h4 className="card-title"> Update Video </h4>
              <Form onSubmit={Updatevideo}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">

                        

                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Select Venue</label>
                           
                            <div className="col-sm-6"  onClick={() => toggleModel()}>
                            {venueText ? (
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {venueText}
                                  </a>
                                </div>
                                <div>
                                  <a
                                    onClick={() => toggleModel()}
                                    href="javascript:void(0)"
                                  >
                                    <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Select Venue
                                  </a>
                                </div>
                                <div>
                                  <a
                                    onClick={() => toggleModel()}
                                    href="javascript:void(0)"
                                  >
                                    <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>  
                       
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Title</label>
                            <div className="col-sm-6">
                            <input type="text" onChange={ (e) => updateValue('title', e.target.value) } value={videoData?.title}  name='title'  className="form-control"  />
                            </div>
                        </div>     
                        
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Description</label>
                            <div className="col-sm-6">
                            <textarea type="textarea" onChange={ (e) => updateValue('description', e.target.value) } value={videoData?.description} name='description' className="form-control" id="horizontal-password-input" />
                            
                            </div>
                        </div>  

                        

                        

                        <div className="row mb-4">
                            <label htmlFor="horizontal-password-input" className="col-sm-3 col-form-label">File</label>
                            <div className="col-sm-6">
                            <input type="file" accept="video/*" onChange={(e) => setSelectedImage(e.target.files[0])}  name='videoUrl' className="form-control" id="horizontal-password-input"  />
                           <br/>                          
                           {imageIcon && (
                               
                                <video width="50" height="50" controls>
                                 <source src={imageIcon} type="video/mp4" />
                                </video>
                              )}
                            </div>                           
                        </div>                       
                                             
             
                       
                        <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                            <div className="col-sm-9">
                            <div>
                                <button type="submit"  className="btn btn-primary w-md">Submit</button>
                            </div>
                            </div>
                            </div>
                        </div>

                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody> }
          </Card>
        </Col>
      </Row>

      {/* modal */}

      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />  

    </React.Fragment>



  );
}
