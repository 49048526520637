import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";




export default function CreateHotelimage() {
  const location = useLocation();
  const state = location.state;

  const [bannerData, setBannerData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [model, setModel] = useState(false);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState(""); 
  const [offerModel, setOfferModel] = useState(false);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [activityModel, setActivityModel] = useState(false);
  const [eventId, setEventId] = useState("");
  const [eventText, setEventText] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [eventModel, setEventModel] = useState(false);


  const [selectedImage, setSelectedImage] = useState("");

  const [url, seturl] = useState("");


  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);


  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };

  const closeModal = () => {
    setModel(false);
  };
  const toggleOfferModel = () => {
    console.log("toggleOfferModel", offerModel);
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const toggleActivityModel = () => {
    console.log("toggleActivityModel", activityModel);
    setActivityModel(!activityModel);
  };
  const closeActivityModal = () => {
    setActivityModel(false);
  };

  const toggleEventModel = () => {
    console.log("toggleEventModel", eventModel);
    setEventModel(!eventModel);
  };
  const closeEventModal = () => {
    setEventModel(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(id?.name);
    setVenueImage(id?.thumbImage);
    //console.log("id", id);

    const formInput = { ...bannerData };
    formInput.item = id;
    formInput.subHeading = name;
    formInput.subText = venue?.address;
    formInput.buttonText = "View";
    formInput.logo = venue?.thumbImage;
    formInput.background = venue?.cover;
    setBannerData(formInput);
   // setPreviewLogo(venue?.image);
    //setPreviewImage(venue?.image);
    closeModal();
  };

  const selectOfferRow = (offer) => {
    setVenueId(offer);
    setOfferText(offer?.title);
    setOfferImage(offer?.image);
    const formInput = { ...bannerData };
    formInput.item = offer?._id;   
    closeOfferModal();
  };

  const selectActivityRow = (id, name, activity) => {
    setVenueId(id);
    setActivityText(id?.name);
    setActivityImage(activity?.image);
    console.log("id", id);
    const formInput = { ...bannerData };
    formInput.item = id;
    if (bannerData.title == "") formInput.title = name;
    if (bannerData.description == "")
      formInput.description = activity?.description;
    formInput.subHeading = activity?.provider?.name;
    formInput.subText = activity?.provider?.address;
    formInput.buttonText = "View";
    formInput.logo = activity?.provider?.logo;
    formInput.background = activity?.galleries?.length
      ? activity?.galleries[0]
      : "";
    setBannerData(formInput);
    // setPreviewImage(activity?.galleries?.length ? activity?.galleries[0] : "");
    // setPreviewLogo(activity?.provider?.logo);
    closeActivityModal();
  };

  const selectEventRow = (id, name, event) => {
    setVenueId(id);
    setEventText(id?.name);
    setEventImage(id?.coverImage);
    console.log("id", id);
    const formInput = { ...bannerData };
    formInput.item = id;
    if (bannerData.title == "") formInput.title = name;
    if (bannerData.description == "")
      formInput.description = event?.description;
    formInput.subHeading = event?.venue?.name;
    formInput.subText = event?.venue?.address;
    formInput.buttonText = "View";
    formInput.logo = event?.venue?.logo;
    formInput.background = event?.image;
    setBannerData(formInput);
    // setPreviewImage(event?.image);
    // setPreviewLogo(event?.venue?.logo);
    closeEventModal();
  };

  const Createbannerform = async (e) => {
    e.preventDefault();
    const data={
      hotelId: state.hotelId,
      fileName: url   
    }   
    const response = await postRequest("hotel/push-image/", data);
    console.log({response})
    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/hotelDetails/"+ state.hotelId);
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  const Imageupload = async (e) => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    const response = await postRequestForm("homepage/upload/image", formData);
    let image  = response.data.url
    console.log({image})
    seturl(image)
  };



  useEffect(()=>{
    Imageupload()
  },[selectedImage])
  return (
    <React.Fragment>
      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Hotel Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Hotel Management</li>:{" "}
              <li className="breadcrumb-item">Add Hotel Image</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title"> Add Hotel Image</h4>

              <Form onSubmit={Createbannerform}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4"> 


                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          File
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="file"
                            onChange={(e) =>
                              setSelectedImage(e.target.files[0])
                            }
                            name="mediaUrl"
                            className="form-control"
                            id="horizontal-password-input"
                            required="required"
                          />
                          <br />
                          {bannerData?.mediaType == "video"
                            ? null
                            : previewImage && (
                                <img
                                  src={previewImage}
                                  style={{ width: "100px", height: "100px" }}
                                />
                              )}
                        </div>
                      </div>

                     
                      <div className="mt-5 mt-lg-4">
                        <div className="row justify-content-end">
                          <div className="col-sm-9">
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      
   
   


    </React.Fragment>
  );
}
