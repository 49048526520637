import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";

//import filter modal
import FilterModal from "../../components/Modal/FilterModal";

import {
  getRequest,
  postRequest,
  deleteRequest,
  putRequest,
  putRequestForm,
  postRequestForm,
} from "../../components/Common/Utils.js";

import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Badge,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";

const filterObj = {
  paymentStatus: "",
  dealTitle: "",
  dealType: "",
};

export default function DealBookingList() {
  const [modal_center, setmodal_center] = useState(false);
  const [ratingdata, setratingdata] = useState([]);
  const [loader, setLoader] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [showOrder, setShowOrder] = useState(false);

  const [paymentStatus, setpaymentStatus] = React.useState("");
  const [dealTitle, setdealTitle] = React.useState("");
  const [dealType, setdealType] = React.useState("");

  const [sort, setsort] = React.useState("createdAt");
  const [sortOrder, setsortOrder] = React.useState("desc");

  const [currentFPage, setCurrentFPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [image, setImage] = React.useState("");
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [model, setModel] = useState(false);
  const [canDelete, setCanDelete] = useState(true);
  const [canExport, setCanExport] = useState(true);
  const [statusid, setstatusid] = useState();
  const [filter, setFilter] = useState({ ...filterObj });

  const [loader2, setLoader2] = useState(false);

  const GetValueOnChange = async (status) => {
    const res = await postRequest("deal/booking-status", {
      userDealId: statusid,
      bookingStatus: status
    });

    if (res.status) {
      toast.success("Booking Status updated Successfully");
      DealBookingManagment();
      setShowOrder(false);
    } else {
      toast.error("Something went wrong");
      setLoader(false);
    }
  };

  useEffect(() => {
    DealBookingManagment();
  }, [currentPage]);

  useEffect(() => {
    DealBookingManagment();
  }, [sort, sortOrder]);

  const closeModal = () => {
    setModel(false);
  };

  const toggleModel = () => {
    setpaymentStatus("");
    setdealTitle("");
    setdealType("");

    console.log("toggleModel", model);
    setModel(!model);
  };

  const handleTableChange = (type, { page, sizePerPage, searchText }) => {
    if (type === "pagination") {
      if (page != currentPage) {
        setCurrentPage(page);
        console.log("page", page);
      }
    }

    console.log("type", type);

    if (type === "search") {
      console.log("searchText", searchText);
    }
  };

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const updateFilter = (key, value) => {
    const filterObj = { ...filter };
    filterObj[key] = value;
    console.log("====>>", { filterObj });
    setFilter(filterObj);
  };

  const applyFilter = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (
          key === "paymentStatus" ||
          key === "dealTitle" ||
          key === "dealType" ||
          key === "dealStatus"
        ) {
          filterObject.type = "regex";
        } else if (key === "status" || key === "stars") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });

    console.log("filterArgs", filterArgs);
    toggleModel();

    let data = {
      paymentStatus,
      dealTitle,
      dealType,
    };
    DealBookingManagment(data);
  };
  const getFilters = () => {
    const filterArgs = [];

    Object.keys(filter).forEach((key) => {
      if (
        filter[key] !== "" &&
        filter[key] !== null &&
        filter[key] !== undefined
      ) {
        var filterObject = {
          [key]: filter[key],
        };

        if (
          key === "paymentStatus" ||
          key === "dealTitle" ||
          key === "dealType"
        ) {
          filterObject.type = "regex";
        } else if (key === "status" || key === "stars") {
          filterObject.type = "eq";
        } else if (key === "createdAt") {
          filterObject.type = "date-eq";
        }

        filterArgs.push(filterObject);
      }
    });

    console.log("filterArgs", filterArgs);
    toggleModel();

    let data = {
      paymentStatus,
      dealTitle,
      dealType,
    };
    DealBookingManagment(data);
  };
  const resetFilter = () => {
    setFilter({ ...filterObj });
    toggleModel();
    DealBookingManagment();
  };

  const defaultSorted = [
    {
      dataField: "_id",
      order: "asc",
    },
  ];

  function tog_center() {
    setmodal_center(!modal_center);
    removeBodyCss();
  }

  function activeFormatter(cellContent, row) {
    console.log({ row });
    return (
      <>
        {row.bookingStatus == "initiated" || row.bookingStatus == "pending" || row.bookingStatus == "processed" ? (
          <>
            <button
              className="btn btn-info"
              type="button"
              onClick={() => [setShowOrder(true), setstatusid(row._id)]}
            >
              Initiated
            </button>
          </>
        ) : (
          <>
            <div
              className={
                row.bookingStatus == "confirmed"
                  ? " btn-active"
                  : " btn-deactive"
              }

            >
              {row.bookingStatus}
            </div>
          </>
        )}
      </>
    );
  }

  function createdAtdata(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p>{moment(row.createdAt).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return <>{i + 1}</>;
  }
  const components = [
    <div className="row mb-4" key="amount">
      <label className="col-sm-3 col-form-label">payment Status</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={paymentStatus}
          onChange={(e) => setpaymentStatus(e.target.value)}
          name="paymentStatus"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">dealTitle</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={dealTitle}
          onChange={(e) => setdealTitle(e.target.value)}
          name="dealTitle"
          className="form-control"
        />
      </div>
    </div>,

    <div className="row mb-4" key="title">
      <label className="col-sm-3 col-form-label">dealType</label>
      <div className="col-sm-6">
        <input
          type="text"
          value={dealType}
          onChange={(e) => setdealType(e.target.value)}
          name="dealType"
          className="form-control"
        />
      </div>
    </div>,

    ,
    // submit button

    <div className="mt-5 mt-lg-4" key="submit">
      <div className="row justify-content-end">
        <div className="col-sm-9">
          <div>
            <button
              type="button"
              onClick={applyFilter}
              className="btn btn-primary w-md"
            >
              Filter
            </button>
            <button
              onClick={resetFilter}
              type="button"
              className="btn btn-danger w-md mx-4"
            >
              Reset
            </button>
          </div>
        </div>
      </div>
    </div>,
  ];

  function dateEndFormatter(cellContent, row) {
    return (
      <React.Fragment>{moment(row.endDate).format("MMM Do YY")}</React.Fragment>
    );
  }

  function dateStartFormatter(cellContent, row) {
    return (
      <React.Fragment>
        {moment(row.startDate).format("MMM Do YY")}
      </React.Fragment>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },
    // {
    //   text: "Hotel",
    //   sort: false,
    //   formatter: hotelFormatter,
    // },
    {
      dataField: "deal.title",

      text: "Title",
      sort: false,
    },
    {
      dataField: "userName",
      formatter: userFormatter,
      text: "Image",
      sort: false,
    },
    {
      dataField: "deal.amount",
      text: "Amount",
      sort: false,
    },
    {
      dataField: "deal.finalAmount",
      text: "Final Amount",
      sort: false,
    },
    {
      dataField: "deal.type",
      text: "Type",
      sort: false,
    },
    {
      dataField: "deal.discountText",
      text: "Discount Text",
      sort: false,
    },
    {
      dataField: "paymentStatus",
      text: "paymentStatus",
      sort: false,
    },
    {
      dataField: "deal.startDate",
      text: "start Date",
      formatter: dateStartFormatter,
      sort: false,
    },
    {
      dataField: "deal.endDate",
      text: "End Date",
      formatter: dateEndFormatter,
      sort: false,
    },

    {
      dataField: "booking_status",
      text: "Status",
      formatter: activeFormatter,
      sort: false,
    },
    {
      dataField: "createdAt",
      text: "Created At",
      formatter: createdAtdata,
      sort: false,
    },
    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  function userFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <img
          src={row?.deal?.image}
          onClick={() => showLightBox(row?.deal?.image)}
          alt="Image"
          width={"50px"}
          style={{ borderRadius: "20%" }} // This makes the image round
        />
        <p>{row.userName}</p>
      </div>
    );
  }

  function hotelFormatter(cellContent, row) {
    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <img
          src={row.hotelData.image[1].fileName}
          onClick={() => showLightBox(row.hotelData.image[0].fileName)}
          alt="Image"
          width={"50px"}
          style={{ borderRadius: "20%" }} // This makes the image round
        />
        <p>{row.hotelData.name}</p>
      </div>
    );
  }

  const handleChange = (event) => {
    setSizePerPage(event.target.value);
    if (currentPage != 1) {
      setCurrentPage(1);
    } else {
      DealBookingManagment(null, event.target.value);
    }
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };
  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const handleReload = () => {
    setSearchText('')
    DealBookingManagment(null, null, true);
  };

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          {/* <button type="button" className=" btn btn-info  mx-2">            
            <Link
              to={{
                pathname: `/updateDeal/${row._id}`,
                state: { row },
              }}
            >              
              <i className="fal fa-pencil fs-5 text-light"></i>
            </Link>
          </button> */}
          {canDelete && (
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => handlerdelete(row._id)}
            >
              <i className="fal fa-trash  fs-5"></i>
            </button>
          )}
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  const exportCSV = async () => {
    setLoader(true);

    const filterArgs = getFilters();
    const payload = {
      sortField: sort,
      sortOrder: sortOrder,
    };

    if (filterArgs?.length) {
      payload.filterArgs = filterArgs;
    } else {
      if (searchText) {
        payload.search = searchText;
      }
    }

    try {
      const response = await postRequest(
        `admin/get-all-deals/export-csv`,
        payload
      );
      const data = response.data;
      window.location.href = data.url;
      console.log("data", data);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const DealBookingManagment = async (filterArgs = [], limit = null, isReload = false) => {
    console.log(currentPage);
    setLoader(true);
    const payload = {
      limit: limit ? limit : sizePerPageOrg,
      page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
      paymentStatus: paymentStatus,
      dealTitle: dealTitle,
      dealType: dealType,
    };
    if (!isReload) {
      if (filterArgs?.length) {
        payload.filterArgs = filterArgs;
      } else {
        if (searchText) {
          payload.search = searchText;
        }
      }
    }
    try {
      const response = await postRequest(`admin/get-all-deals`, payload);
      const data = response.data;
      console.log("deal booking list ", { data });
      setTotalPage(data.count);
      setCurrentFPage(data.page);
      setratingdata(data.bookingList);
      // setSearchText('')
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
      setLoader(false);
    }
  };

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`deal/`, {
          dealId: id,
        });
        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          DealBookingManagment();
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />
        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Deal Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">Deal Management</li>:{" "}
                <li className="breadcrumb-item"> Deal Booking List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            {canExport && (
              <button
                onClick={() => {
                  exportCSV();
                }}
                type="button"
                className="btn btn-primary mx-2"
              >
                <i class="fas fa-upload"></i> Export CSV
              </button>
            )}
          </div>

          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <PaginationProvider
                      pagination={paginationFactory({
                        page: currentFPage,
                        sizePerPage: sizePerPageOrg,
                        totalSize: totalPage,
                        custom: true,
                      })}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <>
                          <ToolkitProvider
                            keyField="_id"
                            data={ratingdata}
                            columns={columns}
                          >
                            {(props) => (
                              <Row>
                                <Col className="col-lg-12 d-flex mb-3">
                                  <label className="mb-0 text-label">
                                    Page Size
                                  </label>
                                  <select
                                    value={sizePerPageOrg}
                                    onChange={handleChange}
                                    className="form-select"
                                    style={{ width: "7%" }}
                                  >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                  </select>

                                  <label className="mb-0 text-label ms-3">
                                    Sort By:
                                  </label>
                                  <select
                                    value={sort}
                                    onChange={handlesorting}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="title">Title</option>
                                    <option value="amount">Amount</option>
                                    <option value="finalAmount">
                                      final Amount
                                    </option>
                                    <option value="type">Type</option>
                                    <option value="discountText">
                                      Discount Text
                                    </option>
                                    <option value="startDate">
                                      start Date
                                    </option>
                                    <option value="endDate">End Date</option>
                                  </select>
                                  <label className="mb-0 text-label ms-3">
                                    Sort Order:
                                  </label>
                                  <select
                                    value={sortOrder}
                                    onChange={handlesortOrder}
                                    className="form-select"
                                    style={{ width: "10%" }}
                                  >
                                    <option value="asc"> Ascending</option>
                                    <option value="desc">Descending</option>
                                  </select>
                                  <div className="filter-item">
                                    <input
                                      type="search"
                                      placeholder="Search"
                                      value={searchText}
                                      class="form-control"
                                      onChange={(e) =>
                                        setSearchText(e.target.value)
                                      }
                                      style={{ width: "30%" }}
                                    />
                                    <button
                                      onClick={() => {
                                        DealBookingManagment();
                                      }}
                                      type="button"
                                      className="btn btn-primary mx-2"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="button"
                                      onClick={handleReload}
                                      className="btn btn-danger mx-2"
                                    >
                                      Reset
                                    </button>

                                    <button
                                      type="button"
                                      onClick={toggleModel}
                                      className="btn btn-info"
                                    >
                                      Filter
                                    </button>
                                  </div>
                                </Col>

                                <Col className="col-lg-12 text-end">
                                  <BootstrapTable
                                    onTableChange={handleTableChange}
                                    remote={true}
                                    classes="table-striped"
                                    {...props.baseProps}
                                    {...paginationTableProps}
                                  />
                                </Col>
                              </Row>
                            )}
                          </ToolkitProvider>

                          <Row>
                            <Col className="col-lg-12 text-end">
                              <div className="text-md-right">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </PaginationProvider>
                  }
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </React.Fragment>
      <FilterModal
        show={model}
        closeModal={closeModal}
        components={components}
      />

      <Modal
        isOpen={showOrder}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Update Deal status
          </h5>
          <button
            onClick={() => {
              setShowOrder(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body d-flex justify-content-around">
          <button
            className="btn btn-active"
            type="button"
            style={{ width: "100px" }}
            onClick={() => GetValueOnChange("confirmed")}
          >
            Confirm
          </button>

          <button
            className="btn btn-deactive"
            type="button"
            onClick={() => GetValueOnChange("cancelled")}
            style={{ width: "100px" }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
}
