import React, { useState, useEffect } from 'react';
import { makeStyles,createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      listStyle: 'none',
      padding: theme.spacing(0.5),
      margin: 0,
    },
    chip: {
      margin: theme.spacing(0.5),
    },
    highlighted: {
      backgroundColor: '#7a6fbe !important',
      color: '#fff !important',
    },
  }));
  
  const theme = createMuiTheme({
    overrides: {
      MuiChip: {
        root: {
          '&$focusVisible': {
            backgroundColor: 'inherit',
          },
        },
        clickable: {
          '&:hover': {
            backgroundColor: 'inherit',
          },
          '&$focusVisible': {
            backgroundColor: 'inherit',
          },
        },
      },
    },
  });

function isSelected(item, selectedItemList) {
  return selectedItemList.some(selectedItem => selectedItem._id === item._id);
}

export default function TagSelection({ itemList, selectedItemList, onSelectionChange }) {
  const classes = useStyles();

  // use the passed 'selectedItemList' as the default state
  const [selectedItems, setSelectedItems] = useState([...selectedItemList]);

 const handleClick = (item) => {
  let newSelectedItems;
  if (isSelected(item, selectedItems)) {
    newSelectedItems = selectedItems.filter(selectedItem => selectedItem._id !== item._id);
  } else {
    newSelectedItems = [...selectedItems, item];
  }
  setSelectedItems(newSelectedItems);
  onSelectionChange(newSelectedItems);  // Update here with new state
};

  useEffect(() => {
    onSelectionChange(selectedItems);
  }, [selectedItems, onSelectionChange]);

  return (
    <ThemeProvider theme={theme}>
    <div className={classes.root}>
      {itemList?.map((item) => {
        let label = item.title;

        return (
          <li key={item._id}>
            <Chip
              label={label}
              onClick={() => handleClick(item)}
              className={`${classes.chip} ${isSelected(item, selectedItems) ? classes.highlighted : ''}`}
            />
          </li>
        );
      })}
    </div>
    </ThemeProvider>
  );
}