import { Link } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Row, Col, CardTitle } from "reactstrap";
import {
  getRequest,
  postRequest,
  deleteRequest,
} from "../../components/Common/Utils.js";
import DonutChart from "../AllCharts/DonutChart";
import CountUp from "react-countup";
import { useHistory, useParams, useLocation } from "react-router-dom";
const MonthlyEarnings = (props) => {
  let history = useHistory();
  const [userdata, setuserdata] = useState({});
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    dsManagment();
  }, []);

  const dsManagment = async (page = 0) => {
    setLoader(true);
    const response = await postRequest(`admin/sub-admin/dashboard`);
    const data = response;

    console.log(data);

    // setTotalPage(parseInt(data.total));
    // setCurrentPage(parseInt(currentPage));

    // wait for 2 seconds

    //await new Promise((resolve) => setTimeout(resolve, 2000));

    setuserdata(data);
    // setSearchText('');

    setLoader(false);
  };

  return (
    <React.Fragment>
      <Row>
      <Col xl={6}>
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-4">All Conversation</CardTitle>
            <Row className="text-center mt-4">
              <div className="col-12"> 
                <h2>
                  <CountUp 
                    start={0}
                    end={userdata?.data?.allConversations || 0}
                    duration={1.5}
                  />
                </h2>
                <p className="text-muted">Conversation</p>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>
      <Col xl={6}>
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-4">All Messages</CardTitle>
            <Row className="text-center mt-4">
              <div className="col-12"> 
                <h2>
                  <CountUp 
                    start={0}
                    end={userdata?.data?.allMessages || 0}
                    duration={1.5}
                  />
                </h2>
                <p className="text-muted">Messages</p>
              </div>
            </Row>
          </CardBody>
        </Card>
      </Col>

        {/* <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Venues</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/venues">
                     
                            <p className="text-muted">Venues</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Cuisines</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/cuisines">
               
                            <p className="text-muted">Cuisines</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Themes</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/themes">
                            <h5 className="font-size-20">{userdata?.data?.theme}</h5>
                            <p className="text-muted">Themes</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col> */}
      </Row>
      <Row>
        {/* <Col xl={4}>
            <Card>              
                

                
                <CardBody>
                    <CardTitle className="h4 mb-4">Offers</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                        <a href="/offer">
                            <h5 className="font-size-20">{userdata?.data?.offers}</h5>
                            <p className="text-muted">Offers</p>
                        </a>   
                        </div>
                        
                    </Row>           

                </CardBody>
               
            </Card>
            </Col>
               <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Music</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/music">
                            <h5 className="font-size-20">{userdata?.data?.music}</h5>
                            <p className="text-muted">Music</p>
                            </a>
                            
                        </div>
                        
                    </Row>      

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Features</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/feature">
                            <h5 className="font-size-20">{userdata?.data?.feature}</h5>
                            <p className="text-muted">Features</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Story</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/stories">
                            <h5 className="font-size-20">{userdata?.data?.story}</h5>
                            <p className="text-muted">Story</p>
                            </a>
                            
                        </div>
                        
                    </Row>      

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Gallery</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/galleries">
                            <h5 className="font-size-20">{userdata?.data?.gallery}</h5>
                            <p className="text-muted">Gallery</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col> */}
        {/* <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Follow</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/follow">
                            <h5 className="font-size-20">{userdata?.data?.follow}</h5>
                            <p className="text-muted">Follows</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Wishlists</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/wishlists">
                            <h5 className="font-size-20">{userdata?.data?.wishList}</h5>
                            <p className="text-muted">Wishlists</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col> */}
      </Row>
      {/* <Row>
           
               <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Story</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/stories">
                            <h5 className="font-size-20">{userdata?.data?.story}</h5>
                            <p className="text-muted">Story</p>
                            </a>
                            
                        </div>
                        
                    </Row>      

                </CardBody>
                
            </Card>
            </Col>
            <Col xl={2}>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Gallery</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <a href="/galleries">
                            <h5 className="font-size-20">{userdata?.data?.gallery}</h5>
                            <p className="text-muted">Gallery</p>
                            </a>
                        </div>
                        
                    </Row>           

                </CardBody>
                
            </Card>
            </Col>
          
          
           
            </Row> */}
    </React.Fragment>
  );
};

export default MonthlyEarnings;
