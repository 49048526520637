import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

const useStyles = makeStyles({
  star: {
    color: 'gold',
  },
  starBorder: {
    color: 'grey',
  },
});

const StarComponent = ({ count }) => {
  const classes = useStyles();
  const starArray = [];

  for (let i = 0; i < 5; i++) {
    starArray.push(i < count ? 'star' : 'starBorder');
  }

  return (
    <div>
      {starArray.map((star, index) => (
        star === 'star' 
          ? <StarIcon key={index} className={classes.star} />
          : <StarBorderIcon key={index} className={classes.starBorder} />
      ))}
    </div>
  );
};

export default StarComponent;