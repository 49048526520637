import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, Spinner } from "reactstrap";
import { useLocation } from "react-router-dom";
import { getRequest, postRequest } from "components/Common/Utils";
import { useParams } from "react-router-dom";

export default function Homeblockdetails() {
  const [homeblockdata, setData] = useState([]);
  

  const [loader, setLoader] = useState(true);
  const location = useLocation();
  console.log(location.state);

  const { id } = useParams();

  const HomeBlockFetchDetail = async () => {
    setLoader(false);
    postRequest(`home-block/detail`, { homeId: `${id}` }).then((data) => {
      let homeblockdata = data.data;
      let status = data.status;
      setData(homeblockdata);
    

      if (status == 1) {
        setLoader(false);
      }
      setLoader(false);
    });
  };
  useEffect(() => {
    HomeBlockFetchDetail();
  }, []);

  return (
    <>
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>HomeBlock Management</h4>

            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">HomeBlock Management</li>:{" "}
              <li className="breadcrumb-item">HomeBlock details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>

        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            <Card>
              <div className="row my-2 py-2">
                
                <div className="userinfo col-md-10">
                  <div className="fst">
                    {" "} 
                    <p>
                    Title : <span>
                      {" "}
                      {location.state.row.title}{" "}
                      </span> {" "}
                    </p>{" "}
                  </div>

                  <div className="row">
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Description : <span>
                          {" "}
                          {location.state.row.description}{" "}
                        </span>{" "}
                      </p>{" "}
                    </div>
                   
                  </div>
                 

                  <div className="row">
                  <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Type: <span> {location.state.row.type}</span>{" "}
                      </p>{" "}
                    </div>
                  </div>
                 
                  {location.state.row.type == "offer" ? (
                    <div className="row">
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Offers :{" "}
                        <span> {location.state.row.offers.length}</span>{" "}
                      </p>{" "}
                    </div>
                  </div>) : null}
                  
                  {location.state.row.type == "venue" ? (
                    
                  <div className="row">
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Venues :{" "}
                        <span> {location.state.row.venues}</span>{" "}
                      </p>{" "}
                    </div>
                  </div>) : null}

                  {location.state.row.type == "slider" ? (
                  
                  <div className="row">
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Sliders :{" "}
                        <span> {location.state.row.sliders}</span>{" "}
                      </p>{" "}
                    </div>
                  </div>) : null}

                  {location.state.row.type == "video" ? (
                  <div className="row">
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Videos :{" "}
                        <span> {location.state.row.videos}</span>{" "}
                      </p>{" "}
                    </div>
                  </div>) : null}
                  <div className="row">
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Size Type:{" "}
                        <span> {location.state.row.size.type}</span>{" "}                       
                      </p>{" "}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 fst py-1">
                      {" "}
                      <p>
                        {" "}
                        Size Ratio:{" "}
                        
                        <span> {location.state.row.size.ratio}</span>{" "}
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </Card>
          </>
        )}
      </Row>
    </>
  );
}
