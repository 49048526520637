import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequest, postRequestForm } from "../../components/Common/Utils.js";

import PackageModal from "components/Modal/PackageModal.js";

import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";

import VenueModal from "../../components/Modal/HotelModal.js";

export default function CreateOffer() {
  const location = useLocation();
  const state = location.state?.row;

  const [offerData, setHomeBlockData] = useState({ type: "video" });
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [venueList, setVenueList] = useState([]);

  const [dayList, setDayList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedDay, setSelectedDay] = useState([]);

  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(false);
  const [packages, setPackages] = useState([]);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [discountList, setDiscountList] = useState([]);


  const fetchDiscount = async () => {
    setLoading(true);
    const response = await postRequest("discount/list", { limit: 10000000 });
    if (response.status == 1) {
      setDiscountList(response.data.list);
    }
    setLoading(false);
  };
 

  const selectRow = (id, name) => {
    setVenueId(id);
    setVenueText(name);
    closeModalVenue();
  };

  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };

  const toggleModal = () => {
    setOpen(!open);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const closeModalVenue = () => {
    setModel(false);
  };

  //const [day]

  const msUpdateDay = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedDay(ids);
  };

  const updateDiscount = (index, value) => {
    const list = [...packages];
    list[index].discount = value;
    setPackages(list);
  };

  let history = useHistory();
  const { id } = useParams();
  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    fetchList();
    fetchCategoryList();
    fetchPackages();
    fetchDiscount();
    // fetch days

    const days = [
      { id: "mon", name: "Monday" },
      { id: "tue", name: "Tuesday" },
      { id: "wed", name: "Wednesday" },
      { id: "thu", name: "Thursday" },
      { id: "fri", name: "Friday" },
      { id: "sat", name: "Saturday" },
      { id: "sun", name: "Sunday" },
    ];
    setDayList(days);
  }, []);

  const fetchPackages = async () => {
    setLoading(true);
    const response = await postRequest("membership-package/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      setPackages(response.data.list);
      console.log("response.data.list", response.data.list);
    }
    setLoading(false);
  };

  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);
    }
    setLoading(false);
  };

  const fetchCategoryList = async () => {
    setLoading(true);
    const response = await postRequest("offer/category/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.title,
        };
      });
      setCategoryList(list);
    }
    setLoading(false);
  };

  const createOffer = async (e) => {
    e.preventDefault();
    setLoading(true);

    // combine date and time
    const startDate = new Date(offerData.startDate + " " + offerData.startTime);
    const endDate = new Date(offerData.endDate + " " + offerData.endTime);

    const formData = new FormData();
    formData.append("title", offerData.title);
    formData.append("description", offerData.description);

    // sort the selected days by day order
    selectedDay.sort((a, b) => {
      const dayOrder = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
      return dayOrder.indexOf(a) - dayOrder.indexOf(b);
    });

    formData.append("days", selectedDay.join(","));
    formData.append("categoryId", offerData.categoryId);
    formData.append("image", selectedImage);    
    formData.append("venue", venueId);
    formData.append("allowWhosin", offerData.allowWhosin == "1" ? true : false);
    formData.append("startTime", startDate);
    formData.append("endTime", endDate);
    formData.append("discount", offerData.discount);

    packages.forEach((item, index) => {
      formData.append(`package_ids[]`, item._id);
      formData.append(`offer_discounts[]`, item.discount);
    });

    const response = await postRequestForm("offer/create", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);

      setTimeout(() => {
        history.push("/offer");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...offerData };
    list[index] = value;

    console.log("list", value);

    setHomeBlockData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Offer Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Offer Management</li>:{" "}
              <li className="breadcrumb-item">Create Offer</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create offer </h4>
                <Form onSubmit={createOffer}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Category
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("categoryId", e.target.value)
                              }
                              name="categoryId"
                              className="form-control"
                            >
                              <option value="">Select Category</option>
                              {categoryList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Venue
                          </label>
                          
                          <div
                            className="col-sm-6"
                            onClick={() => toggleModel()}
                          >
                            {venueText ? (
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {venueText}
                                  </a>
                                </div>
                                <div>
                                 
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Select Venue
                                  </a>
                                </div>
                                <div>
                                  <a
                                    onClick={() => toggleModel()}
                                    href="javascript:void(0)"
                                  >
                                    <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Days
                          </label>
                          <div className="col-sm-6">
                            <MultiSelect
                              options={dayList}
                              onSelect={msUpdateDay}
                              selectedValues={[]}
                              placeholder="Select Days"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("startDate", e.target.value)
                              }
                              name="startDate"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Start Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) =>
                                updateValue("startTime", e.target.value)
                              }
                              name="startTime"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Date
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="date"
                              onChange={(e) =>
                                updateValue("endDate", e.target.value)
                              }
                              name="endDate"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            End Time
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="time"
                              onChange={(e) =>
                                updateValue("endTime", e.target.value)
                              }
                              name="endTime"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Allow Whosin
                          </label>
                          <div className="col-sm-6">
                            <select
                              onChange={(e) =>
                                updateValue("allowWhosin", e.target.value)
                              }
                              name="allowWhosin"
                              className="form-control"
                            >
                              <option value="1">Yes</option>
                              <option value="0" selected>
                                No
                              </option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="cover"
                              className="form-control"
                              id="horizontal-password-input"
                              required="required"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                       

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Enter Packages Discounts
                          </label>
                          <div className="col-sm-6">
                            <table class="table">
                              <thead>
                                <tr>
                                  <th>Package Title</th>
                                  <th>Package Amount</th>
                                  <th>Package Sub title</th>
                                  <th>Select Offer Discount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr>
                                    <td>{item.title}</td>
                                    <td>
                                      {item.discountedPrice} / {item.time}
                                    </td>
                                    <td>{item.subTitle}</td>
                                    <td>
                                      <input
                                        type="text"
                                        onChange={(e) =>
                                          updateDiscount(index, e.target.value)
                                        }
                                        name="discount"
                                        className="form-control"
                                      />
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      <VenueModal
        show={model}
        closeModal={closeModalVenue}
        selectRow={selectRow}
      />

      {/* modal */}
    </React.Fragment>
  );
}
