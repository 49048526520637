import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Modal,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import icon1 from "../../assets/images/icon1.png";
import Swal from "sweetalert2/dist/sweetalert2.js";

import { postRequest, putRequest } from "../../components/Common/Utils.js";
import Select from "react-select";
import MultiSelect from "../../components/Multiselect/MultiselectCommon.js";
import noImage from "../../assets/images/No_Image_Available.jpg";

import HotelModal from "../../components/Modal/HotelModal.js";
import CustomCategoryModal from "../../components/Modal/CustomCategoryModal.js";
import CategoryModal from "../../components/Modal/CategoryModal.js";
import BannerModal from "components/Modal/BannerModal.js";
import TicketModal from "components/Modal/TicketModal.js";
import ComponentModal from "components/Modal/ComponentModal.js";
import TransferModal from "components/Modal/TransferModal.js";
import { set } from "lodash";
import CustomTicketManagementList from "pages/CustomTicket/CustomTicketManagementList";
import CustomTicketlistModal from "components/Modal/CustomTicketlistModal";
import DragDropModal from "components/Modal/DragDropModal";

export default function UpdateHomeBlock() {
  const location = useLocation();
  const state = location.state?.row;
  const [customDataVenue, setDataVenue] = useState([]);
  const [customDataOffer, setDataOffer] = useState([]);
  const [venueData, setVenueData] = useState([]);
  const [offerData, setOfferData] = useState([]);
  const [coverLogo, setCoverLogo] = useState([]);
  const [offerListDetail, setOfferListDetail] = useState([]);
  const [venueListDetail, setVenueListDetail] = useState([]);
  const [componentList, setComponentList] = useState([]);
  const [showOrder, setShowOrder] = useState(false);
  const [dragDropData, setDragDropData] = useState([]);
  const [bannerId, setbannerId] = useState([]);
  const [ticketId, setTicketId] = useState([]);
  const [customrId, setCustomId] = useState([]);
  const [transferId, setTransferId] = useState([]);
  const [offergetId, setOfferId] = useState([]);
  const [venuegetId, setVenueId] = useState([]);
  const [venueText, setVenueText] = useState("");
  const [customCategoryId, setCustomCategoryId] = useState([]);
  const [customCategoryPreview, setCustomCategoryPreview] = useState([]);

  const [bannerPreview, setBannerPreview] = useState([]);
  const [ticketPreview, setTicketPreview] = useState([]);
  const [offerPreview, setOfferPreview] = useState([]);
  const [venuPreview, setVenuPreview] = useState([]);
  const [customPreview, setCustomPreview] = useState([]);
  const [transferPreview, setTransferPreview] = useState([]);

  const [homeData, setHomeBlockData] = useState({ type: "video" });
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const [venueList, setVenueList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [idName, setIdName] = useState("venueId");
  const [typecustom, setTypeCustom] = useState("");

  const [venueoptions, setvenueOptions] = useState([]);
  const [offeroptions, setofferOptions] = useState([]);

  const [sizeList, setSizeList] = useState([]);
  const [model, setModel] = useState(false);
  const [model2, setModel2] = useState(false);
  const [model3, setModel3] = useState(false);
  const [model4, setModel4] = useState(false);
  const [model5, setModel5] = useState(false);
  const [model6, setModel6] = useState(false);
  const [customCategoryModelOpen, setCustomCategoryModelOpen] = useState(false);

  const [selectedVideo, setSelectedVideo] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState([]);
  const [selectedSlider, setSelectedSlider] = useState([]);

  const [defaultVenue, setDefaultVenue] = useState([]);
  const [defaultVideo, setDefaultVideo] = useState([]);
  const [defaultComponent, setDefaultComponent] = useState([]);
  const [defaultOffer, setDefaultOffer] = useState([]);

  const [venueLoaded, setVenueLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [customCompLoaded, setCustomCompLoaded] = useState(false);
  const [offerLoaded, setOfferLoaded] = useState(false);
  const [modelcustom, setModelCustom] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [editVenueIndex, setVenueIndex] = useState(null);
  const [editOfferIndex, setOfferIndex] = useState(null);
  const [addVenue, setVenueAdd] = useState(null);

  const [formValues, setFormValues] = useState([]);
  const [currentForm, setCurrentForm] = useState({
    title: "",
    subTitle: "",
    description: "",
    info: "",
    badge: "",
    typeselect: "",
  });

  const msUpdateVideo = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    console.log("selectedList", selectedList);
    setSelectedVideo(ids);
  };

  const msUpdateComponent = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    console.log("selectedList", selectedList);

    setSelectedComponent(ids);
  };

  const msUpdateVenue = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedVenue(ids);
  };

  const msUpdateOffer = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedOffer(ids);
  };

  let history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    // fetchList();
    // fetchVideoList();
    // fetchComponentList();
    // fetchSizeList();
    // HomeBlockFetchDetail();
    fetchSizeList();
    console.log(state);
  }, []);
  const fetchSizeList = async () => {
    setLoading(true);
    const response = await postRequest("homepage/size/list", {
      limit: 10000000,
    });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          type: item.type,
          ratio: item.ratio,
        };
      });
      setSizeList(list);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!state) return;

    setSelectedVenue(state.venues);
    setSelectedComponent(state.customComponents);
    setSelectedVideo(state.videos);
    setSelectedOffer(state.offers);
    setHomeBlockData(state);

    if (state.type == "banner") {
      const banners = state.banner?.map((item) => {
        return {
          id: item._id,
          cover: item.image,
          logo: item.image,
          address: `${item.type} - ${item.selectedName}`,
          label: item.title,
        };
      });

      const bannerIds = state.banner?.map((item) => item._id);

      setBannerPreview([...banners]); // Use previous state to avoid any inconsistencies
      setbannerId([...bannerIds]); // Update the bannerId array by appending the new id
    }

    if (state.type == "custom_components") {
      console.log("custom_components", state?.custom_components);

      const customComp = state?.custom_components?.map((item) => {
        return {
          id: item._id,
          cover: item.image || item.backgroundImage,
          logo: item.image || item.backgroundImage,
          address: item.contentType,
          label: item.title,
        };
      });

      const customCompIds = state.custom_components?.map((item) => item._id);

      setCustomPreview([...customComp]);
      setCustomId([...customCompIds]);
    }

    if (state.type == "category") {
      const categories = state.category?.map((item) => {
        return {
          id: item._id,
          cover: item.image,
          logo: item.image,
          address: item.description,
          label: item.title,
          title: item.title,
          description: item.description,
        };
      });

      const categoryIds = state.category?.map((item) => item._id);

      setOfferPreview([...categories]);
      setOfferId([...categoryIds]);
    }

    if (state.type == "custom_categories") {
      const custom_categories = state.custom_categories || [];

      const customCategoryIds = custom_categories?.map((item) => item._id);

      setCustomCategoryPreview([...custom_categories]);
      setCustomCategoryId([...customCategoryIds]);
    }

    if (
      state.type == "inspired_by_elevate" ||
      state.type == "activities_and_events"
    ) {
      let arr = [],
        cover,
        logo,
        address,
        label;
      console.log(selectRow, "selectRow");
      cover =
        "https://cdn.pixabay.com/photo/2017/10/02/18/55/belem-tower-2809818_1280.jpg";
      logo = cover;
      address = selectRow.bookingType;
      label = selectRow.title;
      arr = { cover, logo, address, label };

      let tickets = [];
      let ticketIds = [];
      console.log({ state });

      if (state.type == "inspired_by_elevate") {
        console.log("inspired_by_elevate", state.inspired_by_elevate);
        tickets = state.inspired_by_elevate?.map((item) => {
          let serviceContentInfo = item?.serviceContentInfo;
          let images = item?.images[0];
          console.log("5555", { item });
          // let image = images?.find(i => i.Type == "BIG" && i.FileName != "")
          cover =
            "https://cdn.pixabay.com/photo/2017/10/02/18/55/belem-tower-2809818_1280.jpg";
          return {
            id: item._id,
            cover: item?.images[0] || cover,
            logo: item?.images[0] || cover,
            address: item?.bookingType,
            label: item?.title,
            title: item?.title,
            description: item?.description,
          };
        });
        ticketIds = state.inspired_by_elevate?.map((item) => item._id);
      } else {
        console.log("activities_and_events", state.activities_and_events);
        tickets = state.activities_and_events?.map((item) => {
          let serviceContentInfo = item?.serviceContentInfo;
          let images = item?.images[0];
          console.log("5555", { item });
          // let image = images?.find(i => i.Type == "BIG" && i.FileName != "")
          cover =
            "https://cdn.pixabay.com/photo/2017/10/02/18/55/belem-tower-2809818_1280.jpg";

          return {
            id: item._id,
            cover: item?.images[0] || cover,
            logo: item?.images[0] || cover,
            address: item?.bookingType,
            label: item?.title,
            title: item?.title,
            description: item?.description,
          };
        });
        ticketIds = state.activities_and_events?.map((item) => item._id);
      }

      console.log("tickets", tickets, ticketIds);
      setTicketPreview([...tickets]);
      setTicketId([...ticketIds]);
    }

    if (state.type == "hotel") {
      const hotels = state.hotel?.map((item) => {
        return {
          id: item._id,
          cover: item.coverImage,
          logo: item.thumbImage,
          address: item.address,
          label: `${item.name} - ${item.city}`,
        };
      });

      const hotelIds = state.hotel?.map((item) => item._id);

      setVenuPreview([...hotels]);
      setVenueId([...hotelIds]);
    }

    if (state.type == "transfer") {
      const transfers = state.transfer?.map((item) => {
        return {
          id: item._id,
          cover: item.image,
          logo: item.image,
          address: item.description,
          label: item.name,
        };
      });

      const transferIds = state.transfer?.map((item) => item._id);

      setTransferPreview([...transfers]);
      setTransferId([...transferIds]);
    }
  }, [state]);

  useEffect(() => {
    if (!selectedVenue?.length) return;
    if (venueLoaded) return;
    fetchList();
  }, [selectedVenue]);

  useEffect(() => {
    if (!selectedVideo?.length) return;
    if (videoLoaded) return;
    fetchVideoList();
  }, [selectedVideo]);

  useEffect(() => {
    if (!selectedComponent?.length) return;
    if (customCompLoaded) return;
    fetchComponentList();
  }, [selectedComponent]);

  useEffect(() => {
    console.log(customDataVenue);
    if (!selectedOffer?.length) return;
    if (offerLoaded) return;
    fetchOfferList();
  }, [selectedOffer, customDataVenue]);

  const handleRemovevenuPreview = (index) => {
    const newArray = [...venuPreview];
    newArray.splice(index, 1);
    setVenuPreview(newArray);
    const newvenue = [...venuegetId];
    newvenue.splice(index, 1);
    setVenueId(newvenue);
  };

  const handleRemovetransferPreview = (index) => {
    const newArray = [...transferPreview];
    newArray.splice(index, 1);
    setTransferPreview(newArray);
    const newvenue = [...transferId];
    newvenue.splice(index, 1);
    setTransferId(newvenue);
  };

  const handleRemoveBannerPreview = (index) => {
    const newArray = [...bannerPreview];
    newArray.splice(index, 1);
    setBannerPreview(newArray);
    const newvenue = [...bannerId];
    newvenue.splice(index, 1);
    setbannerId(newvenue);
  };

  const handleRemoveTicketPreview = (index) => {
    const newArray = [...ticketPreview];
    newArray.splice(index, 1);
    setTicketPreview(newArray);
    const newvenue = [...ticketId];
    newvenue.splice(index, 1);
    setTicketId(newvenue);
  };

  const handleRemoveCustomPreview = (index) => {
    const newArray = [...customPreview];
    newArray.splice(index, 1);
    setCustomPreview(newArray);
    const newvenue = [...customrId];
    newvenue.splice(index, 1);
    setCustomId(newvenue);
  };
  const handleRemoOfferPreview = (index) => {
    const newArray = [...offerPreview];
    newArray.splice(index, 1);
    setOfferPreview(newArray);
    const newvenue = [...offergetId];
    newvenue.splice(index, 1);
    setOfferId(newvenue);
  };

  // const fetchSizeList = async () => {
  //   setLoading(true);
  //   const response = await postRequest("home-block/size/list", {
  //     limit: 10000000,
  //   });
  //   if (response.status == 1) {
  //     const list = response.data.list.map((item) => {
  //       return {
  //         id: item._id,
  //         type: item.type,
  //         ratio: item.ratio,
  //       };
  //     });
  //     setSizeList(list);
  //   }
  //   setLoading(false);
  // };

  // const fetchOfferList = async () => {
  //   setLoading(true);
  //   const response = await postRequest("offer/list", { limit: 10000000 });

  //   console.log("offer list", response.status);

  //   if (response.status == 1) {
  //     const list = response.data.list.map((item) => {
  //       return {
  //         id: item._id,
  //         name: item.title,
  //       };
  //     });
  //     setOfferList(list);
  //     const mappedOptions = response.data.list.map((item) => ({
  //       value: item._id,
  //       label: item.title,
  //     }));
  //     setofferOptions(mappedOptions);
  //     setOfferListDetail(response.data.list)
  //     const defaultItems = list.filter((item) => {
  //       return selectedOffer.includes(item.id);
  //     });

  //     setOfferLoaded(true);

  //     setDefaultOffer(defaultItems);
  //   }
  //   setLoading(false);
  // };

  // const fetchList = async () => {
  //   setLoading(true);
  //   const response = await postRequest("venue/list", { limit: 10000000 });
  //   if (response.status == 1) {
  //     const list = response.data.list.map((item) => {
  //       return {
  //         id: item._id,
  //         name: item.name,
  //       };
  //     });
  //     setVenueList(list);
  //     const mappedOptions = response.data.list.map((item) => ({
  //       value: item._id,
  //       label: item.name,
  //     }));
  //     setVenueListDetail(response.data.list)
  //     setvenueOptions(mappedOptions);

  //     const defaultItems = list.filter((item) => {
  //       return selectedVenue.includes(item.id);
  //     });
  //     setVenueLoaded(true);
  //     setDefaultVenue(defaultItems);
  //   }
  //   setLoading(false);
  // };

  // const fetchVideoList = async () => {
  //   setLoading(true);
  //   const response = await postRequest("home-block/video-component/list", {
  //     limit: 10000000,
  //   });
  //   if (response.status == 1) {
  //     const list = response.data.list.map((item) => {
  //       return {
  //         id: item._id,
  //         name: item.title,
  //       };
  //     });
  //     setVideoList(list);

  //     const defaultItems = list.filter((item) => {
  //       return selectedVideo.includes(item.id);
  //     });
  //     setDefaultVideo(defaultItems);
  //   }

  //   setVideoLoaded(true);

  //   setLoading(false);
  // };

  // const fetchComponentList = async () => {
  //   setLoading(true);
  //   const response = await postRequest("home-block/custom-component/list", {
  //     limit: 10000000,
  //   });
  //   if (response.status == 1) {
  //     const list = response.data.list.map((item) => {
  //       return {
  //         id: item._id,
  //         name: item.title,
  //       };
  //     });
  //     setComponentList(list);

  //     const defaultItems = list.filter((item) => {
  //       return selectedComponent.includes(item.id);
  //     });
  //     setCustomCompLoaded(true);
  //     setDefaultComponent(defaultItems);
  //   }
  //   setLoading(false);
  // };

  const toggleModel = () => {
    let length = venueData.length;
    setVenueIndex(length);
    setModel(!model);
  };
  const toggleModel2 = () => {
    let length = offerData.length;
    setModel2(!model2);
    setOfferIndex(length);
  };

  const toggleModel3 = () => {
    setModel3(!model3);
  };

  const toggleModel4 = () => {
    setModel4(!model4);
  };
  const toggleModel5 = () => {
    setModel5(!model5);
  };
  const toggleModel6 = () => {
    setModel6(!model6);
  };

  const EdittoggleModelVenue = (index) => {
    const setindex = index;
    setVenueIndex(setindex);
    setModel(!model);
  };
  const EdittoggleModelOffer = (index) => {
    const setindex = index;
    setOfferIndex(setindex);
    setModel2(!model2);
  };

  const closeModal2 = () => {
    setModel2(false);
  };

  const closeModal3 = () => {
    setModel3(false);
  };
  const closeModal4 = () => {
    setModel4(false);
  };
  const closeModal5 = () => {
    setModel5(false);
  };
  const closeModal6 = () => {
    setModel6(false);
  };

  const closeModal = () => {
    setModel(false);
  };

  const selectRow = (selectRow, id, name) => {
    let arr = [],
      cover,
      logo,
      address,
      label;

    console.log(selectRow, "selectRow");

    cover = selectRow.coverImage;
    logo = selectRow.thumbImage;
    address = selectRow.address;
    label = selectRow.name;
    arr = { cover, logo, address, label };
    setVenuPreview([...venuPreview, arr]);
    console.log(venuPreview);
    setVenueId([...venuegetId, selectRow?._id]);
    setVenueText(name);
    closeModal();
  };

  const selectRow2 = (selectRow2, id) => {
    console.log(selectRow2, "selectRow2");

    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      description;
    title = selectRow2.title;
    description = selectRow2.description;
    cover = selectRow2.image;
    logo = selectRow2.image;
    address = selectRow2.description;
    label = selectRow2.title;
    arr = { cover, logo, address, label, title, description };
    setOfferPreview([...offerPreview, arr]);
    setOfferId([...offergetId, id]);
    closeModal2();
  };

  const selectRow3 = (selectRow, id) => {
    let arr = [],
      cover,
      logo,
      address,
      label;
    cover = selectRow.image;
    logo = selectRow.image;
    address = selectRow.type + " - " + selectRow.selectedName;
    label = selectRow.title;
    arr = { cover, logo, address, label };
    setBannerPreview((prevBanner) => [...prevBanner, arr]); // Use previous state to avoid any inconsistencies
    setbannerId((prevBannerId) => [...prevBannerId, selectRow._id]); // Update the bannerId array by appending the new id
    closeModal3();
  };

  const customCategorySelect = (customCategory, id) => {
    //console.log(selectRow2, "selectRow2")
    if (customCategoryId.includes(id)) {
      // Show toast message
      toast.error("This Custom category has already been selected.");
      return; // Exit the function
    }
    let arr = [],
      cover,
      logo,
      address,
      label,
      title,
      description;

    console.log("image ", customCategory.image);

    title = customCategory?.name;
    description = customCategory?.description;
    cover = customCategory?.image;
    // logo = customCategory?.image;
    address = customCategory?.description;
    label = customCategory?.name;
    arr = { cover, logo, address, label, title, description };
    setCustomCategoryPreview([...customCategoryPreview, customCategory]);
    setCustomCategoryId([...customCategoryId, id]);
    toggleCustomCategoryModel();
  };

  const handleRemoCustomCategoryPreview = (index) => {
    const newArray = [...customCategoryPreview];
    newArray.splice(index, 1);
    setCustomCategoryPreview(newArray);
    const newids = [...customCategoryId];
    newids.splice(index, 1);
    setCustomCategoryId(newids);
  };
  const selectRow4 = (selectRow, id) => {
    let arr = [],
      cover,
      logo,
      address,
      label;
    console.log("selectRow", selectRow, id);
    cover = selectRow.image || selectRow.backgroundImage;
    logo = selectRow.image || selectRow.backgroundImage;
    address = selectRow.link;
    label = selectRow.title;
    arr = { cover, logo, address, label };
    setCustomPreview([...customPreview, arr]);
    setCustomId([...customrId, id]);
    closeModal4();
  };

  // const selectRow5 = (selectRow, id) => {
  //   if (!ticketId.includes(selectRow._id)) {
  //     let arr = [],
  //       cover,
  //       logo,
  //       address,
  //       label;
  //     console.log({ selectRow });
  //     let serviceContentInfo = selectRow?.serviceContentInfo;
  //     let images = serviceContentInfo.Images;
  //     let descriptions = serviceContentInfo.Descriptions;
  //     let image = images?.find((i) => i.Type == "BIG" && i.FileName != "");
  //     let description = descriptions?.find((i) => i.Description != null);
  //     cover = image
  //       ? image
  //       : "https://cdn.pixabay.com/photo/2017/10/02/18/55/belem-tower-2809818_1280.jpg";
  //     logo = cover;
  //     address = selectRow.code;
  //     label = selectRow.name;
  //     arr = { cover, logo, address, label };
  //     if (!image && !description) {
  //       Swal.fire({
  //         title: "Are you sure you want to add?",
  //         text: "This Ticket has no description or images!",
  //         icon: "warning",
  //         showCancelButton: true,
  //         confirmButtonColor: "#3085d6",
  //         cancelButtonColor: "#d33",
  //         confirmButtonText: "Yes!",
  //       }).then(async (result) => {
  //         if (result.isConfirmed) {
  //           setTicketPreview([...ticketPreview, arr]);
  //           setTicketId([...ticketId, selectRow._id]);
  //           closeModal5();
  //         }
  //       });
  //     } else {
  //       setTicketPreview([...ticketPreview, arr]);
  //       setTicketId([...ticketId, selectRow._id]);
  //       closeModal5();
  //     }
  //   } else {
  //     toast.error("Item already selected!");
  //   }
  // };

  const selectRow5 = (selectRow, id) => {
    if (!ticketId.includes(selectRow._id)) {
      let arr = [],
        cover,
        logo,
        address,
        label;
      console.log({ selectRow });
      let serviceContentInfo = selectRow?.serviceContentInfo;
      let images = selectRow?.images?.[0];
      console.log({ images });
      // let images = serviceContentInfo.Images
      // let descriptions = serviceContentInfo.Descriptions

      let descriptions = selectRow.description;

      // let image = images?.find((i) => i.Type == "BIG" && i.FileName != "");

      // let description = descriptions?.find((i) => i.Description != null);
      cover = images
        ? images
        : "https://cdn.pixabay.com/photo/2017/10/02/18/55/belem-tower-2809818_1280.jpg";
      logo = cover;
      // address = selectRow.code;
      address = selectRow.bookingType;
      label = selectRow.title;
      arr = { cover, logo, address, label };
      if (!images && !descriptions) {
        Swal.fire({
          title: "Are you sure you want to add?",
          text: "This Ticket has no description or images!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes!",
        }).then(async (result) => {
          if (result.isConfirmed) {
            setTicketPreview([...ticketPreview, arr]);
            setTicketId([...ticketId, selectRow._id]);
            closeModal5();
          }
        });
      } else {
        setTicketPreview([...ticketPreview, arr]);
        setTicketId([...ticketId, selectRow._id]);
        closeModal5();
      }
    } else {
      toast.error("Item already selected!");
    }
  };

  const selectRow6 = (selectRow, id) => {
    let arr = [],
      cover,
      logo,
      address,
      label;
    console.log(selectRow, "selectRow");
    //cover=selectRow.coverImage;
    logo = selectRow.image;
    address = selectRow.description;
    label = selectRow.name;
    arr = { cover, logo, address, label };
    setTransferPreview([...transferPreview, arr]);
    setTransferId([...transferId, selectRow._id]);
    closeModal6();
  };

  const updtHomeBlock = async (e) => {
    e.preventDefault();
    if (!homeData.title) {
      toast.error("Please enter title");
      return;
    }
    if (!homeData.description) {
      toast.error("Please select description");
      return;
    }

    if (homeData.type == "video" && !selectedVideo?.length) {
      toast.error("Please select video");
      return;
    }

    let venueIds = [];

    if (homeData.type == "venue" && !venueData?.length) {
      toast.error("Please select venue");
      return;
    } else {
      venueData.map((item) => {
        venueIds.push(item._id);
      });
    }
    // if (homeData.type == "venue" && !selectedVenue?.length) {
    //   toast.error("Please select venue");
    //   return;
    // }
    let offerIds = [];
    if (homeData.type == "offer" && !offerData?.length) {
      toast.error("Please select offer");
      return;
    } else {
      offerData.map((item) => {
        offerIds.push(item._id);
      });
    }

    if (homeData.type == "slider" && !selectedSlider?.length) {
      toast.error("Please select slider");
      return;
    }
    if (homeData.type == "custom_categories" && !customCategoryId.length) {
      toast.error("Please select  Custom category");
      return;
    }
    setLoading(true);

    const payload = {
      ...homeData,
      homeBlockId: homeData._id,
      // hotel: venuegetId,
      // category: offergetId,
      // banner: bannerId,
      transfer: transferId,
      // custom_components: customrId,
      // inspired_by_elevate: ticketId,
      // activities_and_events: ticketId,
    };

    if (homeData?.type == "activities_and_events") {
      payload.hotel = [];
      payload.category = [];
      payload.banner = [];
      payload.transfer = [];
      payload.custom_components = [];
      payload.inspired_by_elevate = [];
      payload.activities_and_events = ticketId;
      payload.custom_categories = [];
    } else if (homeData?.type == "inspired_by_elevate") {
      payload.hotel = [];
      payload.category = [];
      payload.banner = [];
      payload.transfer = [];
      payload.custom_components = [];
      payload.inspired_by_elevate = ticketId;
      payload.activities_and_events = [];
      payload.custom_categories = [];
    } else if (homeData?.type == "custom_components") {
      payload.hotel = [];
      payload.category = [];
      payload.banner = [];
      payload.transfer = [];
      payload.custom_components = customrId;
      payload.inspired_by_elevate = [];
      payload.activities_and_events = [];
      payload.custom_categories = [];
    } else if (homeData?.type == "banner") {
      payload.hotel = [];
      payload.category = [];
      payload.banner = bannerId;
      payload.transfer = [];
      payload.custom_components = [];
      payload.inspired_by_elevate = [];
      payload.activities_and_events = [];
      payload.custom_categories = [];
    } else if (homeData?.type == "category") {
      payload.hotel = [];
      payload.category = offergetId;
      payload.banner = [];
      payload.transfer = [];
      payload.custom_components = [];
      payload.inspired_by_elevate = [];
      payload.activities_and_events = [];
      payload.custom_categories = [];
    } else if (homeData?.type == "hotel") {
      payload.hotel = venuegetId;
      payload.category = [];
      payload.banner = [];
      payload.transfer = [];
      payload.custom_components = [];
      payload.inspired_by_elevate = [];
      payload.activities_and_events = [];
      payload.custom_categories = [];
    } else if (homeData?.type == "custom_categories") {
      payload.hotel = venuegetId;
      payload.category = [];
      payload.banner = [];
      payload.transfer = [];
      payload.custom_components = [];
      payload.inspired_by_elevate = [];
      payload.activities_and_events = [];
      payload.custom_categories = customCategoryId;
    }
    // let url = "story/create"; custom_categories: customCategoryId,
    // if(storyData.mediaType == 'video'){
    //   url = "story/create/video";
    // }

    const response = await putRequest("homepage/home-block", payload);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/homeblock");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...homeData };
    list[index] = value;
    setHomeBlockData(list);
    if (index == "type") {
      if (value == "custom-offer") {
        setTypeCustom("custom");
        setIdName("offerId");
        setCoverLogo([]);
      } else if (value == "custom-venue") {
        setIdName("venueId");
        setTypeCustom("custom");
        setCoverLogo([]);
      } else {
        setTypeCustom("");
      }
    }
  };

  const [numFieldsVenue, setNumFieldsVenue] = useState(1);
  const [numFieldsOffer, setNumFieldsOffer] = useState(1);
  const handleAddFieldVenue = () => {
    setNumFieldsVenue(numFieldsVenue + 1);
  };
  const handleAddFieldOffer = () => {
    setNumFieldsOffer(numFieldsOffer + 1);
  };
  const handleRemoveFieldVenue = (index) => {
    if (index > 0) {
      if (customDataVenue?.length > index) {
        let data = customDataVenue.slice(index);
        setDataVenue(data);
      }
      console.log(customDataVenue);
      setNumFieldsVenue(numFieldsVenue - 1);
    }
    delete coverLogo[index];
  };
  const handleRemoveFieldOffer = (index) => {
    if (index > 0) {
      if (customDataOffer?.length > index) {
        let data = customDataOffer.slice(index);
        setDataOffer(data);
      }
      setNumFieldsOffer(numFieldsOffer - 1);
    }
    delete coverLogo[index];
  };

  const HomeBlockFetchDetail = async () => {
    postRequest(`home-block/detail`, { id: `${id}` }).then((data) => {
      if (data.data[0].type == "custom-venue") {
        let homeblockdata = data.data[0].customVenues;
        let homeblockdata1 = data.data[0];
        console.log("homeblockdata1");
        console.log(homeblockdata1);
        setDataVenue(homeblockdata);
        setNumFieldsVenue(homeblockdata?.length);
        setTypeCustom("custom");
        setIdName("venueId");
      } else if (data.data[0].type == "custom-offer") {
        let homeblockdata = data.data[0].customOffers;
        setDataOffer(homeblockdata);
        setNumFieldsOffer(homeblockdata?.length);
        setTypeCustom("custom");
        setIdName("offerId");
      } else if (data.data[0].type == "venue") {
        let homeblockdata = data.data[0].venues;
        setVenueData(homeblockdata);
        setNumFieldsOffer(homeblockdata?.length);
        // setTypeCustom('custom');
        // setIdName("offerId");
      } else if (data.data[0].type == "offer") {
        let homeblockdata = data.data[0].offers;
        setOfferData(homeblockdata);
        setNumFieldsOffer(homeblockdata?.length);
        console.log(homeblockdata);
      }
    });
  };
  const handleCoverChange = (e, index) => {
    let arr = [],
      cover,
      logo,
      address,
      label;
    arr = arr.concat(coverLogo);
    let value = e.value;
    let title = e.label;
    if (homeData?.type == "custom-venue") {
      let result = venueListDetail.find((i) => {
        if (i._id == value) {
          return i;
        }
      });
      cover = result.cover;
      logo = result.logo;
      address = result.address;
      label = result.name;
    } else if (homeData?.type == "custom-offer") {
      let result = offerListDetail.find((i) => {
        if (i._id == value) {
          return i;
        }
      });
      console.log(result);
      cover = result.venue.cover;
      logo = result.venue.logo;
      address = result.venue.address;
      label = result.venue.name;
    }
    console.log({ cover, logo, address, label });
    let newObj = {
      ...currentForm,
      cover,
      logo,
      address,
      label,
      [idName]: value,
    };
    console.log(newObj);
    setCurrentForm(newObj);
  };

  const toggleModelCustom = () => {
    console.log(homeData?.type);
    if (homeData?.type == "custom-venue") {
      let length = customDataVenue.length;
      setCurrentIndex(length);
    } else if (homeData?.type == "custom-offer") {
      let length = customDataOffer.length;
      console.log(length);
      setCurrentIndex(length);
    }

    setModelCustom(!modelcustom);
  };
  const EditModelCustom = (item, index) => {
    console.log(item);
    console.log(index);
    setCurrentIndex(index);
    setCurrentForm(item);
    setFormValues((prevValues) => [...prevValues, item]);
    setModelCustom(!modelcustom);
  };
  const closeModalCustom = () => {
    setModelCustom(false);
    setCurrentForm({
      title: "",
      subTitle: "",
      description: "",
      info: "",
      badge: "",
      typeselect: "",
    });
  };
  const toggleCustomCategoryModel = () => {
    setCustomCategoryModelOpen(!customCategoryModelOpen);
  };
  const handleRemoveFormValue = (index) => {
    console.log(index);
    if (homeData?.type == "custom-venue") {
      const newArray = [...customDataVenue];
      newArray.splice(index, 1);
      setDataVenue(newArray);
    } else if (homeData?.type == "custom-offer") {
      const newArray = [...customDataOffer];
      newArray.splice(index, 1);
      setDataOffer(newArray);
    }
  };

  const CustomFormValue = (event) => {
    event.preventDefault();
    if (homeData?.type == "custom-venue") {
      const newArray = [...customDataVenue];
      newArray[currentIndex] = currentForm;
      setDataVenue(newArray);
    } else if (homeData?.type == "custom-offer") {
      const newArray = [...customDataOffer];
      newArray[currentIndex] = currentForm;
      console.log({ newArray });
      setDataOffer(newArray);
    }
    setModelCustom(!modelcustom);
    setCurrentForm({
      title: "",
      subTitle: "",
      description: "",
      info: "",
      badge: "",
      typeselect: "",
    });
    console.log(currentForm);
  };
  console.log("customDataOffer", customDataOffer);
  const [customdata, setData] = useState([]);
  const pushData = (newData) => {
    setData((prevData) => [...prevData, newData]);
  };
  useEffect(() => {
    console.log(customdata);
  }, [customdata]);
  const handleInputChanges = (event) => {
    const { name, value } = event.target;
    setCurrentForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const DragDropTable = () => {
    if (homeData.type === "custom_categories") {
      setDragDropData(customCategoryPreview);
    }
    setShowOrder(true);
  };

  const updateDragDropData = async (data) => {
    setDragDropData(data);
    if (homeData.type == "custom_categories") {
      setCustomCategoryPreview(data);
      let ids = data.map(item => item._id)
      console.log("custom_categories ids", ids)
      setCustomCategoryId(ids);
    }
    setShowOrder(false);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row d-flex flex-row">
        <div className="col-md-9">
          <div className="page-title-box">
            <h4>Homeblock Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Homeblock Management</li>:{" "}
              <li className="breadcrumb-item">Update Homeblock</li>
            </ol>
          </div>
        </div>

        <div className="col-md-3 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Home Block </h4>
                <Form onSubmit={updtHomeBlock}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        {/* <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Select Venue</label>
                            <div className="col-sm-6">
                               { venueText ? <a onClick={ () => toggleModel() } href="javascript:void(0)">{venueText}</a> : <a onClick={ () => toggleModel() } href="javascript:void(0)">Select Venue</a> }                                                     
                            </div>                           
                        </div>   */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title(English)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={homeData.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title(French)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={homeData.titleFrench}
                              onChange={(e) =>
                                updateValue("titleFrench", e.target.value)
                              }
                              name="titleFrench"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title(German)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              value={homeData.titleGerman}
                              onChange={(e) =>
                                updateValue("titleGerman", e.target.value)
                              }
                              name="titleGerman"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description (English)
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={homeData.description}
                              name="description"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description (French)
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("descriptionFrench", e.target.value)
                              }
                              value={homeData.descriptionFrench}
                              name="descriptionFrench"
                              className="form-control"
                              id="horizontal-password-input"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description (German)
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("descriptionGerman", e.target.value)
                              }
                              value={homeData.descriptionGerman}
                              name="descriptionGerman"
                              className="form-control"
                              id="horizontal-password-input"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Show Star
                          </label>

                          <div className="col-sm-6">
                            <select
                              value={homeData.isShowStar}
                              className="form-control"
                              onChange={(e) =>
                                updateValue("isShowStar", e.target.value)
                              }
                              name="mediaisShowStar"
                              class="form-select"
                            >
                              <option value="">Select</option>
                              <option value="true">Yes</option>
                              <option value="false">NO</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Size
                          </label>
                          <div className="col-sm-6">
                            <select

                              value={homeData.size}
                              className="form-control"

                              name="size"
                              class="form-select"
                              onChange={(e) =>
                                updateValue("size", e.target.value)
                              }
                              required="required"
                            >
                              <option> Select</option>
                              {sizeList.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {item.type + " - " + item.ratio}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>

                          <div className="col-sm-6">
                            <select
                              value={homeData.type}
                              className="form-control"
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              name="mediaType"
                              class="form-select"
                            >
                              {/* <option value="slider">Slider</option> */}
                              <option value="activities_and_events">
                                Activities & Events
                              </option>
                              <option value="inspired_by_elevate">
                                Inspired By Elevate
                              </option>
                              <option value="custom_components">
                                Custom Components
                              </option>
                              {/* <option value="my_itinerary">My Itinerary</option> */}
                              {/* <option value="transfer">Transfer</option> */}
                              <option value="banner">Banner</option>
                              {/* <option value="category">Category</option> */}
                              <option value="custom_categories">
                                Custom Category
                              </option>
                              <option value="hotel">Hotel</option>
                            </select>
                          </div>
                        </div>

                        {homeData?.type == "banner" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Auto Scroll
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="checkbox"
                                defaultChecked={homeData.isAutoScroll || false}
                                onChange={(e) =>
                                  updateValue("isAutoScroll", e.target.checked)
                                }
                              />
                            </div>
                          </div>
                        )}
                        {homeData?.type == "custom_components" && (
                          <div className="row mb-4 align-items-center">
                            {customPreview?.map((item, index) => (
                              <div className="col-lg-4 mb-3">
                                <div
                                  class="box-custom"
                                  className={
                                    item
                                      ? "box-custom bgcard fix-height"
                                      : "box-custom fix-height"
                                  }
                                  style={{
                                    border: "1px solid #ccc",
                                    textAlign: "start",
                                    backgroundImage: `url(${item?.cover})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                >
                                  <div className="bottom-footer">
                                    <div className="d-flex align-items-center mb-3">
                                      {/* <div className="logo">
                                    <img src={item?.logo||noImage} alt="logo" />
                                   </div> */}
                                      <div>
                                        <h3>
                                          {item?.label
                                            ? item?.label
                                            : "Select Venue"}
                                        </h3>
                                        {/* <p>
                                          {item?.address
                                            ? item?.address
                                            : "Address"}
                                        </p> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ textAlign: "start" }}>
                                  <button
                                    onClick={() =>
                                      handleRemoveCustomPreview(index)
                                    }
                                    className="btn-remove"
                                    type="button"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                            <div className="col-lg-4">
                              <button
                                type="button"
                                onClick={toggleModel4}
                                className="modal-custom-button"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        )}

                        {homeData?.type == "banner" && (
                          <div className="row mb-4 align-items-center">
                            {bannerPreview?.map((item, index) => (
                              <div className="col-lg-4 mb-3">
                                <div
                                  class="box-custom"
                                  className={
                                    item
                                      ? "box-custom bgcard fix-height"
                                      : "box-custom fix-height"
                                  }
                                  style={{
                                    border: "1px solid #ccc",
                                    textAlign: "start",
                                    backgroundImage: `url(${item?.cover})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                >
                                  <div className="bottom-footer">
                                    <div className="d-flex align-items-center mb-3">
                                      {/* <div className="logo">
                                   <img src={item?.logo||noImage} alt="logo" />
                                  </div> */}
                                      <div>
                                        <h3>
                                          {item?.label
                                            ? item?.label
                                            : "Select Venue"}
                                        </h3>
                                        <p>
                                          {item?.address
                                            ? item?.address
                                            : "Address"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ textAlign: "start" }}>
                                  <button
                                    onClick={() =>
                                      handleRemoveBannerPreview(index)
                                    }
                                    className="btn-remove"
                                    type="button"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}

                            <div className="col-lg-4">
                              <button
                                type="button"
                                onClick={toggleModel3}
                                className="modal-custom-button"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        )}

                        {homeData?.type == "inspired_by_elevate" && (
                          <div className="row mb-4 align-items-center">
                            {ticketPreview?.map((item, index) => (
                              <div className="col-lg-4 mb-3">
                                <div
                                  class="box-custom"
                                  className={
                                    item
                                      ? "box-custom bgcard fix-height"
                                      : "box-custom fix-height"
                                  }
                                  style={{
                                    border: "1px solid #ccc",
                                    textAlign: "start",
                                    backgroundImage: `url(${item?.cover})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                >
                                  <div className="bottom-footer">
                                    <div className="d-flex align-items-center mb-3">
                                      {/* <div className="logo">
                                   <img src={item?.logo||noImage} alt="logo" />
                                  </div> */}
                                      <div>
                                        <h3>
                                          {item?.label
                                            ? item?.label
                                            : "Select Venue"}
                                        </h3>
                                        <p>
                                          {item?.address
                                            ? item?.address
                                            : "Address"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ textAlign: "start" }}>
                                  <button
                                    onClick={() =>
                                      handleRemoveTicketPreview(index)
                                    }
                                    className="btn-remove"
                                    type="button"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}

                            <div className="col-lg-4">
                              <button
                                type="button"
                                onClick={toggleModel5}
                                className="modal-custom-button"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        )}

                        {homeData?.type == "activities_and_events" && (
                          <div className="row mb-4 align-items-center">
                            {ticketPreview?.map((item, index) => (
                              <div className="col-lg-4 mb-3">
                                <div
                                  class="box-custom"
                                  className={
                                    item
                                      ? "box-custom bgcard fix-height"
                                      : "box-custom fix-height"
                                  }
                                  style={{
                                    border: "1px solid #ccc",
                                    textAlign: "start",
                                    backgroundImage: `url(${item?.cover})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                >
                                  <div className="bottom-footer">
                                    <div className="d-flex align-items-center mb-3">
                                      {/* <div className="logo">
                                   <img src={item?.logo||noImage} alt="logo" />
                                  </div> */}
                                      <div>
                                        <h3>
                                          {item?.label
                                            ? item?.label
                                            : "Select Venue"}
                                        </h3>
                                        <p>
                                          {item?.address
                                            ? item?.address
                                            : "Address"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ textAlign: "start" }}>
                                  <button
                                    onClick={() =>
                                      handleRemoveTicketPreview(index)
                                    }
                                    className="btn-remove"
                                    type="button"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}

                            <div className="col-lg-4">
                              <button
                                type="button"
                                onClick={toggleModel5}
                                className="modal-custom-button"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        )}

                        {homeData?.type == "hotel" && (
                          <div className="row mb-4 align-items-center">
                            {venuPreview.map((item, index) => (
                              <div className="col-lg-4 mb-3">
                                <div
                                  class="box-custom"
                                  className={
                                    item
                                      ? "box-custom bgcard fix-height"
                                      : "box-custom fix-height"
                                  }
                                  style={{
                                    border: "1px solid #ccc",
                                    textAlign: "start",
                                    backgroundImage: `url(${item?.cover})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                >
                                  <div className="bottom-footer">
                                    <div className="d-flex align-items-center mb-3">
                                      <div>
                                        <h3>
                                          {item?.label
                                            ? item?.label
                                            : "Select Venue"}
                                        </h3>
                                        <p>
                                          {item?.address
                                            ? item?.address
                                            : "Address"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ textAlign: "start" }}>
                                  <button
                                    onClick={() =>
                                      handleRemovevenuPreview(index)
                                    }
                                    className="btn-remove"
                                    type="button"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}

                            <div className="col-lg-4">
                              <button
                                type="button"
                                onClick={toggleModel}
                                className="modal-custom-button"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        )}

                        {homeData?.type == "transfer" && (
                          <div className="row mb-4 align-items-center">
                            {transferPreview.map((item, index) => (
                              <div className="col-lg-4 mb-3">
                                <div
                                  class="box-custom"
                                  className={
                                    item
                                      ? "box-custom bgcard fix-height"
                                      : "box-custom fix-height"
                                  }
                                  style={{
                                    border: "1px solid #ccc",
                                    textAlign: "start",
                                    backgroundImage: `url(${item?.cover})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    background: !item?.cover
                                      ? "linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%)"
                                      : "none",
                                  }}
                                >
                                  <div className="bottom-footer">
                                    <div className="d-flex align-items-center mb-3">
                                      <div>
                                        <h3>
                                          {item?.label
                                            ? item?.label
                                            : "Select Transfer"}
                                        </h3>
                                        <p>
                                          {item?.address
                                            ? item?.address
                                            : "Address"}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div style={{ textAlign: "start" }}>
                                  <button
                                    onClick={() =>
                                      handleRemovetransferPreview(index)
                                    }
                                    className="btn-remove"
                                    type="button"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}

                            <div className="col-lg-4">
                              <button
                                type="button"
                                onClick={toggleModel6}
                                className="modal-custom-button"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>
                          </div>
                        )}

                        {homeData?.type == "category" && (
                          <div className="row mb-4 align-items-center">
                            {offerPreview.map((item, index) => (
                              <div className="col-lg-4 mb-3">
                                <div
                                  class="box-custom"
                                  className={
                                    item
                                      ? "box-custom bgcard fix-heights"
                                      : "box-custom fix-heights"
                                  }
                                  style={{
                                    border: "1px solid #ccc",
                                    textAlign: "start",
                                    backgroundImage: `url(${item?.cover})`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                >
                                  <div className="bottom-footer mt-0">
                                    <div className="d-flex align-items-center mb-3">
                                      {/* <div className="logo">
                                      <img src={item?.logo||noImage} alt="logo" />
                                      </div>
                                      <div>
                                      <h3>{item?.label ? item?.label : "Select Offer"}</h3>
                                      <p>{item?.address ? item?.address : "Address"}</p>
                                      </div> */}
                                    </div>
                                  </div>
                                  <div className="dic">
                                    <p className="mb-0">
                                      {item?.title ? item?.title : "Title"}
                                    </p>
                                    <h3 className="limit-text">
                                      {item?.description
                                        ? item?.description
                                        : "Description"}
                                    </h3>
                                  </div>
                                </div>
                                <div style={{ textAlign: "start" }}>
                                  <button
                                    onClick={() =>
                                      handleRemoOfferPreview(index)
                                    }
                                    className="btn-remove"
                                    type="button"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                            <div className="col-lg-4">
                              <button
                                type="button"
                                onClick={toggleModel2}
                                className="modal-custom-button"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                            </div>

                            {/* <label className="col-sm-3 col-form-label">
                              Select Offer
                            </label>
                            <div className="col-sm-6">
                              <MultiSelect
                                options={offerList}
                                onSelect={msUpdateOffer}
                                selectedValues={[]}
                                placeholder="Select Offer"
                              />
                            </div> */}
                          </div>
                        )}
                        {homeData?.type == "custom_categories" && (
                          <div className="row mb-4 align-items-center">
                            {customCategoryPreview.map((item, index) => (
                              <div
                                className="col-lg-4 mb-3"
                              // style={{
                              //   backgroundImage: `url(${item?.cover})`,
                              //   color: "red",
                              // }}
                              >
                                <div
                                  // class="box-custom"
                                  className={
                                    item
                                      ? "box-custom bgcard fix-heights"
                                      : "box-custom fix-heights"
                                  }
                                  style={{
                                    backgroundImage: `url("${item?.image || item?.cover}")`,
                                    border: "1px solid #ccc",
                                    textAlign: "start",
                                    // backgroundImage: `url("https://whosin-bucket.nyc3.cdn.digitaloceanspaces.com/images/1709722244170download_(4).jpg")`,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                  }}
                                >
                                  <div className="bottom-footer mt-0">
                                    <div className="d-flex align-items-center mb-3">
                                      {/* <div className="logo">
                                      <img src={item?.logo||noImage} alt="logo" />
                                      </div>
                                      <div>
                                      <h3>{item?.label ? item?.label : "Select Offer"}</h3>
                                      <p>{item?.address ? item?.address : "Address"}</p>
                                      </div> */}
                                    </div>
                                  </div>
                                  <div className="dic">
                                    <p className="mb-0">
                                      <h3 className="limit-text">
                                        {item?.title || item?.name || "Title"}
                                      </h3>
                                    </p>
                                    {/* <h3 className="limit-text">
                                      {item?.description || "Description"}
                                    </h3> */}
                                  </div>
                                </div>
                                <div style={{ textAlign: "start" }}>
                                  <button
                                    onClick={() =>
                                      handleRemoCustomCategoryPreview(index)
                                    }
                                    className="btn-remove"
                                    type="button"
                                  >
                                    Remove
                                  </button>
                                </div>
                              </div>
                            ))}
                            <div className="col-lg-4">
                              <button
                                type="button"
                                onClick={toggleCustomCategoryModel}
                                className="modal-custom-button"
                              >
                                <i class="fas fa-plus"></i>
                              </button>
                              <button
                                type="button"
                                onClick={DragDropTable}
                                className="btn btn-info"
                                style={{ marginLeft: "2rem" }}
                              >
                                <i className="fas fa-grip-vertical"></i>{" "}
                                Order
                              </button>
                            </div>

                            {/* <label className="col-sm-3 col-form-label">
                              Select Offer
                            </label>
                            <div className="col-sm-6">
                              <MultiSelect
                                options={offerList}
                                onSelect={msUpdateOffer}
                                selectedValues={[]}
                                placeholder="Select Offer"
                              />
                            </div> */}
                          </div>
                        )}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>

      {/* modal */}

      <HotelModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <CategoryModal
        show={model2}
        closeModal={closeModal2}
        selectRow={selectRow2}
      />
      <CustomCategoryModal
        show={customCategoryModelOpen}
        closeModal={toggleCustomCategoryModel}
        selectRow={customCategorySelect}
      />
      <BannerModal
        show={model3}
        closeModal={closeModal3}
        selectRow={selectRow3}
      />
      <ComponentModal
        show={model4}
        closeModal={closeModal4}
        selectRow={selectRow4}
      />
      {/* <TicketModal
        show={model5}
        closeModal={closeModal5}
        selectRow={selectRow5}
      /> */}
      <CustomTicketlistModal
        show={model5}
        closeModal={closeModal5}
        selectRow={selectRow5}
      />
      <TransferModal
        show={model6}
        closeModal={closeModal6}
        selectRow={selectRow6}
      />

      <Modal
        isOpen={modelcustom}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {homeData.type == "custom-venue" ? <>Custom Venue</> : null}
            {homeData.type == "custom-offer" ? <>Custom Offer</> : null}
          </h5>
          <button
            onClick={() => {
              closeModalCustom();
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Form onSubmit={CustomFormValue}>
            {Array.from({ length: 1 }).map((_, index) => (
              <div className="row relativerow justify-content-center">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12 mb-4">
                      <input
                        type="text"
                        name="title"
                        autoComplete="off"
                        placeholder="Title"
                        onChange={handleInputChanges}
                        className="form-control"
                        value={currentForm.title || ""}
                        required="required"
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <textarea
                        value={currentForm.subTitle || ""}
                        type="textarea"
                        autoComplete="off"
                        name="subTitle"
                        placeholder="Sub Title"
                        className="form-control"
                        required="required"
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="col-lg-12  mb-4">
                      <textarea
                        type="textarea"
                        autoComplete="off"
                        value={currentForm.description || ""}
                        name="description"
                        className="form-control"
                        id="horizontal-password-input"
                        required="required"
                        placeholder="Description"
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="col-lg-12  mb-4">
                      <textarea
                        type="textarea"
                        name="info"
                        value={currentForm.info || ""}
                        autoComplete="off"
                        placeholder="Info"
                        className="form-control"
                        required="required"
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <input
                        type="text"
                        name="badge"
                        value={currentForm.badge || ""}
                        className="form-control"
                        required="required"
                        autoComplete="off"
                        placeholder="Badge"
                        onChange={handleInputChanges}
                      />
                    </div>
                    <div className="col-sm-12 ">
                      {homeData.type == "custom-venue" ? (
                        <>
                          <Select
                            name={`${idName}`}
                            options={venueoptions}
                            placeholder="Select Venue"
                            onChange={(e) => handleCoverChange(e, index)}
                            defaultValue={currentForm?.forSelect}
                          />
                        </>
                      ) : null}

                      {homeData.type == "custom-offer" ? (
                        <>
                          <Select
                            name={`${idName}`}
                            options={offeroptions}
                            placeholder="Select Offer"
                            onChange={(e) => handleCoverChange(e, index)}
                            defaultValue={currentForm?.forSelect}
                          />
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div
                    className={currentForm ? "box-custom bgcard" : "box-custom"}
                    style={{
                      border: "1px solid #ccc",
                      backgroundImage: `url(${currentForm?.cover})`,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <div class="badge">
                      <input
                        type="text"
                        value={currentForm.badge}
                        autoComplete="off"
                        readOnly
                      />
                    </div>
                    <h5 className="title">
                      {currentForm.title ? currentForm.title : "Title"}
                    </h5>
                    <h4 className="subtitle">
                      {currentForm.subTitle
                        ? currentForm.subTitle
                        : "Sub Title"}
                    </h4>

                    <div className="bottom-footer">
                      <div className="mb-3">
                        <h6 className="fnt-18">
                          {currentForm.description
                            ? currentForm.description
                            : "Description"}
                        </h6>
                        <p>{currentForm.info ? currentForm.info : "Info"}</p>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <div className="logo">
                          <img src={currentForm?.logo || noImage} alt="logo" />
                        </div>
                        <div>
                          <h3>
                            {currentForm?.label ||
                              currentForm?.forSelect?.label ||
                              "Select"}
                          </h3>
                          <p>
                            {currentForm?.address
                              ? currentForm?.address
                              : "Address"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <button type="submit" className="btn-primary btn btn-saves">
                    Save
                  </button>
                </div>
              </div>
            ))}
          </Form>
        </div>
      </Modal>
      <DragDropModal
        show={showOrder}
        data={dragDropData}
        setData={updateDragDropData}
        closeModal={() => setShowOrder(false)}
      />
    </React.Fragment>
  );
}
