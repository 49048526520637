import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation  } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, FormGroup, Form, Table, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  putRequestForm } from "../../components/Common/Utils.js";

export default function UpdateDiscount() {

  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;
  
  
  
  const [discounttypeData, setDiscounttypeData] = useState();
  const [previewImage, setPreviewImage] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(false);  

 

  const { id } = useParams();

  useEffect(() => {
    setDiscounttypeData(state);
    setImageIcon( state?.image ? state?.image : "" )
  }, [state])

  useEffect(() => {
    if(!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result)
      setImageIcon("");
    }
    reader.readAsDataURL(selectedImage)
  }, [selectedImage])
  

  const Updatediscounttype = async (e) => {
    e.preventDefault();

    if (!discounttypeData.title) {
      toast.error("Please enter title");
      return;
    }
   setLoading(true);
  
    const formData = new FormData();
    formData.append("discountTypeId", id);
    formData.append("title", discounttypeData.title);   
    formData.append("status", discounttypeData.status == 'true' ? 1 : 0);
    

    
    if(selectedImage){
      formData.append("image", selectedImage);
    } 

    const response = await putRequestForm("discount/type/update", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/discountType");
      }, 1000);
      
    } else {
      toast.error(response.message);
    }  
    
  };

  const updateValue = (index, value) => {
    const list = {...discounttypeData}
    list[index] = value
    setDiscounttypeData(list)
  } 
    
 
    

  return (
    <React.Fragment>

      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Discount Type Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Discount Type Management</li>:{" "}
              <li className="breadcrumb-item">
                Update Discount Type
              </li>
            </ol>
          </div>
        </div>
      </div>
     
      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>

          { loading ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :

            <CardBody>
              <h4 className="card-title"> Update Discount Type</h4>
              <Form onSubmit={Updatediscounttype}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Title</label>
                            <div className="col-sm-9">
                            <input type="text" onChange={ (e) => updateValue('title', e.target.value) } value={discounttypeData?.title} name='title'  className="form-control"  />
                            </div>
                        </div>   
                        
                        <div className="row mb-4">
                            <label htmlFor="horizontal-password-input" className="col-sm-3 col-form-label">Image</label>
                            <div className="col-sm-9">
                            <input type="file" onChange={(e) => setSelectedImage(e.target.files[0])}  name='image' className="form-control" id="horizontal-password-input" />
                           <br/>
                            {previewImage && (                            
                                  <img src={previewImage} style={{width: "100px", height: "100px"}}/>                              
                              )}

                              {imageIcon && (
                                <img src={imageIcon} style={{width: "100px", height: "100px"}}/>
                              )}
                            </div>                           
                        </div>   
                        
                       
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Status</label>
                            <div className="col-sm-9">
                              <select  value={discounttypeData?.status} onChange={ (e) => updateValue('status', e.target.value) }  class="form-select">
                                   <option>  Select</option>
                                   <option value="true">True</option>
                                   <option value="false">False</option>
                                 </select>
                            </div>                           
                        </div>              
                       
                        <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                            <div className="col-sm-9">
                            <div>
                                <button type="submit"  className="btn btn-primary w-md">Submit</button>
                            </div>
                            </div>
                            </div>
                        </div>

                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody> }
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
