import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { putRequestForm } from "../../components/Common/Utils.js";

export default function UpdatePackage() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  const [packageData, setPackageData] = useState();
  const [previewImage, setPreviewImage] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    setPackageData(state);
    
  }, [state]);

  const Updatepackage = async (e) => {
    e.preventDefault();
    if (!packageData.title) {
      toast.error("Please enter title");
      return;
    }

    if (!packageData.subTitle) {
      toast.error("Please enter subtitle");
      return;
    }

    if (!packageData.discountText) {
      toast.error("Please enter discount text");
      return;
    }

    if (!packageData.time) {
      toast.error("Please enter time");
      return;
    }

    if (!packageData.actualPrice) {
      toast.error("Please enter actual price");
      return;
    }

    if (!packageData.discountedPrice) {
      toast.error("Please enter discount price");
      return;
    }

    if (!packageData.feature) {
      toast.error("Please enter feature");
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append("packageId", id);
    formData.append("title", packageData.title);
    formData.append("subTitle", packageData.subTitle);
    formData.append("discountText", packageData.discountText);
    formData.append("time", packageData.time);
    formData.append("actualPrice", packageData.actualPrice);
    formData.append("discountedPrice", packageData.discountedPrice);
    formData.append("feature", packageData.feature);
    formData.append("status", packageData.status == "true" ? 1 : 0);

    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    const response = await putRequestForm(
      "membership-package/update",
      formData
    );
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/package");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...packageData };
    list[index] = value;
    setPackageData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Package Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Package Management</li>:{" "}
              <li className="breadcrumb-item">Update Package</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Package </h4>
                <Form onSubmit={Updatepackage}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={packageData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Sub Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("subTitle", e.target.value)
                              }
                              value={packageData?.subTitle}
                              name="subTitle"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Text
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("discountText", e.target.value)
                              }
                              name="subTitle"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Time
                          </label>
                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("time", e.target.value)
                              }
                              value={packageData?.time}
                              name="time"
                              class="form-select"
                            >
                              <option value="monthly">Monthly</option>
                              <option value="annually">Annually</option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Actual Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("actualPrice", e.target.value)
                              }
                              value={packageData?.actualPrice}
                              name="actualPrice"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount Price
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("discountedPrice", e.target.value)
                              }
                              value={packageData?.discountedPrice}
                              name="discountPrice"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Feature
                          </label>
                          <div className="col-sm-9">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("feature", e.target.value)
                              }
                              value={packageData?.feature}
                              name="feature"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Status
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={packageData?.status}
                              onChange={(e) =>
                                updateValue("status", e.target.value)
                              }
                              class="form-select"
                            >
                              <option> Select</option>
                              <option value="true">True</option>
                              <option value="false">False</option>
                            </select>
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
