import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest,putRequestForm,putRequestJson,putRequest } from "../../components/Common/Utils.js";
import CustomLoder from "./CustomLoder.js";
import FileUpload from "../../components/Common/FileUpload.js";

export default function UpdateTicketCategory() {
  const { _id } = useParams();
  const location = useLocation();
  const  state  = location?.state?.row;
  console.log({ state });

  const [bannerData, setBannerData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  const [ShowLoder, setShowLoder] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const [url, seturl] = useState("");
 
  let history = useHistory();

  // useEffect(() => {
  //   if (!selectedImage) return;
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setPreviewImage(reader.result);
  //   };
  //   reader.readAsDataURL(selectedImage);
  // }, [selectedImage]);

  const Createbannerform = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    setShowLoder(true);
    console.log({url})
    // formData.append("name", bannerData.name);

    // formData.append("image", url);
    let payload ={
      categoryId:_id,
      "name": bannerData.name,
      "image": url,
    }

    const response = await putRequest("ticket/category/update", payload);
    console.log({ response });
    if (response.status == 1) {
      toast.success(response.message);
      setShowLoder(false);
      setTimeout(() => {
        history.push("/CategoryList");
      }, 1000);
    } else {
      toast.error(response.message);
      setShowLoder(false);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  const Imageupload = async (e) => {
    const formData = new FormData();
    setFileUploadLoader(true)
    formData.append("file", e.target.files[0]);
    // formData.append("file", selectedImage);
    const response = await postRequestForm("homepage/upload/image", formData);
    let image = response.data.url;
    setFileUploadLoader(false)
    console.log({ image });
    seturl(image);
  };

  // useEffect(() => {
  //   Imageupload();
  // }, [selectedImage]);

  useEffect(() => {
    if (state) {
      console.log(state)
      setBannerData(state);
      setPreviewImage(state?.image);
      seturl(state?.image);
    }
  }, [state]);

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Ticket Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Ticket Management</li>
              <li className="breadcrumb-item">Update Category </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title"> Update Category</h4>
              {ShowLoder ? (
                <CustomLoder/>
              ) : (
              <Form onSubmit={Createbannerform}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Title</label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("name", e.target.value)
                            }
                            value={bannerData?.name}
                            name="name"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          File
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="file"
                            onChange={(e) =>
                              Imageupload(e)
                            }
                            name="mediaUrl"
                            className="form-control"
                            id="horizontal-password-input"
                            // required="required"
                          />
                          <br />
                          {bannerData?.mediaType == "video"
                            ? null
                            : url && (
                                <img
                                  src={url}
                                  style={{ width: "100px", height: "100px" }}
                                />
                              )}
                        </div>
                      </div>

                      <div className="mt-5 mt-lg-4">
                        <div className="row justify-content-end">
                          <div className="col-sm-9">
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>  )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <FileUpload message="File Uploading" status={fileUploadLoader} />

    </React.Fragment>
  );
}
