import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect } from "react-router-dom"

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {

      let authType = localStorage.getItem("authType");
      let orgAuthType = authType;
      let authRoles = localStorage.getItem("adminRole");

      try {
        if(authRoles){
          authRoles = JSON.parse(authRoles);
        }
      } catch (error) {
          authRoles = [];
      }

      // extract all pages from authRoles where allow = true

      let allowedPages = [];

      authRoles?.forEach((role) => {
        if(role.allow){
          let tempAllowedPages = role?.pageAccess?.filter((page) => page.allow);
          allowedPages = [...allowedPages, ...tempAllowedPages];
        }
      }); 
      let currentPage = props.location.pathname;
      // remove / character from beginning of currentPage
      currentPage = currentPage.replace(/^\//, "");

      let pageIndex = "";
     

      // check if currentPage is in allowedPages then set authType to admin

      let foundPage = allowedPages?.find((page) => currentPage?.includes(page.page));

      if (foundPage) {   
          pageIndex = foundPage.page;
          authType = "admin";
      }

      const editPageSchema = [
        {
          page : "users",
          editPage: "updateuserdata"
        },
        {
          page : "Component",
          editPage: "updateComponent"
        },
        {
          page : "Category",
          editPage: "Updatecategorydata"
        },
        {
          page : "SubAdminlist",
          editPage: "UpdateSubAdmin"
        },
        {
          page : "notification",
          editPage: "Updatenotificationdata"
        }
      ]     


      if(orgAuthType === "admin"){
        props.deletePage = true;
        props.exportPage = true;
        props.editPage = true;
      }else{

        let deletePage = `${pageIndex}?delete`;
        let exportPage = `${pageIndex}?export`;
        let editPage = editPageSchema.find((page) => page.page === pageIndex);
        if(editPage){
          editPage = editPage.editPage;
        }
        
        deletePage = allowedPages.find((page) => page.page.includes(deletePage)) ? true : false;
        exportPage = allowedPages.find((page) => page.page.includes(exportPage)) ? true : false;
        editPage = editPage ? true : false;  

        props.deletePage = deletePage;
        props.exportPage = exportPage;
        props.editPage = editPage; 
      }
      

      if (isAuthProtected && authType !== "admin") {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any,
}

export default Authmiddleware
