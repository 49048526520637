import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequest, postRequestForm } from "../../components/Common/Utils.js";
import HotelModal from "../../components/Modal/HotelModal.js";
import TicketModal from "components/Modal/TicketModal.js";
import TransferModal from "components/Modal/TransferModal.js";
import DealModal from "components/Modal/DealModal.js";
import CustomTicketModal from "components/Modal/CustomTicketModal.js";
import CustomTicketlistModal from "components/Modal/CustomTicketlistModal.js";
import FileUpload from "components/Modal/FileUpload.js";

export default function CreateComponets() {
  const history = useHistory();

  const location = useLocation();
  const state = location.state?.row;

  const [componentData, setComponentData] = useState({
    qtype: "color",
    type: "link",
    backgroundColor: "#000000"
  });
  const [previewCover, setPreviewCover] = useState("");
  const [previewLogo, setPreviewLogo] = useState("");
  const [loading, setLoading] = useState(false);
  const [fileUploadLoader, setFileUploadLoader] = useState(false);

  const [selectedLogo, setSelectedLogo] = useState("");
  const [selectedCover, setSelectedCover] = useState("");
  const [selectedLogourl, setSelectedLogourl] = useState("");
  const [selectedCoverurl, setSelectedCoverurl] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [model, setModel] = useState(false);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState("");
  const [offerModel, setOfferModel] = useState(false);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [activityModel, setActivityModel] = useState(false);
  const [eventId, setEventId] = useState("");
  const [eventText, setEventText] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [eventModel, setEventModel] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  useEffect(() => {
    if (!selectedLogo) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewLogo(reader.result);
    };
    reader.readAsDataURL(selectedLogo);
  }, [selectedLogo]);

  useEffect(() => {
    if (!selectedCover) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewCover(reader.result);
    };
    reader.readAsDataURL(selectedCover);
  }, [selectedCover]);

  const CreateComponetsform = async (e) => {
    e.preventDefault();

    componentData.contentType = componentData.type;
    componentData.type = componentData.qtype;
    if (!homeData.title) {
      toast.error("Please enter title");
      return;
    }
    if (!homeData.titleFrench) {
      toast.error("Please enter french title ");
      return;
    }
    if (!homeData.titleGerman) {
      toast.error("Please enter german title ");
      return;
    }
    if (!homeData.buttonText) {
      toast.error("Please enter button Text");
      return;
    }
    if (!homeData.buttonTextFrench) {
      toast.error("Please enter french button Text ");
      return;
    }
    if (!homeData.buttonTextGerman) {
      toast.error("Please enter german button Text ");
      return;
    }
    if (componentData.type == "image") {
      if (!selectedCoverurl) {
        toast.error("Please select background image");
        return;
      }
      componentData.backgroundColor = "";
      componentData.image = "";
      componentData.backgroundImage = selectedCoverurl;
    }

    if (componentData.type == "color") {
      if (!componentData.backgroundColor) {
        toast.error("Please select background color");
        return;
      }
      componentData.image = selectedLogourl;
      componentData.backgroundImage = "";
      componentData.backgroundColor = componentData.backgroundColor;
    }

    //console.log("componentData", componentData);
    //return;

    const response = await postRequest("homepage/component", componentData);
    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/Component");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  const Imageupload = async (e) => {
    setFileUploadLoader(true)
    const formData = new FormData();
    formData.append("file", selectedLogo);
    const response = await postRequestForm("homepage/upload/image", formData);
    let image = response.data.url;
    console.log("image", image);
    setSelectedLogourl(image);
    setFileUploadLoader(false)
  };

  const Imageuploadcover = async (e) => {
    setFileUploadLoader(true)
    const formData = new FormData();
    formData.append("file", selectedCover);
    const response = await postRequestForm("homepage/upload/image", formData);
    let image = response.data.url;
    console.log("bg", { image });
    setSelectedCoverurl(image);
    setFileUploadLoader(false)
  };

  useEffect(() => {
    Imageupload();
  }, [selectedLogo]);

  useEffect(() => {
    Imageuploadcover();
  }, [selectedCover]);

  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };

  const updateValue = (index, value) => {
    const list = { ...componentData };
    list[index] = value;
    setComponentData(list);
  };

  const closeModal = () => {
    setModel(false);
  };
  const toggleOfferModel = () => {
    console.log("toggleOfferModel", offerModel);
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const toggleActivityModel = () => {
    console.log("toggleActivityModel", activityModel);
    setActivityModel(!activityModel);
  };
  const closeActivityModal = () => {
    setActivityModel(false);
  };

  const toggleEventModel = () => {
    console.log("toggleEventModel", eventModel);
    setEventModel(!eventModel);
  };
  const closeEventModal = () => {
    setEventModel(false);
  };

  const selectRow = (id) => {
    const componentD = { ...componentData };
    componentD.item = id?._id;
    setComponentData(componentD);

    setVenueImage(id?.coverImage);
    setVenueText(id?.name);
    setSelectedId(id?._id);
    closeModal();
  };

  const selectOfferRow = (offer) => {
    const componentD = { ...componentData };
    componentD.item = offer?._id;
    setComponentData(componentD);

    setOfferText(offer?.title);
    setOfferImage(offer?.image);

    closeOfferModal();
  };

  const selectActivityRow = (id) => {
    const componentD = { ...componentData };
    componentD.item = id?._id;
    setComponentData(componentD);
    setActivityText(id?.title);
    setSelectedId(id?._id);
    closeActivityModal();
  };

  const selectEventRow = (id) => {
    const componentD = { ...componentData };
    componentD.item = id?._id;
    setComponentData(componentD);

    setEventText(id?.name);
    setEventImage(id?.coverImage);
    setSelectedId(id?._id);
    closeEventModal();
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Component Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Component Management</li>:{" "}
              <li className="breadcrumb-item">Create Component</li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Create Component </h4>
                <Form onSubmit={CreateComponetsform}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title (English)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="title"
                              className="form-control"
                              value={componentData?.title}
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title (French)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="titleFrench"
                              className="form-control"
                              value={componentData?.titleFrench}
                              onChange={(e) =>
                                updateValue("titleFrench", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title (German)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="titleGerman"
                              className="form-control"
                              value={componentData?.titleGerman}
                              onChange={(e) =>
                                updateValue("titleGerman", e.target.value)
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-9">
                            <select
                              name="type"
                              className="form-control"
                              value={componentData?.qtype}
                              onChange={(e) =>
                                updateValue("qtype", e.target.value)
                              }
                            >
                              <option value="color" selected>
                                Color
                              </option>
                              <option value="image">Image</option>
                            </select>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Button Text (English)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="buttonText"
                              className="form-control"
                              value={componentData?.buttonText}
                              onChange={(e) =>
                                updateValue("buttonText", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Button Text (French)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="buttonTextFrench"
                              className="form-control"
                              value={componentData?.buttonTextFrench}
                              onChange={(e) =>
                                updateValue("buttonTextFrench", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Button Text (German)
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              name="buttonTextGerman"
                              className="form-control"
                              value={componentData?.buttonTextGerman}
                              onChange={(e) =>
                                updateValue("buttonTextGerman", e.target.value)
                              }
                            />
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Link
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="url"
                              name="url"
                              className="form-control"
                            />
                          </div>
                        </div> */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Content Type
                          </label>
                          <div className="col-sm-9">
                            <select
                              value={componentData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              className="form-select"
                            >
                              <option value="">Select Type</option>
                              <option value="link">Link</option>
                              <option value="ticket">Ticket</option>
                              {/* <option value="hotel">Hotel</option>
                              <option value="transfer">Transfer</option> */}
                              <option value="deal">Deal</option>
                            </select>
                          </div>
                        </div>

                        {componentData?.type === "link" && (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-firstname-input"
                              className="col-sm-3 col-form-label"
                            >
                              Link
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                onChange={(e) =>
                                  updateValue("item", e.target.value)
                                }
                                defaultValue={componentData?.item}
                                name="link"
                                className="form-control"
                              />
                            </div>
                          </div>
                        )}
                        {componentData?.type == "ticket" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Ticket
                            </label>
                            <div
                              className="col-sm-9"
                              onClick={() => toggleActivityModel()}
                            >
                              {activityText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleActivityModel()}
                                  >
                                    Choose Ticket
                                  </button>
                                  {/* <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div> */}
                                  {/* <img
                                    src={activityImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img> */}
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={activityText}
                                    id="selectedVenue"
                                    placeholder="No Ticket chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleActivityModel()}
                                  >
                                    Choose Ticket
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No Ticket chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {componentData?.type == "hotel" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Hotel
                            </label>

                            <div
                              className="col-sm-9"
                              onClick={() => toggleModel()}
                            >
                              {venueText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Hotel
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={venueImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedOffer"
                                    placeholder="No Hotel chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleModel()}
                                  >
                                    Choose Hotel
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={venueText}
                                    id="selectedOffer"
                                    placeholder="No Hotel chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {componentData?.type == "transfer" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Transfer
                            </label>

                            <div
                              className="col-sm-9"
                              onClick={() => toggleEventModel()}
                            >
                              {eventText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",

                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleEventModel()}
                                  >
                                    Choose Transfer
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={eventImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={eventText}
                                    id="selectedOffer"
                                    placeholder="No Transfer chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",

                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleEventModel()}
                                  >
                                    Choose Transfer
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={eventText}
                                    id="selectedOffer"
                                    placeholder="No Transfer chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                        {componentData?.type == "deal" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Deal
                            </label>

                            <div
                              className="col-sm-9"
                              onClick={() => toggleOfferModel()}
                            >
                              {offerText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",

                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Deal
                                  </button>
                                  <div
                                    style={{
                                      border: "1px solid #ced4da",
                                      width: "16px",
                                    }}
                                  ></div>
                                  <img
                                    src={offerImage}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      border: "1px solid #ced4da",
                                      borderRight: "none",
                                      borderLeft: "none",
                                    }}
                                  ></img>
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No Transfer chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",

                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseOfferBtn"
                                    onclick={() => toggleOfferModel()}
                                  >
                                    Choose Deal
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    value={offerText}
                                    id="selectedOffer"
                                    placeholder="No Deal chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        {componentData?.qtype == "color" ? (
                          <>
                            <div className="row mb-4">
                              <label
                                htmlFor="horizontal-password-input"
                                className="col-sm-3 col-form-label"
                              >
                                Logo
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="file"
                                  onChange={(e) =>
                                    setSelectedLogo(e.target.files[0])
                                  }
                                  name="image"
                                  className="form-control"
                                  id="horizontal-password-input"
                                  defaultValue={selectedLogourl}
                                />
                                <br />
                                {previewLogo && (
                                  <img
                                    src={previewLogo}
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Background Color
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="color"
                                  name="backgroundColor"
                                  className="form-control"
                                  onChange={(e) =>
                                    updateValue(
                                      "backgroundColor",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                            </div>{" "}
                          </>
                        ) : (
                          <div className="row mb-4">
                            <label
                              htmlFor="horizontal-password-input"
                              className="col-sm-3 col-form-label"
                            >
                              Background Image
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="file"
                                onChange={(e) =>
                                  setSelectedCover(e.target.files[0])
                                }
                                name="backgroundImage"
                                className="form-control"
                                id="horizontal-password-input"
                                defaultValue={selectedCoverurl}
                              />
                              <br />
                              {previewCover && (
                                <img
                                  src={previewCover}
                                  style={{ width: "100px", height: "100px" }}
                                />
                              )}
                            </div>
                          </div>
                        )}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <HotelModal show={model} closeModal={closeModal} selectRow={selectRow} />

      {/* <TicketModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
      /> */}

      {/* CustomTicketlistModal */}

      <CustomTicketlistModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
      />
      <TransferModal
        show={eventModel}
        closeModal={closeEventModal}
        selectRow={selectEventRow}
      />
      <DealModal
        show={offerModel}
        closeModal={closeOfferModal}
        selectRow={selectOfferRow}
      />
      <FileUpload message="File Uploading" status={fileUploadLoader} />

    </React.Fragment>
  );
}
