import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequest, putRequest } from "../../components/Common/Utils.js";
import { Select } from "@material-ui/core";
import VenueModal from "../../components/Modal/HotelModal.js";

export default function UpdateSpecialOffer() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;

  const [specialOfferData, setSpecialOfferData] = useState();
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueList, setVenueList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState([]);
  const [listFetched, setListFetched] = useState(false);
  const [model, setModel] = useState(false);
  const [model2, setModel2] = useState(false);

  const [venueOptions, setVenueOptions] = useState([
    { value: "", label: "None" },
  ]);

  const [loading, setLoading] = useState(false);

  const [brunches, setBrunches] = useState([]);
  const [discountList, setDiscountList] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    setSpecialOfferData(state);

    if(state?.discount?._id){
      state.discount = state.discount._id;
    }


    if (state?.brunch) {     

      setBrunches(state?.brunch);
    }
  }, [state]);

  useEffect(() => {
    fetchList();
    fetchDiscount();
    setVenueId(state?.venue?._id);
    setVenueText(state?.venue?.name);
  }, [state]);

  const fetchDiscount = async () => {
    setLoading(true);
    const response = await postRequest("discount/list", { limit: 10000000 });
    if (response.status == 1) {
      setDiscountList(response.data.list);
    }
    setLoading(false);
  };

  const deleteBrunch = (index) => {
    const tempPack = [...brunches];
    tempPack.splice(index, 1);
    setBrunches(tempPack);
  };

 
  const fetchList = async () => {
    setLoading(true);
    const response = await postRequest("venue/list", { limit: 10000000 });
    if (response.status == 1) {
      const list = response.data.list.map((item) => {
        return {
          id: item._id,
          name: item.name,
        };
      });
      setVenueList(list);

    

      const defaultItems = list.filter((item) => {
        return selectedVenue.includes(item.id);
      });

      console.log("defaultItems", defaultItems);
      setListFetched(true);
    }
    setLoading(false);
  };

  const closeModal2 = () => {
    setModel2(false);
  };


  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };

  const toggleModel2 = () => {
    console.log("toggleModel", model2);
    setModel2(!model2);
  };

 

  const selectRow = (id, name) => {
    setVenueId(id);
    setVenueText(name);
    closeModal2();
  };

  const UpdatespecialOffer = async (e) => {
    e.preventDefault();
    setLoading(true);


    const payload = {
      specialOfferId: state._id,
      title: specialOfferData.title,
      venueId: venueId,
      claimCode: specialOfferData?.claimCode ? specialOfferData?.claimCode : '' ,
      description: specialOfferData.description,
      type: specialOfferData.type,
      discount : specialOfferData.discount ? specialOfferData.discount : "" ,
    };

    

    if(specialOfferData.type === "brunch"){    
      const newbranches = brunches.map((item) => {
        return {
          item: item.item,
          amount: item.amount,
          itemDiscount: item.itemDiscount 
        };
      }); 
      payload.brunch = newbranches ;
    }   

    const response = await putRequest("offer/special/update", payload);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/specialoffer");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...specialOfferData };
    list[index] = value;
    setSpecialOfferData(list);
  };

  const fetchIndex = (items, search) => {
    for (var i = 0; i < items.length; i++) {
      if (items[i].value === search) {
        return i;
      }
    }
    return -1;
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Special Offer Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">
                Special Offer Management
              </li>
              : <li className="breadcrumb-item">Update Special Offer</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Special Offer </h4>
                <Form onSubmit={UpdatespecialOffer}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={specialOfferData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)     
                              }
                              value={specialOfferData?.description}      
                              name="description"
                              className="form-control"
                            />
                          </div>
                        </div>

                       
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select Venue
                          </label>

                          <div className="col-sm-6"  onClick={() => toggleModel2()}>
                            {venueText ? (
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    {venueText}
                                  </a>
                                </div>
                                <div>
                                 
                                </div>
                              </div>
                            ) : (
                              <div
                                style={{
                                  display: "flex",
                                  border: "1px solid #ced4da",
                                  padding: " 0.375rem 1.75rem 0.375rem 0.75rem",
                                  borderRadius: "0.25rem",
                                  lineHeight: 1.5,
                                }}
                              >
                                <div style={{ minWidth: "100%" }}>
                                  <a
                                    href="javascript:void(0)"
                                    style={{
                                      textDecoration: "none",
                                      color: "#5b626b",
                                      fontSize: "0.8125rem",
                                      fontWeight: 400,
                                    }}
                                  >
                                    Select Venue
                                  </a>
                                </div>
                                <div>
                                  <a
                                    onClick={() => toggleModel()}
                                    href="javascript:void(0)"
                                  >
                                    <i
                                      style={{ color: "#5b626b" }}
                                      class="fa fa-plus"
                                    ></i>
                                  </a>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>    
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Type
                          </label>
                          <div className="col-sm-6">
                            <select
                              defaultValue={specialOfferData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              className="form-select"
                            >
                              <option> Select</option>
                              <option value="total">Total</option>
                              <option value="brunch">Brunch</option>
                            </select>
                          </div>
                        </div>
                        {specialOfferData?.type === "brunch" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Brunch
                            </label>
                            <div className="col-sm-6">
                              <a
                                onClick={() => toggleModal()}
                                href="javascript:void(0)"
                              >
                                Add Brunches
                              </a>
                              <br />
                              <br />
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Item</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Discount</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {brunches?.map((item, index) => (
                                    <tr key={index}>
                                      <th scope="row">{index + 1}</th>
                                      <td>{item.item}</td>
                                      <td>{item.amount}</td>
                                      <td>{item.discountText}</td>
                                      <td>
                                        <a
                                          onClick={() => deleteBrunch(index)}
                                          href="javascript:void(0)"
                                        >
                                          Delete
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        )}

                        {specialOfferData?.type === "total" && (<div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Discount
                          </label>
                          <div className="col-sm-6">
                            <select
                                value={specialOfferData?.discount}
                                onChange={(e) =>
                                  updateValue("discount", e.target.value)
                                }
                                class="form-select"
                              >
                                <option> Select</option>
                                {discountList.map((item, index) => (
                                  <option value={item._id}>{item.title}</option>
                                ))}
                              </select>
                          </div>
                        </div>)}

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
      <VenueModal show={model2} closeModal={closeModal2} selectRow={selectRow} />
    </React.Fragment>
  );
}
