import { Modal } from "reactstrap";
import { useEffect, useRef, useState } from "react";
import DraggableList from "react-draggable-list";

const Item = ({ item, dragHandleProps, index }) => {
  const { onMouseDown, onTouchStart } = dragHandleProps;
  return (
    <>
      <div
        className="disable-select"
        style={{
          border: "1px solid #e4dfdf",
          borderRadius: "5px",
          margin: "4px",
          padding: "7px",
          display: "flex",
          justifyContent: "space-around",
          background: "white",
          alignItems: "center",
          userSelect: "none",
        }}
        onTouchStart={(e) => {
          e.preventDefault();
          console.log("touchStart");
          // Change background color when touched
          e.target.style.backgroundColor = "blue";
          onTouchStart(e);
        }}
        onMouseDown={(e) => {
          console.log("mouseDown");
          onMouseDown(e);
        }}
        onTouchEnd={(e) => {
          // Reset background color when touch ends
          e.target.style.backgroundColor = "black";
        }}
        onMouseUp={() => {
          // Reset background color when mouse up
        }}
      >
        <span
          class="fas fa-grip-vertical"
          style={{ fontSize: "1.5rem" }}
        ></span>
        <div style={{ width: "60%" }}>
          {item.title || item.name || item.label}
        </div>
        {item.type || item.categoryName}
        {item.image || item.logo || item.cover == "" ? (
          <img
            src={item.image || item.logo || item.cover}
            style={{ width: "50px", height: "50px" }}
          />
        ) : (
          <video width="100">
            <source src={item.video} type="video/mp4" />
          </video>
        )}
      </div>
    </>
  );
};

const DragDropItems = ({ data, show, closeModal, setData }) => {
  // State for the list
  const [list, setList] = useState(
    data.map((item, index) => ({ ...item, index: index }))
  );

  // Ref for the container
  const containerRef = useRef();

  // Handle list change
  const handleListChange = (newList) => {
    setList(newList);
  };

  return (
    <div
      className=""
      style={{ maxHeight: "600px", overflowY: "auto" }}
      ref={containerRef}
    >
      <DraggableList
        itemKey="index"
        template={Item}
        list={list}
        onMoveEnd={handleListChange}
        container={() => containerRef.current}
      />
      <div style={{ textAlign: "center", margin: "20px" }}>
        {" "}
        {/* Add a div to center the button */}
        <button
          onClick={() => setData(list)} /* Set onClick to call setData(items) */
          className="btn btn-primary"
        >
          Save Order
        </button>
      </div>
    </div>
  );
};

export default DragDropItems;
