import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Spinner,Button } from "reactstrap";
// import rButton from "reactstrap/button";

import {
  deleteRequest,
  putRequest,
  postRequest,
  RBButton  
} from "components/Common/Utils";
import { useParams } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { format } from "date-fns";
// import StarComponent from "./StarComponent";

import Lightbox from "react-image-lightbox";

import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Avatar,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

import LocationPicker from "react-location-picker";
// import DayTimePicker from "./DayTimePicker";

import { makeStyles } from "@material-ui/core/styles";
import { ToastContainer, toast } from "react-toastify";

import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
// import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import "./CustomCard.css";
import RoomListTable from "./RoomTable";
import HotelRatingTable from "./HotelRatingTable";
import HotelBookingTable from "./HotelBookingTable";

import FeatureAdd from "../../components/Modal/FeatureModal";

import FileDrop from "./FileDrop";
// import VenueSpecialOfferListTable from "./VenueSpecialOfferList";
// import VenueClaimsListTable from "./VenueClaimsTable";
// import VenueNotificationListTable from "./VenueNotificationTable";
// import VenueReviewListTable from "./VenueReviewTable";
// import VenueFollowListTable from "./VenueFollowingTable";
// import VenueCheckinTable from "./VenueCheckinTable";
// import VenueActiveStoryListTable from "./VenueActiveStoryTable";
// // import VenueStoryListTable from "./VenueStoryTable";
// import VenueAdListTable from "./VenueAdsTable";

const defaultPosition = {
  lat: 33.6706,
  lng: 36.0227,
};

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function Hoteldetails({ venueId, venueName = "" }) {
  const [venueData, setData] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loader, setLoader] = useState(true);
  const [image, setImage] = React.useState("");
  const [images, setImages] = React.useState([]);
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [openLightboxGallery, setOpenLightBoxGallery] = React.useState(false);
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [selectedReview, setSelectedReview] = React.useState(null);
  const [newFeature, setNewFeature] = React.useState("");
  const [featureModal, setFeatureModal] = React.useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    city : "",
    address : "",
    zipCode: "",
    description: "",    
    latitude : "",
    longitude : "",    
  });

  const [textReply, setTextReply] = React.useState("");

  const updateFeature = (value) => {
    setNewFeature(value);
  };

  const submitFeature = async () => {

    const response = await postRequest("hotel/feature/create", {
      intCode: venueData.intCode,
      feature: newFeature,
    });

    if (response.status) {
     // toast.success("Feature added successfully");
      setFeatureModal(false);
      VenueFetchDetail();
    } else {
      toast.error(response.message);
    }

  }

  const removeFeature = async (text) => {
    const response = await deleteRequest("hotel/feature/remove", {
      feature: text,
      intCode: venueData.intCode,
    });

    if (response.status) {
      //toast.success("Feature removed successfully");
      VenueFetchDetail();
    } else {
      toast.error(response.message);
    }
  }




  const closeFeatureModal = () => {
    setFeatureModal(false);
  };

  const handleEditClick = () => {

    setFormData({
      name: venueData.name,
      city: venueData.city,
      address: venueData.address,
      zipCode: venueData.zipCode,
      description: venueData.description,
      latitude: venueData.latitude,
      longitude: venueData.longitude,
    });

    setIsEditing((prevIsEditing) => !prevIsEditing);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSaveClick = async () => {
    // Validate formData as needed

    // Send a request to update the hotel information
    try {
      const response = await postRequest("hotel/update", {
        hotelId: id,
        ...formData
      });

      // If successful, stop editing mode
      if (response.status === 1) {
        toast.success(response.message);
        setIsEditing(false);

        VenueFetchDetail();
       
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      // Handle error (e.g., inform the user about failed update)
      toast.error("Failed to update hotel. Please try again.");
    }
  };

  const handleMusicSelectionChange = (newSelectedMusic) => {
    const oldMusic = [...selectedMusic];
    if (newSelectedMusic.length > oldMusic.length) {
      const addedMusic = newSelectedMusic.filter(
        (newItem) => !oldMusic.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedMusic.length > 0) {
        setSelectedMusic(newSelectedMusic);
        handlerToggle("venue/music/toggle", {
          venueId: id,
          musicId: addedMusic[0]._id,
        });
      }
    } else if (newSelectedMusic.length < oldMusic.length) {
      const removedMusic = oldMusic.filter(
        (oldItem) =>
          !newSelectedMusic.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedMusic.length > 0) {
        setSelectedMusic(newSelectedMusic);
        handlerToggle("venue/music/toggle", {
          venueId: id,
          musicId: removedMusic[0]._id,
        });
      }
    }
  };

  const handleThemeSelectionChange = (newSelectedTheme) => {
    const oldTheme = [...selectedTheme];
    if (newSelectedTheme.length > oldTheme.length) {
      const addedTheme = newSelectedTheme.filter(
        (newItem) => !oldTheme.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedTheme.length > 0) {
        setSelectedTheme(newSelectedTheme);
        handlerToggle("venue/theme/toggle", {
          venueId: id,
          themeId: addedTheme[0]._id,
        });
      }
    } else if (newSelectedTheme.length < oldTheme.length) {
      const removedTheme = oldTheme.filter(
        (oldItem) =>
          !newSelectedTheme.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedTheme.length > 0) {
        setSelectedTheme(newSelectedTheme);
        handlerToggle("venue/theme/toggle", {
          venueId: id,
          themeId: removedTheme[0]._id,
        });
      }
    }
  };

  const handleCuisineSelectionChange = (newSelectedCuisine) => {
    const oldCuisine = [...selectedCuisine];
    if (newSelectedCuisine.length > oldCuisine.length) {
      const addedCuisine = newSelectedCuisine.filter(
        (newItem) => !oldCuisine.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedCuisine.length > 0) {
        setSelectedCuisine(newSelectedCuisine);
        handlerToggle("venue/cuisine/toggle", {
          venueId: id,
          cuisineId: addedCuisine[0]._id,
        });
      }
    } else if (newSelectedCuisine.length < oldCuisine.length) {
      const removedCuisine = oldCuisine.filter(
        (oldItem) =>
          !newSelectedCuisine.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedCuisine.length > 0) {
        setSelectedCuisine(newSelectedCuisine);
        handlerToggle("venue/cuisine/toggle", {
          venueId: id,
          cuisineId: removedCuisine[0]._id,
        });
      }
    }
  };

  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFeatureSelectionChange = (newSelectedFeature) => {
    const oldFeature = [...selectedFeature];
    if (newSelectedFeature.length > oldFeature.length) {
      const addedFeature = newSelectedFeature.filter(
        (newItem) => !oldFeature.some((oldItem) => oldItem._id === newItem._id)
      );
      if (addedFeature.length > 0) {
        setSelectedFeature(newSelectedFeature);
        handlerToggle("venue/feature/toggle", {
          venueId: id,
          featureId: addedFeature[0]._id,
        });
      }
    } else if (newSelectedFeature.length < oldFeature.length) {
      const removedFeature = oldFeature.filter(
        (oldItem) =>
          !newSelectedFeature.some((newItem) => newItem._id === oldItem._id)
      );
      if (removedFeature.length > 0) {
        setSelectedFeature(newSelectedFeature);
        handlerToggle("venue/feature/toggle", {
          venueId: id,
          featureId: removedFeature[0]._id,
        });
      }
    }
  };

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const useStyles = makeStyles({
    tableHeader: {
      color: "#ffffff", // Use your preferred color
      backgroundColor: "#3f51b5", // Header background color
      fontWeight: "bold",
    },
    descriptionText: {
      fontSize: "0.8rem",
    },
    deleteIcon: {
      color: "red",
    },
    roundImage: {
      borderRadius: "50%",
      width: "75px",
      height: "75px",
    },
    roundImageSm: {
      borderRadius: "50%",
    },

    gridList: {
      width: "100%",
    },
    icon: {
      color: "rgba(255, 55, 25, 0.54)",
    },

    // galleryImage: {
    //   width: "100%",
    // },
  });

  const breakpointColumnsObj = {
    default: 3, // adjust number of columns here
    1100: 3,
    700: 2,
    500: 1,
  };

  const classes = useStyles();

  const { id } = useParams();

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const VenueFetchDetail = async () => {
    setLoader(false);
    postRequest(`hotel/detail`, { hotelId: `${id}` }).then((data) => {
      let venueData = data.data;
      //let status = data.status;
      setData(venueData);
      console.log("venueData");
      console.log(venueData);

      setCurrentLocation({
        lat: parseFloat(venueData?.latitude),
        lng: parseFloat(venueData?.longitude),
      });

      if (venueData?.timings && venueData?.timings.length > 0) {
        // fetch the index from days

        const newTimeOptions = [...timeOptions];
        venueData?.timings.map((item, index) => {
          const daysIndex = days.indexOf(capitalizeFirstLetter(item.day));
          newTimeOptions[daysIndex].checked = true;
          newTimeOptions[daysIndex].open = item.openingTime;
          newTimeOptions[daysIndex].close = item.closingTime;
        });

        setTimeOptions(newTimeOptions);
      }

      if (venueData?.music && venueData?.music.length > 0) {
        setSelectedMusic(venueData.music);
      }

      if (venueData?.themes && venueData?.themes.length > 0) {
        setSelectedTheme(venueData.themes);
      }

      if (venueData?.cuisines && venueData?.cuisines.length > 0) {
        setSelectedCuisine(venueData.cuisines);
      }

      if (venueData?.features && venueData?.features.length > 0) {
        setSelectedFeature(venueData.features);
      }

      setLoader(false);
    });
  };


  useEffect(() => {
    VenueFetchDetail();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  const handlerdelete = (id, url, obj, key) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await deleteRequest(`${url}`, obj);

        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          // remove from venueData
          const vData = { ...venueData };
          vData[key] = vData[key].filter((item) => item._id !== id);
          setData(vData);
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const handlerdelete2 = (file) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        deleteGalleryFile( id, file);
      }
    });
  };
  

  const deleteGalleryFile = async (id, file) => {
    const response = await deleteRequest(`hotel/pull-image`, {
      hotelId: id,
      fileName: file,
    });
    if (response.status) {
      Swal.fire("Deleted!", "Deleted successfully");
      VenueFetchDetail();
    } else {
      Swal.fire("Error!", "Something went wrong");
    }
  };

  const handlerToggle = async (url, obj) => {
    await deleteRequest(`${url}`, obj);
  };

  const updateReply = async () => {
    const response = await putRequest("rating/reply/update", {
      ratingId: selectedReview._id,
      reply: textReply,
    });

    // update in venueData as well
    const selReview = selectedReview;

    if (selReview.reply?.reply) {
      selReview.reply.reply = textReply;
    } else {
      selReview.reply = { reply: textReply };
    }

    setSelectedReview(selReview);

    setOpen(false);

    if (response.status == 1) {
      toast.success("reply Updated");
    } else {
      toast.error(response.message);
    }
  };

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

  const showLightBoxGallery = (imgSrc) => {

    const index = images.indexOf(imgSrc);
    setPhotoIndex(index !== -1 ? index : 0); 
    setOpenLightBoxGallery(true);
}

  const handleCancelClick = () => {
    // Reset formData
    setFormData({
      name: venueData.name,
      city: venueData.city,
      address: venueData.address,
      zipCode: venueData.zipCode,
      description: venueData.description,
      latitude: venueData.latitude,
      longitude: venueData.longitude,
    });

    // Exit edit mode
    setIsEditing(false);
  };

  const {
   
    name,
    description,
    address,
    thumbImage,
    coverImage,   
    city,  
    zipCode,
    latitude,
    longitude,   
  } = venueData;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your reply to the review here.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Reply"
            type="text"
            fullWidth
            multiline
            defaultValue={
              selectedReview?.reply?.reply ? selectedReview?.reply?.reply : ""
            }
            onChange={(e) => {
              setTextReply(e.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={ () => handleClose()} color="primary">
            Cancel
          </Button>
          <Button onClick={ () =>  updateReply() } color="primary">
            Reply
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <Row>
        <Col className="10">
          <div className="page-title-box">
            <h4>Hotel Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Hotel Management</li>:{" "}
              <li className="breadcrumb-item">Hotel details</li>
            </ol>
          </div>
        </Col>
        <div className="col-md-2 text-end">
          <button
            type="button"
            class="btn btn-primary btn-lg"
            onClick={() => history.back()}
          >
            <i class="fas fa-chevron-left"></i> Back
          </button>
        </div>

        {loader ? (
          <CardBody style={{ height: "100px" }}>
            <Spinner
              color="info"
              style={{
                height: "4rem",
                width: "4rem",
                position: "absolute",
                left: "50%",
              }}
            >
              Loading...
            </Spinner>
          </CardBody>
        ) : (
          <>
            <Card className="custom-card">
              <div
                className="cover-image"
                style={{ backgroundImage: `url(${coverImage})` }}
                onClick={() => showLightBox(coverImage)}
              ></div>
              <div className="profile-image">
                <img
                  src={thumbImage}                
                  onClick={() => showLightBox(thumbImage)}
                  alt="Image Not Available"
                />

              </div>
              <br></br>

              <div className="row my-2 py-2">

              <div className="userinfo">
        <div className="fst">
          {/* Hotel Name */}         
         
            <h3> {name} </h3>
        
          {/* Edit/Save Button */}
          {isEditing ? (
            <button   className="success" onClick={handleSaveClick}>Save</button>
          ) : (
            <button  className="btn-info" onClick={handleEditClick}>Edit</button>
          )}
          {/* Cancel Button */}
          {isEditing && (
            <button style={{ marginLeft: "6px"}} className="btn-danger" onClick={handleCancelClick}>Cancel</button>
          )}
        </div></div>
        <div className="row">
          {/* City */}

                 <div className="col-md-4 fst py-1">
                  <p>
                    City:{" "}
                    {isEditing ? (
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <span>{name}</span>
                    )}
                  </p>
                </div>


                <div className="col-md-4 fst py-1">
                  <p>
                    City:{" "}
                    {isEditing ? (
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <span>{city}</span>
                    )}
                  </p>
                </div>
                {/* Address */}
                <div className="col-md-4 fst py-1">
                  <p>
                    Address:{" "}
                    {isEditing ? (
                      <input
                        type="text"
                        name="address"
                        className="form-control"
                        value={formData.address}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <span>{address}</span>
                    )}
                  </p>
                </div>
                {/* Zip Code */}
                <div className="col-md-4 fst py-1">
                  <p>
                    Zip Code:{" "}
                    {isEditing ? (
                      <input
                        type="text"
                        className="form-control"
                        name="zipCode"
                        value={formData.zipCode}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <span>{zipCode}</span>
                    )}
                  </p>
                </div>
                {/* latitude */}
                <div className="col-md-4 fst py-1">
                  <p>
                    Latitude:{" "}
                    {isEditing ? (
                      <input
                        type="text"
                        className="form-control"
                        name="latitude"
                        value={formData.latitude}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <span>{latitude}</span>
                    )}
                  </p>
                </div>    
                {/* longitude */}
                <div className="col-md-4 fst py-1">
                  <p>
                    Longitude:{" "}
                    {isEditing ? (
                      <input
                        type="text"
                        name="longitude"
                        className="form-control"
                        value={formData.longitude}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <span>{longitude}</span>
                    )}
                  </p>
               
              </div>
              <hr />
              {/* Description */}
              <div className="row">
                <div className="col-md-12 fst py-1">
                  <p>
                    Description:{" "}
                    {isEditing ? (
                      <textarea
                        name="description"
                        className="form-control"
                        value={formData.description}
                        onChange={handleInputChange}
                      />
                    ) : (
                      <span>{description}</span>
                    )}
                  </p>
                </div>
              </div>
            </div>             


              </div>
            </Card>
            <Col md="12">
              <div
                id="exTab2"
                className="twitter-like-tabs2"
                style={{ background: "#ffff", marginLeft: "25px" }}
              >
                <ul
                  className="nav nav-pills"
                  style={{ width: "480px", display: "contents" }}
                >
                  <li className="active">
                    <a href="#location" data-toggle="tab">
                      {" "}
                      Location{" "}
                    </a>
                  </li>
               
                  <li>
                    <a href="#features" data-toggle="tab">
                      {" "}
                      Features{" "}
                    </a>
                  </li>                

                  <li>
                    <a href="#offers" data-toggle="tab">
                      {" "}
                      Rooms
                    </a>
                  </li>                

                  <li>
                    <a href="#gallery" data-toggle="tab">
                      {" "}
                      Gallery{" "}
                    </a>
                  </li>                 

                  <li>
                    <a href="#reviews" data-toggle="tab">
                      {" "}
                      Reviews{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#bookings" data-toggle="tab">
                      {" "}
                      Bookings{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md="12">
              <div className="tab-content clearfix">
                <div className="tab-pane active padded-tab" id="location">
                  <div className="row">
                    <div className="map-container">
                      <LocationPicker
                        containerElement={<div style={{ height: "100%" }} />}
                        mapElement={<div style={{ height: "350px" }} />}
                        defaultPosition={currentLocation}
                      />
                    </div>
                  </div>
                </div>    
             

                <div className="tab-pane padded-tab" id="features">              
                  <div className="tab-pane padded-tab" id="cuisine">
                      <div className="card feature-card">
                          <div  style={{marginBottom: '20px'}} className="row justify-content-center">                    
                            <Button style={{width: "160px"}} onClick={ () => setFeatureModal(true)} className="btn-sm" variant="primary">
                              <i className="fas fa-plus"></i> Add Feature
                            </Button> 
                          </div>
                          <div className="row">
                              {
                                  venueData?.features?.map((feature, index) => (
                                      <div key={index} className="col-md-3 fst py-1">
                                          <ul className="feature-list">
                                              <li className="feature-item">
                                                  {feature} <i style={{marginLeft: "6px"}} onClick={() => removeFeature(feature)} className="fas fa-trash text-danger"></i>
                                              </li>
                                          </ul>
                                      </div>
                                  ))
                              }
                          </div>
                      </div>
                  </div>
              </div>

                <div className="tab-pane padded-tab" id="offers">
                  {venueData?.intCode !== 0 && (
                    <RoomListTable
                       intCode={venueData.intCode}
                    />
                  )}
                </div>      

              <div className="tab-pane padded-tab" id="reviews">
               {venueData?._id  && (
                    <HotelRatingTable
                    hotelId={venueData._id}
                    />
                  )}
                </div>    

                <div className="tab-pane padded-tab" id="bookings">
               {venueData?._id  && (
                    <HotelBookingTable
                    entityId={venueData.intCode}
                    />
                  )}
                </div>    



                

              <div className="tab-pane padded-tab" id="gallery">  
                <div >
                {/* <Link  to={{
                    pathname: '/createHotelImage',
                    state: { hotelId: id }
                  }}>
                <Button>
                  <i className="fas fa-plus"></i> Create
                </Button>
              </Link>&nbsp;&nbsp;&nbsp;    */}

              <FileDrop hotelId= {id} onUpload={VenueFetchDetail}/>

              </div>


              
              
              
               <br></br>            
               
                <Row>
                  
                  {venueData?.imageFiles?.map((gallery, index) => (
                   
                      <Col key={gallery} xs={12} sm={6} md={4} lg={3} xl={2}>
                        <Card>
                          <CardBody>
                            <img
                              src={gallery}
                              alt="gallery image "
                              className="mb-3"
                              style={{
                                height: "200px",
                                cursor: "pointer",
                                width: "100%",
                              }}
                              onClick={() => {
                                setImages(venueData?.imageFiles); // Set all images                                
                                showLightBoxGallery(gallery); // Open the lightbox with current image
                              }}
                            />
                            <div className="d-flex justify-content-between align-items-center">
                              <RBButton
                                color="danger"
                                onClick={() => handlerdelete2(gallery)}
                              >
                                <i className="fal fa-trash fs-5"></i>
                              </RBButton>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                   
                  ))}
                   </Row>
                </div>  
              
               
              </div>
            </Col>
          </>
        )}

        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}

    {openLightboxGallery && (
         <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setOpenLightBoxGallery(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
     />
        )}

        
      <FeatureAdd  show={featureModal}
        closeModal={closeFeatureModal}
        onSubmit={submitFeature}        
        updateValue = {updateFeature}
      />


      </Row>
      <ToastContainer />{" "}
    </>
  );
}
