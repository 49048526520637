import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";
import CustomLoder from "pages/TicketCategory/CustomLoder.js";
import MultiselectCommon from "./MultiselectCommon.js";
import TicketModal from "components/Modal/TicketModal.js";
import CustomTicketModal from "components/Modal/CustomTicketModal.js";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import IconPackModal from "components/Modal/IconPackModal.js";
import IconPackModalupdated from "components/Modal/IconPackModalupdated.js";
import FileUpload from "../../components/Common/FileUpload.js";

export default function CreateCustomTicket() {
  const [bannerData, setBannerData] = useState(null);
  const [previewImage, setPreviewImage] = useState([]);
  const [ShowLoder, setShowLoder] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedCaterogy, setselectedCaterogy] = useState([]);
  const [selectedTicketoption, setselectedTicketoption] = useState([]);
  const [categoryOptions, setCategoryData] = useState([]);
  const [subCategoryOptions, setSubCategoryData] = useState([]);
  const [categoryDataIds, setSelectedCaterogyIds] = useState([]);
  const [subCategoryDataIds, setSelectedSubCaterogyIds] = useState([]);
  const [packages, setPackages] = useState([]);
  const [WhatsInclude, setIncludePackages] = useState([]);
  const [iconModal, setIconModal] = useState(false);
  const [iconModalInclude, seticonModalInclude] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [selectedIconInclude, setselectedIconInclude] = useState("");
  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);

  const [url, seturl] = useState([]);
  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      // setPreviewImage(reader.result);
      setPreviewImage((oldImage) => [...oldImage, reader.result]);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const CategorylistManagment = async () => {
    const payload = {
      limit: 100000,
      page: 1,
    };
    try {
      const response = await postRequest(`ticket/category/list`, payload);
      const data = response.data;
      let filter = data.list.map((item) => ({
        name: item?.name,
        id: item?._id,
      }));
      setCategoryData(filter);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
    }
  };

  const SubCategorylistManagment = async (ids) => {
    const payload = {
      limit: 100000,
      page: 1,
      categoryIds: ids?ids:categoryDataIds,
    };
    try {
      const response = await postRequest(
        `ticket/sub-category/by-category-ids`,
        payload
      );
      const data = response.data;
      let filter = data.map((item) => ({
        name: item?.name,
        id: item?._id,
      }));
      setSubCategoryData(filter||[]);
    } catch (error) {
      console.log("Failed to fetch data:", error);
    } finally {
    }
  };

  useEffect(() => {
    CategorylistManagment();
    SubCategorylistManagment();
  }, []);

  useEffect(() => {
    SubCategorylistManagment();
  }, [categoryDataIds]);

  const selectIconRow = (icon) => {
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  const selectIncludeIconRow = (icon) => {
    const packagesList = [...packages];
    packagesList[selectedIcon].icon = icon.image;
    packagesList[selectedIcon].iconName = icon.title;
    setPackages(packagesList);
    closeIconModal();
  };

  const selectIconRowIWhatsnclude = (icon) => {
    const packagesList = [...WhatsInclude];
    packagesList[selectedIconInclude].icon = icon.image;
    packagesList[selectedIconInclude].iconName = icon.title;
    setIncludePackages(packagesList);
    closeIconModalInclude();
  };

  const selectIncludeIconRowWhats = (icon) => {
    const packagesList = [...WhatsInclude];
    packagesList[selectedIconInclude].icon = icon.image;
    packagesList[selectedIconInclude].iconName = icon.title;
    setIncludePackages(packagesList);
    closeIconModalInclude();
  };

  const closeIconModal = () => {
    setIconModal(false);
  };
  const closeIconModalInclude = () => {
    seticonModalInclude(false);
  };

  const openIconModal = (index) => {
    setSelectedIcon(index);
    setIconModal(true);
  };

  const openIconModalInclude = (index) => {
    setselectedIconInclude(index);
    seticonModalInclude(true);
  };

  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };
  const updatePackageFieldInclude = (index, fieldName, value) => {
    const newPackages = [...WhatsInclude];
    newPackages[index][fieldName] = value;
    setIncludePackages(newPackages);
  };

  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);

    setPackages(newPackages);
  };

  const deleteIncludePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);

    setIncludePackages(newPackages);
  };

  const addRow = () => {
    setPackages([...packages, { icon: "", feature: "", iconName: "" }]);
  };
  const addIncludeRow = () => {
    setIncludePackages([
      ...WhatsInclude,
      { icon: "", feature: "", iconName: "" },
    ]);
  };

  const saveIncludePackage = (index) => {
    const newPackages = [...WhatsInclude];
    newPackages[index] = { ...newPackages[index], saved: true };
    setIncludePackages(newPackages);

    const packageToSave = newPackages[index];
    // Add your own save logic here, such as API call etc.
  };

  const msUpdateDay = (selectedList, selectedItem) => {
    console.log("test click",selectedList, selectedItem)
    const ids = selectedList.map((item) => item.id);
    console.log("msUpdateDay",ids)
    setSelectedCaterogyIds(ids);
  };

  const msUpdateSubCat = (selectedList, selectedItem) => {
    const ids = selectedList.map((item) => item.id);
    setSelectedSubCaterogyIds(ids);
  };

  const CreateCustomTicketform = async (e) => {
    e.preventDefault();

    if (!bannerData?.title) {
      toast.error("Tittle is required");
      return;
    }
    if (!bannerData?.description) {
      toast.error("Description is required");
      return;
    }
    if (imageUrls.length == 0) {
      toast.error("Please select at least one Image");
      return;
    }
    if (!categoryDataIds.length) {
      toast.error("Please add atleast one option!");
      return;
    }
    if (!bannerData?.bookingType) {
      toast.error("Please select booking type");
      return;
    }
    if (bannerData?.bookingType == "juniper" && bannerData?.code == "") {
      toast.error("Please select code");
      return;
    }
    if (
      bannerData?.bookingType == "elevate" &&
      selectedTicketoption.length == 0
    ) {
      toast.error("Please add atleast one option");
      return;
    }
    if (!bannerData?.startingAmount) {
      toast.error("Starting Amount is required");
      return;
    }
    if (
      bannerData?.isFreeCancellation == undefined ||
      bannerData?.isFreeCancellation == ""
    ) {
      toast.error("Please select free cancellation");
      return;
    }
    // if (!url.length) {
    //   toast.error("Please select atleast one Image");
    //   return
    // }

    setShowLoder(true);
    // const formData = new FormData();
    // formData.append("title", bannerData.title);
    // formData.append("description", bannerData.description);
    // formData.append("images", [url]);
    // formData.append("categoryIds", categoryIds);
    // formData.append("bookingType", bannerData?.bookingType);
    // formData.append("code", bannerData?.code);
    // console.log({ formData });
    let imageData = imageUrls.filter((item) => item != null);

    let payload = {
      title: bannerData.title,
      description: bannerData.description,
      badge: bannerData.badge,
      city: bannerData.city,
      images: imageData,
      categoryIds: categoryDataIds,
      subCategoryIds: subCategoryDataIds,
      code: bannerData?.code,
      bookingType: bannerData?.bookingType,
      startingAmount: bannerData?.startingAmount,
      isFreeCancellation: bannerData?.isFreeCancellation,
      cancellationPolicy: bannerData?.cancellationPolicy,
      ticketOptions: selectedTicketoption,
      features: packages,
      whatsInclude: WhatsInclude,
    };
    const response = await postRequest("ticket/custom/create", payload);
    console.log({ response });
    if (response.status == 1) {
      toast.success(response.message);
      setShowLoder(true);
      setTimeout(() => {
        history.push("/custom/tickets");
      }, 1000);
    } else {
      setShowLoder(false);
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  const Imageupload = async (e) => {
    const formData = new FormData();
    setFileUploadLoader(true);
    formData.append("file", e.target.files[0]);
    // formData.append("file", selectedImage);
    const response = await postRequestForm("homepage/upload/image", formData);
    let image = response.data.url;
    setFileUploadLoader(false);
    console.log({ image });
    setImageUrls([...imageUrls, image]);
  };

  const RemoveImage = (index) => {
    // console.log({ url });
    // let image = previewImage.filter((i, j) => j !== index);
    let UrlImage = imageUrls.filter((i, j) => j !== index);
    // setPreviewImage(image);
    setImageUrls(UrlImage);
  };

  // useEffect(() => {
  //   Imageupload();
  // }, [selectedImage]);

  // moda code ticket
  const [activityModel, setActivityModel] = useState(false);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [updateTicketpData, setUpdateTicketpData] = useState(null);

  const toggleActivityModel = () => {
    console.log("toggleActivityModel", activityModel);
    setActivityModel(!activityModel);
  };

  const closeActivityModal = () => {
    setActivityModel(false);
  };

  const selectActivityRow = (id, name, activity) => {
    setActivityText(id?.name);
    setActivityImage(activity?.image);
    console.log("selected data", id);
    const formInput = { ...bannerData, code: id?.code };

    console.log({ formInput });
    setBannerData(formInput);
    closeActivityModal();
  };

  // ticket option modal code

  const [TicketModalshow, setTicketModalshow] = useState(false);

  const showTicketModel = () => {
    setUpdateTicketpData({});
    setTicketModalshow(true);
  };

  const closeTicketModal = () => {
    setTicketModalshow(false);
  };

  // useEffect(() => {
  //   SelectformData();
  // }, [selectedTicketoption]);

  const SelectformData = (data) => {
    let customtickets = [...selectedTicketoption];
    if (selectedIndex !== -1) {
      customtickets[selectedIndex] = data;
      setselectedTicketoption(customtickets);
      setUpdateTicketpData({});
      setSelectedIndex(-1);
    } else {
      setselectedTicketoption([...selectedTicketoption, data]);
    }
  };

  const handleRemoveTicket = (index) => {
    const newArray = [...selectedTicketoption];
    newArray.splice(index);
    setselectedTicketoption(newArray);
    console.log(selectedTicketoption);
  };

  const handleUpdateTicket = (data, index) => {
    setTicketModalshow(true);
    setSelectedIndex(index);
    setUpdateTicketpData(data);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Ticket Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Ticket Management</li>
              <li className="breadcrumb-item">Create Custom Ticket </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title"> Create Custom Ticket</h4>

              {ShowLoder ? (
                <CustomLoder />
              ) : (
                <Form onSubmit={CreateCustomTicketform}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={bannerData?.title}
                              name="title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description
                          </label>
                          {/* <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              value={bannerData?.description}
                              name="description"
                              className="form-control"
                            />
                          </div> */}

                          <div className="col-sm-6">
                            <ReactQuill
                              style={{
                                height: "100px",
                                width: "100%",
                                marginBottom: "60px",
                              }}
                              value={bannerData?.description || ""}
                              onChange={(value) =>
                                updateValue("description", value)
                              }
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Badge
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("badge", e.target.value)
                              }
                              value={bannerData?.badge}
                              name="badge"
                              className="form-control"
                            />
                          </div>
                        </div>

                        {/* <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            City
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("city", e.target.value)
                              }
                              value={bannerData?.city}
                              name="city"
                              className="form-control"
                            />
                          </div>
                        </div> */}
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            City
                          </label>

                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("city", e.target.value)
                              }
                              name="city"
                              class="form-select"
                            >
                              <option value="">Select</option>

                              <option value="abu dhabi">Abu Dhabi</option>
                              <option value="ajman">Ajman</option>
                              <option value="dubai">Dubai</option>
                              <option value="fujairah">Fujairah</option>
                              <option value="sharjah">Sharjah</option>
                              <option value="ras al khaimah">
                                Ras Al Khaimah
                              </option>
                              <option value="umm al quwain">
                                Umm Al Quwain
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            File
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) => Imageupload(e)}
                              name="mediaUrl"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            <div className="row">
                              {imageUrls.map((image, index) => (
                                <div className="d-flex align-items-center flex-column col-md-3">
                                  <img
                                    key={index} // Add a unique key for each image in the array
                                    src={image}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      padding: "5px",
                                    }}
                                    alt={`Preview ${index + 1}`} // Provide alt text for accessibility
                                  />

                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => RemoveImage(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Category
                          </label>
                          <div className="col-sm-6">
                            <MultiselectCommon
                              options={categoryOptions}
                              onSelect={msUpdateDay}
                              onRemove={msUpdateDay}
                              // setselectedCaterogy={setselectedCaterogy}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Sub Category
                          </label>
                          <div className="col-sm-6">
                            <MultiselectCommon
                              options={subCategoryOptions}
                              onSelect={msUpdateSubCat}
                              // setselectedCaterogy={setselectedCaterogy}
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Booking Type
                          </label>

                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue("bookingType", e.target.value)
                              }
                              name="bookingType"
                              class="form-select"
                            >
                              <option value="">Select</option>

                              <option value="juniper">Juniper</option>
                              <option value="elevate">Elevate</option>
                            </select>
                          </div>
                        </div>

                        {bannerData?.bookingType == "elevate" && (
                          <>
                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Cancellation Policy
                              </label>
                              <div className="col-sm-6">
                                <textarea
                                  rows="4"
                                  onChange={(e) =>
                                    updateValue(
                                      "cancellationPolicy",
                                      e.target.value
                                    )
                                  }
                                  value={bannerData?.cancellationPolicy}
                                  name="cancellationPolicy"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="row mb-4">
                              <label className="col-sm-3 col-form-label">
                                Add Option
                              </label>

                              <div className="col-sm-6">
                                <button
                                  type="button"
                                  className="btn btn-primary w-md"
                                  onClick={() => showTicketModel()}
                                >
                                  Add Option
                                </button>
                              </div>
                            </div>

                            <div className="row">
                              <div class="">
                                <table class="table table-bordered table-striped">
                                  <thead>
                                    <tr>
                                      <th>S.NO</th>
                                      <th>Title</th>
                                      <th>Start Date</th>
                                      <th>End Date</th>
                                      <th>Availability Type</th>
                                      <th>Amount</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedTicketoption?.map(
                                      (item, index) => (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>{item?.title}</td>
                                          <td>
                                            {" "}
                                            {moment(item?.startDate).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </td>
                                          <td>
                                            {moment(item?.endDate).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </td>
                                          <td>{item?.availabilityType}</td>
                                          <td>{item?.amount}</td>
                                          <td>
                                            <button
                                              onClick={() =>
                                                handleUpdateTicket(item, index)
                                              }
                                              className="btn btn-primary w-md mr-2"
                                              type="button"
                                            >
                                              Edit
                                            </button>
                                            &nbsp; &nbsp;
                                            <button
                                              onClick={() =>
                                                handleRemoveTicket(index)
                                              }
                                              className="btn-remove"
                                              type="button"
                                            >
                                              Remove
                                            </button>
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )}

                        {bannerData?.bookingType == "juniper" && (
                          <div className="row mb-4">
                            <label className="col-sm-3 col-form-label">
                              Select Code
                            </label>
                            <div
                              className="col-sm-6"
                              onClick={() => toggleActivityModel()}
                            >
                              {activityText ? (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleActivityModel()}
                                  >
                                    Choose Code
                                  </button>
                                  {/* <div
                                       style={{
                                         border: "1px solid #ced4da",
                                         width: "16px",
                                       }}
                                     ></div> */}
                                  {/* <img
                                       src={activityImage}
                                       style={{
                                         width: "40px",
                                         height: "40px",
                                         border: "1px solid #ced4da",
                                         borderRight: "none",
                                         borderLeft: "none",
                                       }}
                                     ></img> */}
                                  <input
                                    type="text"
                                    style={{ borderLeft: "none" }}
                                    class="form-control"
                                    readonly
                                    value={activityText}
                                    id="selectedVenue"
                                    placeholder="No Code chosen"
                                  />
                                </div>
                              ) : (
                                <div class="input-group">
                                  <button
                                    class="btn"
                                    style={{
                                      border: "1px solid #ced4da",
                                      background: "#7a6fbe",
                                      color: "#ffff",
                                    }}
                                    type="button"
                                    id="chooseVenueBtn"
                                    onclick={() => toggleActivityModel()}
                                  >
                                    Choose Code
                                  </button>
                                  <input
                                    type="text"
                                    class="form-control"
                                    readonly
                                    id="selectedVenue"
                                    placeholder="No Code chosen"
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Feature List
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Icon</th>
                                  <th>Select Feature</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding:
                                            " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          borderRadius: "0.25rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <div style={{ minWidth: "100%" }}>
                                          {item?.icon && (
                                            <img
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              src={item.icon}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}

                                          {!item?.icon && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                openIconModal(index);
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "tomato",
                                                border: "none",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Select Icon
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.feature}`}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "feature",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => savePackage(index)}
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() => deletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addRow}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            What's Include
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Icon</th>
                                  <th>Select Feature</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {WhatsInclude.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <div
                                        style={{
                                          display: "flex",
                                          padding:
                                            " 0.375rem 1.75rem 0.375rem 0.75rem",
                                          borderRadius: "0.25rem",
                                          lineHeight: 1.5,
                                        }}
                                      >
                                        <div style={{ minWidth: "100%" }}>
                                          {item?.icon && (
                                            <img
                                              onClick={() => {
                                                openIconModalInclude(index);
                                              }}
                                              src={item.icon}
                                              style={{
                                                width: "60px",
                                                height: "60px",
                                              }}
                                            />
                                          )}

                                          {!item?.icon && (
                                            <button
                                              type="button"
                                              onClick={() => {
                                                openIconModalInclude(index);
                                              }}
                                              style={{
                                                color: "white",
                                                backgroundColor: "tomato",
                                                border: "none",
                                                padding: "10px 20px",
                                              }}
                                            >
                                              Select Icon
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.feature}`}
                                        onChange={(e) => {
                                          updatePackageFieldInclude(
                                            index,
                                            "feature",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() =>
                                            saveIncludePackage(index)
                                          }
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() =>
                                            deleteIncludePackage(index)
                                          }
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addIncludeRow}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Starting Amount
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="number"
                              onChange={(e) =>
                                updateValue("startingAmount", e.target.value)
                              }
                              value={bannerData?.startingAmount}
                              name="startingAmount"
                              min={0}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Is Free Cancellation Available
                          </label>

                          <div className="col-sm-6">
                            <select
                              className="form-control"
                              onChange={(e) =>
                                updateValue(
                                  "isFreeCancellation",
                                  e.target.value
                                )
                              }
                              name="isFreeCancellation"
                              class="form-select"
                            >
                              <option value="">Select</option>

                              <option value="true">Yes</option>
                              <option value="false">No</option>
                            </select>
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </CardBody>
          </Card>
        </Col>

        <IconPackModal
          show={iconModal}
          closeModal={closeIconModal}
          selectRow={selectIconRow}
          selectIncludeRow={selectIncludeIconRow}
        />

        <IconPackModalupdated
          show={iconModalInclude}
          closeModal={closeIconModalInclude}
          selectRow={selectIconRowIWhatsnclude}
          selectIncludeRow={selectIncludeIconRowWhats}
        />
      </Row>

      <TicketModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
        selectIncludeRow={selectActivityRow}
      />

      <CustomTicketModal
        updateTicketpData={updateTicketpData}
        selectedTicketoption={selectedTicketoption}
        selectedIndex={selectedIndex}
        show={TicketModalshow}
        closeModal={closeTicketModal}
        SelectformData={SelectformData}
      />
      <FileUpload message="File Uploading" status={fileUploadLoader} />
    </React.Fragment>
  );
}
