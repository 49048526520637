import React from "react";
import { Redirect } from "react-router-dom";
//Email
// Authentication related pages
import Login from "../pages/Authentication/Login";
import AgentLogin from "../pages/AgentAuth/Login";
import Forgetpassword from "../pages/Authentication/Forgetpassword";

import ForgetpasswordAgent from "../pages/AgentAuth/Forgetpassword";
// Dashboard
import Dashboard from "../pages/Dashboard/index";
import UserManagmentlist from "pages/UserManagement/UserManagmentlist";
import Updateuserdata from "pages/UserManagement/Updateuserdata";
import ChatWindow from "pages/Conversation/ChatWindow";
import ConvoManagmentlist from "pages/Conversation/ConvoManagmentlist";

import AgentDashboard from "../pages/AgentDashboard/index";
import AgentConversation from "../pages/AgentConversation/ConvoManagmentlist";
import AgentChatWindow from "../pages/AgentChatWindow/ChatWindow";

import PrivacyPolicy from "../pages/privacy/privacyPolicy";
import Termscondition from "../pages/terms/terms";


import VenueManagmentlist from "pages/VenueManagement/VenueManagmentlist";
import CreateVenue from "pages/VenueManagement/CreateVenue";
import UpdateVenue from "pages/VenueManagement/UpdateVenue";
import MenuManagmentlist from "pages/MenuManagement/MenuManagmentlist";
import CreateMenu from "pages/MenuManagement/CreateMenu";
import UpdateMenu from "pages/MenuManagement/UpdateMenu";
import RatingManagmentlist from "pages/RatingManagement/RatingManagmentlist";
import FollowManagementlist from "pages/FollowManagement/FollowManagmentlist";
import UpdateRating from "pages/RatingManagement/UpdateRating";
import ThemeManagmentlist from "pages/ThemeManagement/ThemeManagmentlist";
import CreateTheme from "pages/ThemeManagement/CreateTheme";
import UpdateTheme from "pages/ThemeManagement/UpdateTheme";
import CuisineManagmentlist from "pages/CuisineManagement/CuisineManagmentlist";
import CreateCuisine from "pages/CuisineManagement/CreateCuisine";
import UpdateCuisine from "pages/CuisineManagement/UpdateCuisine";

import CreateVenueTheme from "pages/VenueManagement/CreateVenueTheme";
import CreateVenueCuisine from "pages/VenueManagement/CreateVenueCuisine";
import CreateStory from "pages/StoryManagement/CreateStory";
import StoryManagmentlist from "pages/StoryManagement/StoryManagmentlist";
import PageManagementlist from "pages/AppManagement/PageManagementlist";
import CreatePage from "pages/AppManagement/CreatePage";
import UpdatePage from "pages/AppManagement/UpdatePage";
import VenueStoryManagementlist from "pages/VenueManagement/VenueStoryManagmentlist";
import CreateVenueStory from "pages/VenueManagement/CreateVenueStory";
import GalleryManagementlist from "pages/GalleryManagement/GalleryManagmentlist";
import CreateGallery from "pages/GalleryManagement/CreateGallery";
import CreateVenueMusic from "pages/VenueManagement/CreateVenueMusic";
import CreateVenueFeature from "pages/VenueManagement/CreateVenueFeature";
import CustomComponentManagementlist from "pages/CustomComponentManagement/CustomCompoManagmentlist";
import CreateCustomComponent from "pages/CustomComponentManagement/CreateCustomComponent";

import VideoManagementlist from "pages/VideoManagement/VideoManagmentlist";
import CreateVideo from "pages/VideoManagement/CreateVideo";
import UpdateVideo from "pages/VideoManagement/UpdateVideo";
import HomeBlockManagementlist from "pages/HomeBlockManagement/HomeBlockManagmentlist";
import CreateHomeBlock from "pages/HomeBlockManagement/CreateHomeBlock";
import UpdateHomeBlock from "pages/HomeBlockManagement/UpdateHomeBlock";
import SearchBlockManagementlist from "pages/SearchBlockManagement/SearchBlockManagmentlist";
import CreateSearchBlock from "pages/SearchBlockManagement/CreateSearchBlock";
import UpdateSearchBlock from "pages/SearchBlockManagement/UpdateSearchBlock";
import OfferManagementlist from "pages/OfferManagement/OfferManagmentlist";
import CreateOffer from "pages/OfferManagement/CreateOffer";
import UpdateOffer from "pages/OfferManagement/UpdateOffer";



import TimeManagementlist from "pages/TimeManagement/TimeManagmentlist";
import CreateTime from "pages/TimeManagement/CreateTime";
import UpdateTime from "pages/TimeManagement/UpdateTime";

import DiscountTypeManagementlist from "pages/DiscountManagement/DiscountTypeManagmentlist";
import CreateDiscountType from "pages/DiscountManagement/CreateDiscountType";
import UpdateDiscountType from "pages/DiscountManagement/UpdateDiscountType";
import DiscountManagementlist from "pages/DiscountManagement/DiscountManagmentlist";
import CreateDiscount from "pages/DiscountManagement/CreateDiscount";
import UpdateDiscount from "pages/DiscountManagement/UpdateDiscount";
import BannerTypeManagementlist from "pages/BannerManagement/BannerTypeManagmentlist";
import CreateBannerType from "pages/BannerManagement/CreateBannerType";
import UpdateBannerType from "pages/BannerManagement/UpdateBannerType";
import BannerManagementlist from "pages/BannerManagement/BannerManagmentlist";
import CreateBanner from "pages/BannerManagement/CreateBanner";
import AddReply from "pages/RatingManagement/AddReply";
import SpecialOfferManagementlist from "pages/OfferManagement/SpecialOfferManagmentlist";
import SpecialOfferClaimManagementlist from "pages/OfferManagement/SpecialOfferClaimManagmentlist";

import ComplainManagement from "pages/Complain Management/ComplainManagement";
import Complainreplay from "pages/Complain Management/Complainreplay";
import UpdateMusic from "pages/Complain Management/UpdateMusic";
import FeatureList from "pages/Features/FeatureList";
import CreateFeature from "pages/Features/CreateFeature";
import UpdateFeature from "pages/Features/UpdateFeature";
import BlockSizeList from "pages/HomeBlockSize/BlockSizeList";
import CreateBlockSize from "pages/HomeBlockSize/CreateBlockSize";
import UpdateBlockSize from "pages/HomeBlockSize/UpdateBlockSize";
import CategoryList from "pages/CategoryManagement/CategoryList";
import CreateCategory from "pages/CategoryManagement/CreateCategory";
import UpdateCategory from "pages/CategoryManagement/UpdateCategory";
import SpecialOfferlist from "pages/SpecialOffer/SpecialOffer";
import CreateSpecialOffer from "pages/SpecialOffer/CreateSpecialOffer";
import UpdateSpecialOffer from "pages/SpecialOffer/UpdateSpecialOffer";
import CreateClaim from "pages/OfferManagement/CreateClaim";
import Homeblockdetails from "pages/HomeBlockManagement/Homeblockdetails";
import PackageList from "pages/PackageManagement/PackageList";
import CreatePackage from "pages/PackageManagement/CreatePackage";
import UpdatePackage from "pages/PackageManagement/UpdatePackage";
import Venuedetails from "pages/VenueManagement/Venuedetails";
import NotificationList from "pages/Notification/NotificationList";
import UpdateNotification from "pages/Notification/UpdateNotification";
import CreateNotification from "pages/Notification/CreateNotification";
import Notificationdetails from "pages/Notification/NotificationDetails";
import AppTransactionList from "pages/AppTransaction/AppTransactionList";
import UpdateCustomComponent from "pages/CustomComponentManagement/UpdateCustomComponent";
import ComponentManagementlist from "pages/ComponentManagement/ComponentManagmentlist";
import CreateComponets from "pages/ComponentManagement/CreateComponent";
import UpdateComponets from "pages/ComponentManagement/UpdateComponent";
import UserCreated from "pages/UserManagement/UserCreated";
import SubAdminlist from "pages/SubAdmin/SubAdminlist";
import FeedBackList from "pages/FeedBack/FeedBackList";

import CreateSubAdmin from "pages/SubAdmin/CreateSubAdmin";
import UpdateSubAdmin from "pages/SubAdmin/UpdateSubAdmin";
import HotelManagement from "pages/Hotel Management/HotelManagement";
import UpdateHotel from "pages/Hotel Management/UpdateHotel";
import Hoteldetails from "pages/Hotel Management/Hoteldetails";
import HotelRoomManagement from "pages/HotelRoom Management/HotelRoomManagement";
import TicketManagement from "pages/Ticket Management/TicketManagement";
import Ticketdetails from "pages/Ticket Management/Ticketdetails";
import TransferManagement from "pages/Transfer Management/TransferManagement";
import Transferdetails from "pages/Transfer Management/Transferdetails";
import HotelRatingManagementlist from "pages/HotelReviewManagement/HotelRatingManagmentlist";
import UpdateHotelRating from "pages/HotelReviewManagement/UpdateHotelRating";
import AddHotelReply from "pages/HotelReviewManagement/AddHotelReply";
import TicketRatingManagementlist from "pages/TicketReviewManagement/TicketRatingManagmentlist";
import UpdateTicketRating from "pages/TicketReviewManagement/UpdateTicketRating";
import AddTicketReply from "pages/TicketReviewManagement/AddTicketReply";
import TransferRatingManagementlist from "pages/TransferReviewManagement/TransferRatingManagmentlist";
import UpdateTransferRating from "pages/TransferReviewManagement/UpdateTransferRating";
import AddTransferReply from "pages/TransferReviewManagement/AddTransferReply";
import DealManagementlist from "pages/DealManagement/DealManagmentlist";
import CreateDeal from "pages/DealManagement/CreateDeal";
import UpdateDeal from "pages/DealManagement/UpdateDeal";
import Userdetails from "../pages/UserManagement/Userdetails";
import BookingManagementlist from "pages/BookingManagement/BookingManagmentlist";
import Bookingdetails from "pages/BookingManagement/Bookingdetails";
import HotelBookingManagementlist from "pages/HotelBookingManagement/HotelBookingManagmentlist";
import TicketBookingManagementlist from "pages/TicketBookingManagement/TicketBookingManagmentlist";
import TransferBookingManagementlist from "pages/TransferBookingManagement/TransferBookingManagmentlist";
import CreateHotelimage from "../pages/Hotel Management/CreateHotelimage";
import ChartComponets from "pages/DashboardChart/ChartComponent";
import AutoResponder from "pages/AutoResponder/AutoResponder";
import OrderManagementlist from "pages/OrderManagement/OrderManagmentlist";
import DealBookingList from "pages/DealManagement/DealBookingList";
import JpSetting from "pages/Setting/JpSetting";
import AppThemeSetting from "pages/Setting/AppThemeSetting";
import BookingLineDetails from "pages/BookingManagement/BookingLineDetails";
import TicketDetailsUpdated from "pages/Ticket Management/TicketDetailsUpdated";
import TicketCategory from "pages/TicketCategory/TicketCategory";
import TicketCategoryList from "pages/TicketCategory/TicketCategoryList";

import TicketSubCategory from "pages/TicketCategory/TicketSubCategory";

import TicketSubCategoryList from "pages/TicketCategory/TicketSubCategoryList";

import UpdateTicketCategory from "pages/TicketCategory/UpdateTicketCategory";
import UpdateTicketSubCategory from "pages/TicketCategory/UpdateTicketSubCategory";

import CustomTicketManagementList from "pages/CustomTicket/CustomTicketManagementList";
import CreateCustomTicket from "pages/CustomTicket/CreateCustomTicket";
import UpdatedCustomTicket from "pages/CustomTicket/UpdatedCustomTicket";
import ElevateTicketBookingList from "pages/HotelBookingManagement/ElevateTicketBookingList";
import Iconlist from "pages/IconManagement/IconList";
import CreateIcon from "pages/IconManagement/CreateIcon";
import AdminProfileUpdate from "pages/AdminManagement/adminProfile";
import AdminSetting from "pages/AdminManagement/adminSetting";
import ItineraryCreate from "pages/ItineraryManagement/ItineraryCreate";
import ItineraryManagementlist from "pages/ItineraryManagement/ItineraryManagementlist";
import ItineraryUpdate from "pages/ItineraryManagement/ItineraryUpdate";
import DeleteAccount from "pages/Authentication/DeleteAccount";

import DeleteAccountRequest from "pages/UserManagement/DeleteAccountRequest";

const userRoutes = [
  // Dashboard
  { path: "/dashboard", component: Dashboard },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
  { path: "/users", component: UserManagmentlist },

  { path: "/deleteAccount", component: DeleteAccountRequest },

  { path: "/userdetails/:id", component: Userdetails },
  // business List

  // Venue List
  { path: "/venues", component: VenueManagmentlist },
  // Create Venue
  { path: "/createVenue", component: CreateVenue },
  { path: "/createVenueTheme", component: CreateVenueTheme },
  { path: "/createVenueCuisine", component: CreateVenueCuisine },
  { path: "/createVenueMusic", component: CreateVenueMusic },
  { path: "/createVenueFeature", component: CreateVenueFeature },
  { path: "/venuedetails/:id", component: Venuedetails },

  // Update Venue
  { path: "/updateVenue/:id", component: UpdateVenue },
  // Menu List
  { path: "/menu/:id", component: MenuManagmentlist },
  // Create Menu
  { path: "/createMenu/:id", component: CreateMenu },
  // Story List
  { path: "/story/:id", component: VenueStoryManagementlist },
  { path: "/createStory/:id", component: CreateVenueStory },
  // Update Menu
  { path: "/updateMenu/:id", component: UpdateMenu },
  // Rating List
  { path: "/ratings", component: RatingManagmentlist },
  // Update Rating
  { path: "/updateRating/:id", component: UpdateRating },
  //add reply
  { path: "/addReply/:id", component: AddReply },
  // Theme List
  { path: "/themes", component: ThemeManagmentlist },


  // Create Theme
  { path: "/createTheme", component: CreateTheme },
  // Update Theme
  { path: "/updateTheme/:id", component: UpdateTheme },

  // Cuisine List
  { path: "/cuisines", component: CuisineManagmentlist },
  // Create Cuisine
  { path: "/createCuisine", component: CreateCuisine },
  // Update Cuisine
  { path: "/updateCuisine/:id", component: UpdateCuisine },

  { path: "/updateuserdata/:id", component: Updateuserdata },


  //Story List
  { path: "/stories", component: StoryManagmentlist },

  // Story
  { path: "/createStory", component: CreateStory },

  //Page List
  { path: "/pages", component: PageManagementlist },
  //Create Page
  { path: "/createPage", component: CreatePage },
  //Update Page
  { path: "/updatePage/:id", component: UpdatePage },
  //Gallery List
  { path: "/galleries", component: GalleryManagementlist },
  //Create Gallery
  { path: "/createGallery", component: CreateGallery },
  // videos List
  { path: "/videos", component: VideoManagementlist },
  // Create Video
  { path: "/createVideo", component: CreateVideo },
  // Update Video
  { path: "/updateVideo/:id", component: UpdateVideo },

  // Time List
  { path: "/time/:id", component: TimeManagementlist },
  // Create Time
  { path: "/createTime/:id", component: CreateTime },
  // Update Time
  { path: "/updateTime/:id", component: UpdateTime },

  // HomeBlock List
  { path: "/homeblock", component: HomeBlockManagementlist },
  // create HomeBlock
  { path: "/createHomeblock", component: CreateHomeBlock },
  // Update HomeBlock
  { path: "/updateHomeblock/:id", component: UpdateHomeBlock },
  //Home Block Details
  { path: "/homeblockdetails/:id", component: Homeblockdetails },

  // search block
  { path: "/search-block", component: SearchBlockManagementlist },
  { path: "/search-block/create", component: CreateSearchBlock },
  { path: "/search-block/update/:id", component: UpdateSearchBlock },
  
  //Music List
  { path: "/Complaintlist", component:ComplainManagement  },
  { path: "/Complainreplay", component: Complainreplay },
  { path: "/Updatemusicdata/:id", component: UpdateMusic },

  // Feature List
  { path: "/feature", component: FeatureList },
  { path: "/Createfeaturedata", component: CreateFeature },
  { path: "/Updatefeaturedata/:id", component: UpdateFeature },

  // BlockSize List
  { path: "/blocksize", component: BlockSizeList },
  { path: "/Createsizedata", component: CreateBlockSize },
  { path: "/Updatesizedata/:id", component: UpdateBlockSize },

  //Discount Type List
  { path: "/discountType", component: DiscountTypeManagementlist },
  // Create Discount Type
  { path: "/createDiscountType", component: CreateDiscountType },
  // Update Discount Type
  { path: "/updateDiscountType/:id", component: UpdateDiscountType },

  //Discount List
  { path: "/discount", component: DiscountManagementlist },
  // Create Discount
  { path: "/createDiscount", component: CreateDiscount },
  // Update Discount
  { path: "/updateDiscount/:id", component: UpdateDiscount },

  //Banner Type List
  { path: "/bannerType", component: BannerTypeManagementlist },
  // Create Banner Type
  { path: "/createBannerType", component: CreateBannerType },
  // Update Banner Type
  { path: "/updateBannerType/:id", component: UpdateBannerType },

  //Banner List
  { path: "/banner", component: BannerManagementlist },
  // Create Banner
  { path: "/createBanner", component: CreateBanner },

  // Deal List
  { path: "/deals", component: DealManagementlist },

  // Create Deal
  { path: "/createDeal", component: CreateDeal },

  // Update Deal
  { path: "/updateDeal/:id", component: UpdateDeal },

  //Offer List
  { path: "/offer", component: OfferManagementlist },
  // Create Offer
  { path: "/createOffer", component: CreateOffer },
  // Update Offer
  { path: "/updateOffer/:id", component: UpdateOffer },
  // Category List
  { path: "/Category", component: CategoryList },
  { path: "/Createcategorydata", component: CreateCategory },
  { path: "/Updatecategorydata/:id", component: UpdateCategory },
  // Notification
  { path: "/notification", component: NotificationList },
  { path: "/notificationdetails", component: Notificationdetails },
  { path: "/Createnotification", component: CreateNotification },
  { path: "/Updatenotificationdata", component: UpdateNotification },
  //transactions
  { path: "/app-transaction", component: AppTransactionList },

  { path: "/conversation", component: ConvoManagmentlist },

  { path: "/chat-window/:id?", component: ChatWindow },

  // Chart List
  { path: "/chart", component: ChartComponets },

  // Auto Responeder
  { path: "/autoresponder", component: AutoResponder },

// Order List
  { path: "/orders", component: OrderManagementlist },
  // Special Offer List
  //{ path: "/specialOffer", component: SpecialOfferManagementlist },

   // admin profile
   { path: "/admin-profile", component: AdminProfileUpdate },

  // admin profile Setting
  { path: "/admin-setting", component: AdminSetting },

  // Special Offer Claim List
  { path: "/SpecialOfferClaim", component: SpecialOfferClaimManagementlist },
  // Special Offer List
  { path: "/specialoffer", component: SpecialOfferManagementlist },
  // { path: "/specialoffer", component: SpecialOfferlist },
  { path: "/CreateSpecialOffer", component: CreateSpecialOffer },
  { path: "/UpdateSpecialOffer/:id", component: UpdateSpecialOffer },
  // Create Claim
  { path: "/createClaim", component: CreateClaim },
  // package List
  { path: "/package", component: PackageList },
  // Create package
  { path: "/Createpackagedata", component: CreatePackage },
  // Update package
  { path: "/Updatepackagedata/:id", component: UpdatePackage },
  // Custom Component List
  { path: "/Component", component: ComponentManagementlist },
  { path: "/UserCreated", component: UserCreated },

  { path: "/CreateComponets", component: CreateComponets },
  { path: "/updateComponent/:id", component: UpdateComponets },
  // Create Custom Component
  { path: "/createCustomComponent", component: CreateCustomComponent },
  // Update Custom Component
  { path: "/updateComponent/:id", component: UpdateCustomComponent },
  // Follow List
  { path: "/follows", component: FollowManagementlist },

  // Booking List
  { path: "/bookings", component: BookingManagementlist },
  { path: "/elevatebookings", component: ElevateTicketBookingList },

  // Booking Details
  { path: "/bookingdetails/:id", component: Bookingdetails },
// Booking line Details
   {   path: "/bookingLinedetails", component: BookingLineDetails   },

  // Hotel Booking List
  { path: "/hotelbookings", component: HotelBookingManagementlist },

  // Ticket Booking List
  { path: "/ticketbookings", component: TicketBookingManagementlist },

  // Transfer Booking List
  { path: "/transferbookings", component: TransferBookingManagementlist },

// DealBooking routing


{ path: "/DealBookingList", component: DealBookingList },


  //Hotel List
  { path: "/hotels", component: HotelManagement },
  { path:"/hotelDetails/:id",component:Hoteldetails},

  // Update Hotel
  { path: "/updateHotel/:id", component: UpdateHotel },

  // Add Hotel Image
  { path:"/createHotelImage",component:CreateHotelimage},

  // Hotel Rating List
  { path: "/hotelratings", component: HotelRatingManagementlist },
  // Update Hotel Rating
  { path: "/updateHotelRating/:id", component: UpdateHotelRating },
  //Add Reply
  { path: "/addReply/:id", component: AddHotelReply },


  // Ticket Rating List
  { path: "/ticketratings", component: TicketRatingManagementlist },
  // Update Ticket Rating
  { path: "/updateTicketRating/:id", component: UpdateTicketRating },
  //Add Reply
  { path: "/addTicketReply/:id", component: AddTicketReply },

  // Transfer Rating List
  { path: "/transferratings", component: TransferRatingManagementlist },
  // Update Transfer Rating
  { path: "/updateTransferRating/:id", component: UpdateTransferRating },
  //Add Reply
  { path: "/addTransferReply/:id", component: AddTransferReply },

  //Hotel Room List
  { path: "/hotelroom", component: HotelRoomManagement },

  // Ticket List
  { path: "/tickets", component: TicketManagement },
  { path: "/createCategory", component: TicketCategory },
  { path: "/UpdatCategory/:_id", component: UpdateTicketCategory },
  { path: "/UpdateSubCategory/:_id", component: UpdateTicketSubCategory },

  { path: "/ticketdetails/:id", component: Ticketdetails },
  { path: "/ticketdetails/edit/:id", component: TicketDetailsUpdated },

// custom ticket 
{ path: "/custom/tickets", component: CustomTicketManagementList },
{ path: "/custom/tickets/create", component: CreateCustomTicket },
{ path: "/custom/tickets/update/:id", component: UpdatedCustomTicket },
// Itinerary 
{ path: "/Itinerary/list", component: ItineraryManagementlist },
{ path: "/Itinerary/create", component: ItineraryCreate },
{ path: "/Itinerary/update/:id", component: ItineraryUpdate },
  // { path: "/createCategory", component: TicketCategory },

  { path: "/CategoryList", component: TicketCategoryList },

  { path: "/createSubCategory", component: TicketSubCategory },

  { path: "/SubCategoryList", component: TicketSubCategoryList },

  // Transfer List
  { path: "/transfers", component: TransferManagement },
  { path: "/transferdetails/:id", component: Transferdetails },



  // Subadmin list 
  { path: "/SubAdminlist", component: SubAdminlist },
  { path: "/FeedBackList", component: FeedBackList },

  { path: "/CreateSubAdmin", component: CreateSubAdmin },
  { path: "/UpdateSubAdmin", component: UpdateSubAdmin },

  //setting
  { path: "/jp", component: JpSetting },
  { path: "/app-theme", component: AppThemeSetting },


  // icon
  { path: "/Icon-management-list", component: Iconlist },
  { path: "/Create-Icon", component: CreateIcon },


];

const authRoutes = [  
  { path: "/login", component: Login },
  { path: "/delete-account-request", component: DeleteAccount },
 
  { path: "/agent/login", component: AgentLogin },
  { path: "/PasswordReset", component:  Forgetpassword },
  { path: "/agent/PasswordReset", component:  ForgetpasswordAgent },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-condition", component: Termscondition },
  // { path: "/privacy-policy", component: PrivacyPolicy },
];

const agentRoutes = [
  { path: "/agent/dashboard", component: AgentDashboard },
  { path: "/agent/conversation", component: AgentConversation },
  { path: "/agent/chat-window", component: AgentChatWindow },
];


export { userRoutes, authRoutes, agentRoutes };
