import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest, putRequestForm } from "../../components/Common/Utils.js";
import HotelModal from "../../components/Modal/HotelModal.js";
import CategoryModal from "../../components/Modal/CategoryModal.js";
import BannerModal from "components/Modal/BannerModal.js";
import TicketModal from "components/Modal/TicketModal.js";
import TransferModal from "components/Modal/TransferModal.js";

// import VenueModal from "../../components/Modal/HotelModal.js";

export default function UpdateHotel() {
  const location = useLocation();
  const state = location.state?.row;

  const [bannerData, setBannerData] = useState(null);
  const [previewImage, setPreviewImage] = useState("");
  
  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [venueImage, setVenueImage] = useState("");
  const [model, setModel] = useState(false);
  const [offerId, setOfferId] = useState("");
  const [offerText, setOfferText] = useState("");
  const [offerImage, setOfferImage] = useState(""); 
  const [offerModel, setOfferModel] = useState(false);
  const [activityId, setActivityId] = useState("");
  const [activityText, setActivityText] = useState("");
  const [activityImage, setActivityImage] = useState("");
  const [activityModel, setActivityModel] = useState(false);
  const [eventId, setEventId] = useState("");
  const [eventText, setEventText] = useState("");
  const [eventImage, setEventImage] = useState("");
  const [eventModel, setEventModel] = useState(false);


  const [selectedImage, setSelectedImage] = useState("");

  const [url, seturl] = useState("");


  let history = useHistory();

  useEffect(() => {
    console.log("state", state);

    if(state?.type == 'hotel'){
      setVenueText(state?.selectedName)  
      setVenueImage(state?.selectedImage)
    }

    if(state?.type == 'deal'){
      setOfferText(state?.selectedName)
      setOfferImage(state?.selectedImage)
    }

    if(state?.type == 'ticket'){
      setActivityText(state?.selectedName)
      setActivityImage(state?.selectedImage)
    }

    if(state?.type == 'transfer'){
      setEventText(state?.selectedName)
      setEventImage(state?.selectedImage)
    }

    const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = (1 + date.getMonth()).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
  
  return `${year}-${month}-${day}`;
};

    const bData = {
      name: state.name,
      address: state.address,
      description: state.description,
      zipCode: state.zipCode,
      city: state.city,
      latitude: state.latitude,
      longitude: state.longitude,
        
    }
    // console.log("bData", bData);

    setPreviewImage(state?.image)

    setBannerData(bData)


  }, [state]);



  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const toggleModel = () => {
    console.log("toggleModel", model);
    setModel(!model);
  };

  const closeModal = () => {
    setModel(false);
  };
  const toggleOfferModel = () => {
    console.log("toggleOfferModel", offerModel);
    setOfferModel(!offerModel);
  };
  const closeOfferModal = () => {
    setOfferModel(false);
  };

  const toggleActivityModel = () => {
    console.log("toggleActivityModel", activityModel);
    setActivityModel(!activityModel);
  };
  const closeActivityModal = () => {
    setActivityModel(false);
  };

  const toggleEventModel = () => {
    console.log("toggleEventModel", eventModel);
    setEventModel(!eventModel);
  };
  const closeEventModal = () => {
    setEventModel(false);
  };

  const selectRow = (id, name, venue) => {
    setVenueId(id);
    setVenueText(id?.name);
    setVenueImage(id?.thumbImage);
    const formInput = { ...bannerData };
    formInput.item = id;
    formInput.subHeading = name;
    formInput.subText = venue?.address;
    formInput.buttonText = "View";
    formInput.logo = venue?.thumbImage;
    formInput.background = venue?.cover;
    setBannerData(formInput);
   // setPreviewLogo(venue?.image);
    //setPreviewImage(venue?.image);
    closeModal();
  };

  const selectOfferRow = (offer) => {
    setVenueId(offer?._id);
    setOfferText(offer?.title);
    setOfferImage(offer?.image);
    const formInput = { ...bannerData };
    formInput.item = offer?._id;

    console.log("offer", offer, formInput);

    if (bannerData.title == "") formInput.title = offer?.title;
    if (bannerData.description == "")
      formInput.description = offer?.description;

    formInput.subHeading = offer?.venue?.name;
    formInput.subText = offer?.venue?.address;
    formInput.buttonText = "View";
    formInput.logo = offer?.venue?.logo;
    formInput.background = offer?.image;

    setBannerData(formInput);
    // setPreviewImage(offer?.image);
    // setPreviewLogo(offer?.venue?.logo);
    closeOfferModal();
  };

  const selectActivityRow = (id, name, activity) => {
    setVenueId(id);
    setActivityText(id?.name);
    setActivityImage(activity?.image);
    console.log("id", id);
    const formInput = { ...bannerData };
    formInput.item = id;
    if (bannerData.title == "") formInput.title = name;
    if (bannerData.description == "")
      formInput.description = activity?.description;
    formInput.subHeading = activity?.provider?.name;
    formInput.subText = activity?.provider?.address;
    formInput.buttonText = "View";
    formInput.logo = activity?.provider?.logo;
    formInput.background = activity?.galleries?.length
      ? activity?.galleries[0]
      : "";
    setBannerData(formInput);
    // setPreviewImage(activity?.galleries?.length ? activity?.galleries[0] : "");
    // setPreviewLogo(activity?.provider?.logo);
    closeActivityModal();
  };

  const selectEventRow = (id, name, event) => {
    setVenueId(id);
    setEventText(id?.title);
    setEventImage(id?.image);
    console.log("id", id);
    const formInput = { ...bannerData };
    formInput.item = id;
    if (bannerData.title == "") formInput.title = name;
    if (bannerData.description == "")
      formInput.description = event?.description;
    formInput.subHeading = event?.venue?.name;
    formInput.subText = event?.venue?.address;
    formInput.buttonText = "View";
    formInput.logo = event?.venue?.logo;
    formInput.background = event?.image;
    setBannerData(formInput);
    // setPreviewImage(event?.image);
    // setPreviewLogo(event?.venue?.logo);
    closeEventModal();
  };

  const UpdateHotelform = async (e) => {
    e.preventDefault();  
    const payload = {
      hotelId: state?._id,
      name: bannerData.name,
      address: bannerData.address,
      zipCode: bannerData.zipCode,
      city: bannerData.city,
      latitude: bannerData.latitude,
      longitude: bannerData.longitude,
    }   

    const response = await postRequest("hotel/update", payload);
    // setLoading(false);
    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/hotels");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };


  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  const Imageupload = async (e) => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    const response = await postRequestForm("homepage/upload/image", formData);
    let image  = response.data.url
    console.log({image})
    seturl(image)
  };





  useEffect(()=>{
    Imageupload()
  },[selectedImage])
  return (
    <React.Fragment>
      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Hotel Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Hotel Management</li>:{" "}
              <li className="breadcrumb-item">Update Hotel</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title"> Update Hotel</h4>

              <Form onSubmit={UpdateHotelform}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">Name</label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("name", e.target.value)
                            }
                            value={bannerData?.name}
                            name="name"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                          Address
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            type="text"
                            onChange={(e) =>
                              updateValue("address", e.target.value)
                            }
                            value={bannerData?.address}

                            name="address"
                            className="form-control"
                          />
                        </div>
                      </div>                      


                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Zip Code
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("zipCode", e.target.value)
                            }
                            value={bannerData?.zipCode}

                            name="zipCode"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        City
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            onChange={(e) =>
                              updateValue("city", e.target.value)
                            }
                            value={bannerData?.city}

                            name="city"
                            className="form-control"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Latitude
                        </label>
                        <div className="col-sm-6">
                          <input

                            type="text"
                            onChange={(e) =>
                              updateValue("latitude", e.target.value)
                            }
                            value={bannerData?.latitude}

                            name="latitude"
                            className="form-control"
                          />
                        </div>
                      </div>       

                      <div className="row mb-4">
                        <label className="col-sm-3 col-form-label">
                        Longitude
                        </label>
                        <div className="col-sm-6">
                          <input

                            type="text"
                            onChange={(e) =>
                              updateValue("longitude", e.target.value)
                            }
                            value={bannerData?.longitude}

                            name="longitude"
                            className="form-control"
                          />
                        </div>
                      </div>                         
                      


                      {/* <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          File
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="file"
                            onChange={(e) =>
                              setSelectedImage(e.target.files[0])
                            }
                            name="mediaUrl"
                            className="form-control"
                            id="horizontal-password-input"                            
                          />
                          <br />
                          {bannerData?.mediaType == "video"
                            ? null
                            : previewImage && (
                                <img
                                  src={previewImage}
                                  style={{ width: "100px", height: "100px" }}
                                />
                              )}
                        </div>
                      </div> */}

                      


                      <div className="mt-5 mt-lg-4">
                        <div className="row justify-content-end">
                          <div className="col-sm-9">
                            <div>
                              <button
                                type="submit"
                                className="btn btn-primary w-md"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* modal */}
      <HotelModal show={model} closeModal={closeModal} selectRow={selectRow} />
      <CategoryModal
        show={offerModel}
        closeModal={closeOfferModal}
        selectRow={selectOfferRow}
      />
      <TicketModal
        show={activityModel}
        closeModal={closeActivityModal}
        selectRow={selectActivityRow}
      />
      <TransferModal
        show={eventModel}
        closeEventModal={closeEventModal}
        selectRow={selectEventRow}
      />    

    </React.Fragment>
  );
}
