import React, { useState, useEffect } from "react";
import { useHistory,useParams, useLocation  } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, FormGroup, Form, Table, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  postRequestForm, postRequest } from "../../components/Common/Utils.js";

import VenueModal from "../../components/Modal/HotelModal.js";

export default function CreateDiscount() {
  const location = useLocation();
  const state = location.state?.row;
  

  const [discountData, setDiscountData] = useState(null); 
  const [previewImage, setPreviewImage] = useState(""); 
  const [loading, setLoading] = useState(false);  
  const [selectedImage, setSelectedImage] = useState(""); 
  const [discounttypeList, setDiscounttypeList] = useState([]);

  const [venueId, setVenueId] = useState("");
  const [venueText, setVenueText] = useState("");
  const [model, setModel] = useState(false);

 

  let history = useHistory();

  const { id } = useParams(); 
  
  useEffect(() => {
    if(!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result)
    }
    reader.readAsDataURL(selectedImage)
  }, [selectedImage])

  
  useEffect(() => {
    fetchList();
    }, []);

    const fetchList = async () => {
      setLoading(true);
      const response = await postRequest("discount/type/list", { limit: 10000000});
      if (response.status == 1) {
        setDiscounttypeList(response.data.list);
      }
      setLoading(false);
    };

    const toggleModel = () => {
      console.log("toggleModel", model)
      setModel(!model);
    };
  
 
    const closeModal = () => {
      setModel(false);
    };

    const selectRow = (id, name) => {
      setVenueId(id);
      setVenueText(name);
      closeModal();
    };  

  const Creatediscount = async (e) => {
    e.preventDefault();
    if(!discountData.title){
      toast.error("Please enter title");
      return;
    }

    if(!discountData.discount){
      toast.error("Please enter discount %");
      return;
    }


    if(!discountData.description){
      toast.error("Please enter description");
      return;
    }
   
    if(!discountData.discountTypeId){
      toast.error("Please enter discountTypeId");
      return;
    }
    

   setLoading(true);
  
    const formData = new FormData();
    formData.append("discountTypeId", discountData.id); 
    formData.append("discount", discountData.discount);
    formData.append("title", discountData.title);
    formData.append("description", discountData.description);  
    formData.append("startDate", discountData?.startDate ? discountData?.startDate : "");
    formData.append("expiryDate", discountData?.expiryDate ? discountData?.expiryDate : "");
   
   
   
    
    const response = await postRequestForm("discount/create", formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/discount");
      }, 1000);
      
    } else {
      toast.error(response.message);
    }  
    
  };

  const updateValue = (index, value) => {
    const list = {...discountData}
    list[index] = value
    setDiscountData(list)
    
  } 
    
 
    

  return (
    <React.Fragment>

      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Discount Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Discount Management</li>:{" "}
              <li className="breadcrumb-item">
              Create Discount 
              </li>
            </ol>
          </div>
        </div>
      </div>
     
      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>

          { loading ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :

            <CardBody>
              <h4 className="card-title"> Create Discount</h4>
              <Form onSubmit={Creatediscount}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">

                    <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Title</label>
                            <div className="col-sm-6">
                            <input type="text"  onChange={ (e) => updateValue('title', e.target.value) } value={discountData?.title} name='title'  className="form-control"  />
                            </div>
                        </div>   

                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Discount %</label>
                            <div className="col-sm-6">
                            <input type="text"  onChange={ (e) => updateValue('discount', e.target.value) } value={discountData?.discount} name='title'  className="form-control"  />
                            </div>
                        </div> 

                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Select Discount Type</label>
                            <div className="col-sm-6">
                              <select onChange={ (e) => updateValue('discountTypeId', e.target.value) }  class="form-select">
                                   <option>  Select</option>
                                    {discounttypeList.map((item, index) => (
                                      <option value={item._id}>{item.title}</option>
                                    ))}    
                                 </select>
                                 
                            </div>                           
                        </div>                   

                       

                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Description</label>
                            <div className="col-sm-6">
                            <textarea type="text"  onChange={ (e) => updateValue('description', e.target.value) } value={discountData?.description} name='description'  className="form-control"  />
                            </div>
                        </div>                

                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Start Date</label>
                            <div className="col-sm-6">
                            <input type="date"  onChange={ (e) => updateValue('startDate', e.target.value) } value={discountData?.startDate} name='startDate'  className="form-control"  />
                            </div>
                        </div>   
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Expiry Date</label>
                            <div className="col-sm-6">
                            <input type="date"  onChange={ (e) => updateValue('expiryDate', e.target.value) } value={discountData?.expiryDate} name='expiryDate'  className="form-control"  />
                            </div>
                        </div>                       
                       
                        <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                            <div className="col-sm-9">
                            <div>
                                <button type="submit"  className="btn btn-primary w-md">Submit</button>
                            </div>
                            </div>
                            </div>
                        </div>

                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody> }
          </Card>
        </Col>
      </Row>

      {/* modal */}

      <VenueModal show={model} closeModal={closeModal} selectRow={selectRow} />  

    </React.Fragment>



  );
}
