
import React, {useState} from "react"
import { Row, Col, CardBody, Card, Container } from "reactstrap"

import { Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import { ToastContainer, toast } from "react-toastify";
import logo from "../../assets/images/logo.png"
import { postRequest } from "components/Common/Utils";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"


const Forgetpassword = (props) => {
  const [email, setEmail] = useState("");

  const resetPassword = async (e) =>  {
    e.preventDefault();
    let data = { email };   

    postRequest("admin/sub-admin/forget-password", data).then((data) => {
      let log = data;

     // console.log(log, "log")
      //return;


      if (log.status) {
        toast.success(log.message);
      } else {
        toast.error(log.message);
      }
     
    });
  }


  return (
    <React.Fragment>
      <ToastContainer />
      <MetaTags>
        <title>Recover Password | Yalator Admin Dashboard</title>
      </MetaTags>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center">                   
                      {/* <img src={logoDark} alt="" height="30" className="auth-logo-dark" />
                      <img src={logoLightPng} alt="" height="30" className="auth-logo-light" /> */}
                      <div class="text-center" style={{ padding: 20 }}>
                        <img src={logo} alt="" height="40" className="auth-logo-light"  /> 
                      </div>                   
                  </h3>
                  <div className="">
                    <h4 className="text-muted font-size-18 mb-3 text-center">Recover Password</h4>
                    <div className="alert alert-info" role="alert">
                      Enter your Email and instructions will be sent to you!
                    </div>
                    {props.forgetError && props.forgetError ? (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {props.forgetError}
                      </Alert>
                    ) : null}
                    {props.forgetSuccessMsg ? (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {props.forgetSuccessMsg}
                      </Alert>
                    ) : null}

                    <AvForm
                      className="form-horizontal mt-4"
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        
                        <Link to="/agent/login" className="password-reset" style={{ color: "#EC672C !important" }}>
                            Login
                        </Link>

                      </div>

                      <Row className="mb-3">
                        <Col className="text-end">
                          <button
                            className="btn btn-primary w-md waves-effect waves-light"
                            type="submit"
                            onClick={(e) => resetPassword(e)}
                          >
                            Reset
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>             
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Forgetpassword
