import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { postRequestForm, postRequest } from "../../components/Common/Utils.js";

const FileDrop = ({ hotelId, onUpload }) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [url, setUrl] = useState(null);
  const [isDragging, setIsDragging] = useState(false);  
  const fileInputRef = React.createRef(); 
  const [previewSrc, setPreviewSrc] = useState('');

  const onImageUpload = async () => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    
    try {
      const response = await postRequestForm("homepage/upload/image", formData);
      const image = response.data.url;
      //console.log({ response: response.data.url });
      setUrl(image);



      await onCreateBannerForm(image); // Automatically call the next step after successful upload
    } catch (error) {
      console.error("Upload failed:", error);
      toast.error("Image upload failed. Please try again.");
    }
  };

  const onCreateBannerForm = async (imageUrl) => {
    const data = {
      hotelId: hotelId,
      fileName: imageUrl
    };

    try {
      const response = await postRequest("hotel/push-image/", data);
      console.log({ response });

      if (response.status === 1) {
        //toast.success(response.message);
        // setTimeout(() => {
        //   history.push("/hotelDetails/" + hotelId);
        // }, 1000);

        onUpload();


      } else {
        toast.error(response.message);
      }
    } catch (error) {
      console.error("Banner creation failed:", error);
      toast.error("Banner creation failed. Please try again.");
    } finally {
      setSelectedImage(null);
      setPreviewSrc(null);
    }
  };

  const onClick = () => {
    fileInputRef.current.click(); // Trigger the file input's click event to show the file selection dialog
  };


  useEffect(() => {
    if (selectedImage) {
      onImageUpload();
    }
  }, [selectedImage]);



  const onDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const onDragLeave = () => {
    setIsDragging(false);
  };

  const onSelectImage = (e) => {
    if (e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      setPreviewSrc(URL.createObjectURL(e.target.files[0])); // Set the preview image source URL
    }
  };

  const onDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);

    if (e.dataTransfer.files.length > 0) {
      setSelectedImage(e.dataTransfer.files[0]);
      setPreviewSrc(URL.createObjectURL(e.dataTransfer.files[0])); // Set the preview image source URL
      //onImageUpload(); // Upload image immediately upon drop
    }
  };

  const dropZoneStyles = {
    border: '2px dashed gray',
    padding: '80px 60px',
    cursor: 'pointer',
    color: isDragging ? 'green' : 'black'   
  };

  return (
    <div>
        <div 
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onClick={onClick}
        style={dropZoneStyles}
        >
         {previewSrc ? (
          <img 
            src={previewSrc} 
            alt="preview" 
            style={{
              maxWidth: '100%', 
              maxHeight: '100%', 
              display: 'block',
              margin: 'auto'
            }}
          />
        ) : (
          <p>Drag & Drop an image here or click to select one</p>
        )}

        <input 
                type="file" 
                ref={fileInputRef} 
                style={{ display: 'none' }} 
                onChange={onSelectImage} 
                accept="image/*" // Ensure only image files can be selected
              />
        </div>
  </div>
  );
};

export default FileDrop;