import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col, Card, CardBody, Form, Spinner } from "reactstrap";

import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

import { postRequest } from "../../components/Common/Utils.js";

export default function CreateMusic() {
  const [musicData, setMusicData] = useState(null);
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  const updateMusicData = async (e) => {
    e.preventDefault();
    if (!musicData.title) {
      toast.error("Please enter title");
      return;
    }

    setLoading(true);

    const dataObj = {
      id: musicData._id,
      title: musicData.title,
    };

    const response = await postRequest("music/create", dataObj);

    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/music");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...musicData };
    list[index] = value;
    setMusicData(list);
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Complaint Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Complaint Management</li>:{" "}
              <li className="breadcrumb-item">
                <Link to="#">Complaint Reply </Link>
              </li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}

      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody >
                <h4 className="card-title ">Complain Reply </h4>
                {/* <Form onSubmit={updateMusicData}>
                  <Row>
                    <Col lg={5} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-firstname-input"
                            className="col-sm-3 col-form-label"
                          >
                            Title
                          </label>
                          <div className="col-sm-9">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              name="title"
                              defaultValue={musicData?.title}
                              className="form-control"
                              id="horizontal-firstname-input"
                            />
                          </div>
                        </div>
                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col lg={6} className="ms-lg-auto"></Col>
                  </Row>
                </Form> */}

                <div className="messagebox">
                <div className="msger-chat">

                  <div className="msg right-msg">
                    {/* <div className="msg-img">
                      <i className="fad fa-user-circle"></i>
                    </div> */}
                    <div className="msg-img">
                      <img src="https://cdn-icons-png.flaticon.com/512/219/219983.png" alt="img user"/>
               
                      </div>
                    <div className="msg-bubble">
                      <div className="msg-info">
                        <div className="msg-info-name">admin</div>
                        <div className="msg-info-time">12:45</div>
                      </div>
                      <div className="msg-text">hi</div>
                    </div>
                  </div>

                  <div className="msg left-msg">
                    {/* <div className="msg-img">
                      <i className="fad fa-user-circle"></i>
                    </div> */}
                    <div className="msg-img">
                      <img src="https://cdn-icons-png.flaticon.com/512/219/219983.png" alt="img user"/>
               
                      </div>
                    <div className="msg-bubble-left">
                      <div className="msg-info">
                        <div className="msg-info-name">admin</div>
                        <div className="msg-info-time">12:45</div>
                      </div>
                      <div className="msg-text">hi</div>
                    </div>
                  </div>
                </div>
                </div>

                <form className="msger-inputarea" id="messageForm">

        <input type="text" className="msger-input" name="message" id="messageInput" placeholder="Enter your message..." autocomplete="off"/>
        <button className="btnsend">Send</button>
    </form>
                
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
