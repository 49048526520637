import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { postRequestForm, postRequest, putRequest } from "../../components/Common/Utils.js";
import CustomLoder from "pages/TicketCategory/CustomLoder.js";

import "react-quill/dist/quill.snow.css";

import FileUpload from "../../components/Common/FileUpload.js";
import CustomTicketNameModal from "components/Modal/CustomTicketNameModal.js";

export default function ItineraryUpdate() {
  const { id } = useParams();

  const location = useLocation();
  const state = location.state?.row;

  console.log({ state });
  const [bannerData, setBannerData] = useState(null);
  const [previewImage, setPreviewImage] = useState([]);
  const [ShowLoder, setShowLoder] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [iconModal, setIconModal] = useState(false);
  const [packages, setPackages] = useState([]);

  const [fileUploadLoader, setFileUploadLoader] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);

  const [url, seturl] = useState([]);
  let history = useHistory();

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      // setPreviewImage(reader.result);
      setPreviewImage((oldImage) => [...oldImage, reader.result]);
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  useEffect(() => {
    setBannerData(state);

    setImageUrls(state?.images);

    let newtons = state?.tags?.map(tag => {
      return { Tags: tag };
    });

    console.log(newtons);

    setPackages(newtons);
  }, [state]);

  const updatePackageField = (index, fieldName, value) => {
    const newPackages = [...packages];
    newPackages[index][fieldName] = value;
    setPackages(newPackages);
  };

  const savePackage = (index) => {
    const newPackages = [...packages];
    newPackages[index] = { ...newPackages[index], saved: true };
    setPackages(newPackages);
  };

  const deletePackage = (index) => {
    const newPackages = packages.filter((item, i) => i !== index);

    setPackages(newPackages);
  };

  const addRow = () => {
    setPackages([...packages, { icon: "", Tags: "", iconName: "" }]);
  };

  const CreateCustomTicketform = async (e) => {
    e.preventDefault();

    if (!bannerData?.ticketName) {
      toast.error("Tittle Name  is required");
      return;
    }

    if (imageUrls.length == 0) {
      toast.error("Please select at least one Image");
      return;
    }

    setShowLoder(true);

    let imageData = imageUrls.filter((item) => item != null);

    let payload = {
      ticketName: bannerData?.ticketName,
      type: bannerData?.type,
      id,
      images: imageData,

      tags: packages?.map((item) => item?.Tags),
    };
    const response = await putRequest("user/update-itinerary-images", payload);
    console.log({ response });
    if (response.status == 1) {
      toast.success(response?.message);
      setShowLoder(true);
      setTimeout(() => {
        history.push("/Itinerary/list");
      }, 1000);
    } else {
      setShowLoder(false);
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...bannerData };
    list[index] = value;
    setBannerData(list);
  };

  const Imageupload = async (e) => {
    const formData = new FormData();
    setFileUploadLoader(true);
    formData.append("file", e.target.files[0]);
    // formData.append("file", selectedImage);
    const response = await postRequestForm("homepage/upload/image", formData);
    let image = response.data.url;
    setFileUploadLoader(false);
    console.log({ image });
    setImageUrls([...imageUrls, image]);
  };

  const RemoveImage = (index) => {
    let UrlImage = imageUrls.filter((i, j) => j !== index);

    setImageUrls(UrlImage);
  };


  const selectEventRow = (item) => {
    console.log({item})
    
    
        setIconModal(false)
    
        setBannerData({ ...bannerData, ticketName: item });
      };
  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Ticket Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Ticket Management</li>
              <li className="breadcrumb-item">Update Itinerary Images </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title"> Update Itinerary Images</h4>

              {ShowLoder ? (
                <CustomLoder />
              ) : (
                <Form onSubmit={CreateCustomTicketform}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">
                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Select  Ticket
                          </label>
                          <div className="col-sm-6">
                            <select
                              value={bannerData?.type}
                              onChange={(e) =>
                                updateValue("type", e.target.value)
                              }
                              required
                              className="form-select"

                            >
                              <option > select Ticket Name</option>
                              <option value="manually"> Manually</option>
                              <option value="list_view">From list</option>
                            </select>
                          </div>
                        </div>


                        {bannerData?.type == "manually" && <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Ticket Name
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("ticketName", e.target.value)
                              }
                              value={bannerData?.ticketName}
                              name="ticketName"
                              className="form-control"
                            />
                          </div>
                        </div>}

                        {bannerData?.type == "list_view" &&

                          <div className="row mb-4">

                            <label
                              htmlFor="horizontal-password-input"
                              className="col-sm-3 col-form-label"
                            >
                              Chose Ticket

                            </label>
                            <div className="col-sm-6">


                              <div class="input-group">
                                <button
                                  class="btn"
                                  style={{
                                    border: "1px solid #ced4da",
                                    background: "#7a6fbe",
                                    color: "#ffff",
                                  }}
                                  type="button"
                                  id="chooseEventBtn"
                                  onClick={() => setIconModal(true)}

                                >
                                  Choose Ticket
                                </button>
                                <input
                                  type="text"
                                  class="form-control"
                                  readonly
                                  value={bannerData?.ticketName}
                                  id="selectedEvent"
                                  placeholder="No Ticket chosen"
                                />
                              </div>
                            </div>





                          </div>

                        }

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            File
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) => Imageupload(e)}
                              name="mediaUrl"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            <div className="row">
                              {imageUrls?.map((image, index) => (
                                <div className="d-flex align-items-center flex-column col-md-3">
                                  <img
                                    key={index} // Add a unique key for each image in the array
                                    src={image}
                                    style={{
                                      width: "100px",
                                      height: "100px",
                                      padding: "5px",
                                    }}
                                    alt={`Preview ${index + 1}`} // Provide alt text for accessibility
                                  />

                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={() => RemoveImage(index)}
                                  >
                                    Remove
                                  </button>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Tags List
                          </label>
                          <div className="col-sm-6">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>Select Tags</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages.map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <input
                                        type="text"
                                        value={`${item?.Tags}`}
                                        onChange={(e) => {
                                          updatePackageField(
                                            index,
                                            "Tags",
                                            e.target.value
                                          );
                                        }}
                                        className="form-control"
                                      />
                                    </td>
                                    <td>
                                      {!item.saved ? (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "blue",
                                            border: "none",
                                            padding: "10px 20px",
                                            marginRight: "10px",
                                          }}
                                          onClick={() => savePackage(index)}
                                          disabled={item.saved}
                                        >
                                          Save
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          style={{
                                            color: "white",
                                            backgroundColor: "red",
                                            border: "none",
                                            padding: "10px 20px",
                                          }}
                                          onClick={() => deletePackage(index)}
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                              <tfoot>
                                <tr>
                                  <td colSpan={4}>
                                    <button
                                      type="button"
                                      style={{
                                        color: "white",
                                        backgroundColor: "green",
                                        border: "none",
                                        padding: "10px 20px",
                                      }}
                                      onClick={addRow}
                                    >
                                      Add Row
                                    </button>
                                  </td>
                                </tr>
                              </tfoot>
                            </table>
                          </div>
                        </div>

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-9">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <FileUpload message="File Uploading" status={fileUploadLoader} />



      <CustomTicketNameModal

        show={iconModal}
        closeModal={() => setIconModal(false)}
        selectRow={selectEventRow}

      />
    </React.Fragment>
  );
}
