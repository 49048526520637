import React, { useState, useEffect } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Spinner } from "reactstrap"
import { Link, NavLink, useLocation } from 'react-router-dom';
import { DeleteConfirmDialog, deleteRequest, getRequest, postRequest, postRequestForm, putRequest, putRequestForm } from "components/Common/Utils";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import Swal from "sweetalert2/dist/sweetalert2.js";
import { format } from "date-fns";
import StarComponent from './StarComponent'; 

import Masonry from 'react-masonry-css';
import Lightbox from "react-image-lightbox";


import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from "@material-ui/core/GridListTileBar";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Avatar } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import LocationPicker from "react-location-picker";
import DayTimePicker from "./DayTimePicker";


import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import TagSelection from './TagSelection';
import { ToastContainer, toast } from "react-toastify";

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import './CustomCard.css'; 

const defaultPosition = {
  lat: 33.6706,
  lng: 36.0227,
};





const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export default function VenueDetails() {  
  const [Active, setActive] = useState([]);
  const [created, setcreated] = useState([]);
  const [venueData, setData] = useState([]); 
  const [selectedActivityValues, setSelectedActivityValues] = useState([]);
  const [selectedDayValues, setSelectedDayValues] = useState([]);
  const [selectedPlayTimeValues, setSelectedPlayTimeValues] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(defaultPosition);
  const [loader, setLoader] = useState(true);
  const [image, setImage] = React.useState("");
  const location1 = useLocation();
  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [selectedMusic, setSelectedMusic] = useState([]);  
  const [selectedTheme, setSelectedTheme] = useState([]);
  const [selectedCuisine, setSelectedCuisine] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState([]); 
  const [open, setOpen] = React.useState(false);
  const [selectedReview, setSelectedReview] = React.useState(null);

  const [textReply, setTextReply] = React.useState('');


  const handleMusicSelectionChange = (newSelectedMusic) => {
    const oldMusic = [...selectedMusic];
     if (newSelectedMusic.length > oldMusic.length) {       
        const addedMusic = newSelectedMusic.filter(
            newItem => !oldMusic.some(oldItem => oldItem._id === newItem._id)
        );
        if(addedMusic.length > 0) {                   
          setSelectedMusic(newSelectedMusic);
          handlerToggle('venue/music/toggle', { venueId: id, musicId: addedMusic[0]._id })
        }
    } else if (newSelectedMusic.length < oldMusic.length) {       
        const removedMusic = oldMusic.filter(
            oldItem => !newSelectedMusic.some(newItem => newItem._id === oldItem._id)
        );
        if(removedMusic.length > 0) {          
          setSelectedMusic(newSelectedMusic);
          handlerToggle('venue/music/toggle', { venueId: id, musicId: removedMusic[0]._id })
        }
    } 
  };

  const handleThemeSelectionChange = (newSelectedTheme) => {

    const oldTheme = [...selectedTheme];
      if (newSelectedTheme.length > oldTheme.length) {
        const addedTheme = newSelectedTheme.filter(
            newItem => !oldTheme.some(oldItem => oldItem._id === newItem._id)
        );
        if(addedTheme.length > 0) {          
          setSelectedTheme(newSelectedTheme);
          handlerToggle('venue/theme/toggle', { venueId: id, themeId: addedTheme[0]._id })
        }
    } else if (newSelectedTheme.length < oldTheme.length) {
        const removedTheme = oldTheme.filter(
            oldItem => !newSelectedTheme.some(newItem => newItem._id === oldItem._id)
        );
        if(removedTheme.length > 0) {          
          setSelectedTheme(newSelectedTheme);
          handlerToggle('venue/theme/toggle', { venueId: id, themeId: removedTheme[0]._id })
        }
    }    
  };

  const handleCuisineSelectionChange = (newSelectedCuisine) => {

    const oldCuisine = [...selectedCuisine];
      if (newSelectedCuisine.length > oldCuisine.length) {
        const addedCuisine = newSelectedCuisine.filter(
            newItem => !oldCuisine.some(oldItem => oldItem._id === newItem._id)
        );
        if(addedCuisine.length > 0) {          
          setSelectedCuisine(newSelectedCuisine);
          handlerToggle('venue/cuisine/toggle', { venueId: id, cuisineId: addedCuisine[0]._id })
        }
    } else if (newSelectedCuisine.length < oldCuisine.length) {
       const removedCuisine = oldCuisine.filter(
            oldItem => !newSelectedCuisine.some(newItem => newItem._id === oldItem._id)
        );
        if(removedCuisine.length > 0) {
          setSelectedCuisine(newSelectedCuisine);
          handlerToggle('venue/cuisine/toggle', { venueId: id, cuisineId: removedCuisine[0]._id })
        }
    }  
   
  };

  const handleOpen = (review) => {
    setSelectedReview(review);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };



  const handleFeatureSelectionChange = (newSelectedFeature) => {

    const oldFeature = [...selectedFeature];
      if (newSelectedFeature.length > oldFeature.length) {
        const addedFeature = newSelectedFeature.filter(
            newItem => !oldFeature.some(oldItem => oldItem._id === newItem._id)
        );
        if(addedFeature.length > 0) {          
          setSelectedFeature(newSelectedFeature);
          handlerToggle('venue/feature/toggle', { venueId: id, featureId: addedFeature[0]._id })
        }
    } else if (newSelectedFeature.length < oldFeature.length) {
        const removedFeature = oldFeature.filter(
            oldItem => !newSelectedFeature.some(newItem => newItem._id === oldItem._id)
        );
        if(removedFeature.length > 0) {          
          setSelectedFeature(newSelectedFeature);
          handlerToggle('venue/feature/toggle', { venueId: id, featureId: removedFeature[0]._id })
        }
    }    
  };

  const [timeOptions, setTimeOptions] = useState([
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
    { checked: false, open: "00:00", close: "00:00" },
  ]);

  const useStyles = makeStyles({
    tableHeader: {
      color: '#ffffff', // Use your preferred color
      backgroundColor: '#3f51b5', // Header background color
      fontWeight: 'bold',
    },
    descriptionText: {
      fontSize: '0.8rem',
    },
    deleteIcon: {
      color: 'red',
    },
    roundImage: {
      borderRadius: "50%",
      width: '75px',
      height: '75px'
    },
    roundImageSm: {
      borderRadius: "50%"    
    },

    gridList: {
      width: "100%",      
    },
    icon: {
      color: 'rgba(255, 55, 25, 0.54)',
    },

    galleryImage: {
      width: '100%',
    },

  });

  const breakpointColumnsObj = {
    default: 3, // adjust number of columns here
    1100: 3,
    700: 2,
    500: 1
  };
  
  const classes = useStyles();
  

  const { id } = useParams(); 

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return "";
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const VenueFetchDetail = async () => {
    setLoader(false)
    postRequest(`venue/detail`, { venueId: `${id}` }).then((data) => {
      let venueData = data.data;
     //let status = data.status;
      setData(venueData);
      console.log("venueData");
      console.log(venueData);

      setCurrentLocation({
        lat: parseFloat(venueData?.location?.coordinates[1]),
        lng: parseFloat(venueData?.location?.coordinates[0]),
      });

      if (venueData?.times && venueData?.times.length > 0) {
        // fetch the index from days
  
        const newTimeOptions = [...timeOptions];
        venueData?.times.map((item, index) => {
          const daysIndex = days.indexOf(capitalizeFirstLetter(item.day));
  
          newTimeOptions[daysIndex].checked = true;
          newTimeOptions[daysIndex].open = item.openingTime;
          newTimeOptions[daysIndex].close = item.closingTime;
        });
  
        console.log(newTimeOptions, "newTimeOptions")
  
        setTimeOptions(newTimeOptions);
      }
    

      if(venueData?.music && venueData?.music.length > 0) {        
          setSelectedMusic(venueData.music);
      }

      if(venueData?.themes && venueData?.themes.length > 0) {      
        setSelectedTheme(venueData.themes);
      }

      if(venueData?.cuisines && venueData?.cuisines.length > 0) {
        setSelectedCuisine(venueData.cuisines);
      }

      if(venueData?.features && venueData?.features.length > 0) {
        setSelectedFeature(venueData.features);
      }


      setLoader(false)
    });
  }; 

  const Updatevenue = async (e) => {   

    const formData = new FormData();
    formData.append("venueId", id);  

    if (timeOptions && timeOptions.length > 0) {
      timeOptions.forEach((item, index) => {
        if (item.checked) {
          formData.append(`days[]`, days[index].toString().toLowerCase());
          formData.append(`open_time[]`, item.open);
          formData.append(`close_time[]`, item.close);
        }
      });
    }

   

    const response = await putRequestForm("venue/update", formData);   

    if (response.status == 1) {
      toast.success(response.message);     
    } else {
      toast.error(response.message);
    }
  }; 

  useEffect(() => {
    VenueFetchDetail()    
  }, []);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  }

  const updateTiming = (index, objName, value) => {
    const newTimeOptions = [...timeOptions];
    newTimeOptions[index][objName] = value;
    setTimeOptions(newTimeOptions);
  };

  const handlerdelete = (id, url, obj, key ) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {    

      
      if (result.isConfirmed) {
        const response = await deleteRequest(`${url}`, obj);

        if (response.status) {
          Swal.fire("Deleted!", "Deleted successfully");
          // remove from venueData
          const vData = {...venueData};
          vData[key] = vData[key].filter(item => item._id !== id);
          setData(vData);
          
        } else {
          Swal.fire("Error!", "Something went wrong");
        }
      }
    });
  };

  const handlerToggle = async (url, obj) => {
      await deleteRequest(`${url}`, obj);
  };

  const updateReply = async () => {
    const response = await putRequest("rating/reply/update", { ratingId: selectedReview._id, reply: textReply });  
    
    // update in venueData as well
    const selReview = selectedReview

    if(selReview.reply?.reply){
      selReview.reply.reply = textReply;
    }else{
      selReview.reply = { reply: textReply }
    }
    
    setSelectedReview(selReview);

    setOpen(false);

    if (response.status == 1) {
      toast.success("reply Updated");     
    } else {
      toast.error(response.message);
    }
  }

  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };

 
  

  const { business_id, name, about, address, logo, cover, phone, email, website, booking_url, menu_url, dress_code, createdAt, updatedAt, location } = venueData;

  


  return (  

    <>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Reply to Review</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please enter your reply to the review here.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Reply"
              type="text"
              fullWidth
              multiline
              defaultValue={ selectedReview?.reply?.reply ? selectedReview?.reply?.reply : ''  }
              onChange={(e) => {
                setTextReply(e.target.value)
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={updateReply} color="primary">
              Reply
            </Button>
          </DialogActions>
        </Dialog>
    
    
     <ToastContainer />
      <Row>
          <Col className='10'>
          <div className="page-title-box">
            <h4>Venue Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Management</li>
              : <li className="breadcrumb-item">Venue details</li>
            </ol>
          </div>
        </Col>
          <div className='col-md-2 text-end' >
            <button type="button" class="btn btn-primary btn-lg" onClick={() => history.back()}><i class="fas fa-chevron-left"></i> Back</button>
          </div>       

        {loader ? <CardBody style={{ height: "100px" }}>
          <Spinner
            color="info"
            style={{
              height: '4rem',
              width: '4rem',
              position: "absolute",
              left: "50%"
            }}
          >
            Loading...
          </Spinner>
        </CardBody> : <>
        <Card className='custom-card'>
    <div className="cover-image" style={{ backgroundImage: `url(${cover})` }}>
       
    </div>
    <div className="profile-image">

      <img src={logo} alt="Image Not Available" />
    </div><br></br>

    <div className='row my-2 py-2'>
      <div className="userinfo col-md-12 " style={{paddingLeft:"20px"}}>
        <div className="fst"> <h3> {name} </h3> </div>
        <div className='row'>
          <div className="col-md-4 fst py-1"> <p> Business Name:  <span> {business_id?.name}</span> </p> </div>
          <div className="col-md-4 fst py-1"> <p> Phone:  <span> {phone}</span> </p> </div>
          <div className="col-md-4 fst py-1"> <p> Email:   <span> {email}</span> </p> </div>
          <div className="col-md-4 fst py-1"> <p> Address:   <span> {address}</span> </p> </div>
          <div className="col-md-4 fst py-1"> 
            <p> Website:   
              <span> 
                <a href={website} target="_blank" rel="noopener noreferrer">
                  Visit Website
                </a>
              </span> 
            </p> 
          </div>
          {booking_url && (
            <div className="col-md-4 fst py-1"> 
              <p> Booking URL:   
                <span> 
                  <a href={booking_url} target="_blank" rel="noopener noreferrer">
                    Book Now
                  </a>
                </span> 
              </p> 
            </div>
          )}
          {menu_url && (
            <div className="col-md-4 fst py-1"> 
              <p> Menu URL:   
                <span> 
                  <a href={menu_url} target="_blank" rel="noopener noreferrer">
                    View Menu
                  </a>
                </span> 
              </p> 
            </div>
          )}

          <div className="col-md-4 fst py-1"> <p> Dress Code:   <span> {dress_code}</span> </p> </div>
          <div className="col-md-4 fst py-1"> 
            <p> Created At:   
              <span> 
                {formatDate(createdAt)}
              </span> 
            </p> 
          </div>
          <div className="col-md-4 fst py-1"> 
            <p> Updated At:   
              <span> 
                {formatDate(updatedAt)}
              </span> 
            </p> 
          </div>  
        </div>  
        <hr />     
        

        <div className='row'>
          <div className="col-md-12 fst py-1"> 
            <p> About:   <span> {about}</span> </p> 
          </div>
        </div>
      </div>
    </div>
  </Card>



  <div id="exTab1" className="twitter-like-tabs">
    <ul className="nav nav-pills">

        <li className="active">
            <a href="#location" data-toggle="tab"> Location </a>
        </li>

        <li >
            <a href="#timings" data-toggle="tab"> Timings </a>
        </li>

        <li >
            <a href="#music" data-toggle="tab"> Music </a>
        </li>
        <li >
            <a href="#features" data-toggle="tab"> Features </a>
        </li>
        <li >
            <a href="#themes" data-toggle="tab"> Themes </a>
        </li>
        <li >
            <a href="#cuisine" data-toggle="tab"> Cuisine </a>
        </li>

        <li>
            <a href="#offers" data-toggle="tab"> Offers</a>
        </li>

        <li>
            <a href="#special-offers" data-toggle="tab">Special Offers</a>
        </li>

        <li>
            <a href="#cliams" data-toggle="tab">Claims</a>
        </li>

        <li>
            <a href="#gallery" data-toggle="tab"> Gallery </a>
        </li>

        <li>
            <a href="#notifications" data-toggle="tab"> Notifications </a>
        </li>

        <li>
            <a href="#reviews" data-toggle="tab"> Reviews </a>
        </li>       

        <li>
            <a href="#following" data-toggle="tab"> Following</a>
        </li>       

        <li>
            <a href="#activeStories" data-toggle="tab"> Active Stories </a>
        </li>
        <li>
            <a href="#stories" data-toggle="tab"> Stories </a>
        </li>        
    </ul>

    <div className="tab-content clearfix">

    <div className="tab-pane active padded-tab" id="location">
    <div className='row'>
          <div className="map-container">
            <LocationPicker
                                containerElement={
                                  <div style={{ height: "100%" }} />
                                }
                                mapElement={<div style={{ height: "350px" }} />}
                                defaultPosition={currentLocation}                             
                              />
            </div>   
            </div>  
        
          </div>
        <div className="tab-pane padded-tab" id="timings">
             <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Update Time
                          </label>
                          <div className="col-sm-9">
                            {days.map((day, index) => (
                              <DayTimePicker
                                updateTiming={updateTiming}
                                currentValue={timeOptions[index]}
                                index={index}
                                key={index}
                                day={day}
                              />
                            ))}
                          </div>
                            
                          <div className="col-sm-6 offset-6" style={{ marginTop: '12px' }}>
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={Updatevenue}
                            >
                              Update
                            </button>
                            </div>   
                    </div>
        </div>
        <div className="tab-pane padded-tab" id="music">
        <div className='row'>
         <div className="col-md-12 fst py-1"> 
           

              { selectedMusic?.length && (
                <TagSelection 
                itemList={venueData?.music_list} 
                selectedItemList={selectedMusic} 
                onSelectionChange={handleMusicSelectionChange} 
                />
              )}
            </div>
            </div>
             
        </div>

        <div className="tab-pane padded-tab" id="features">
        <div className='row'>
        <div className="col-md-12 fst py-1"> 
          

            { selectedFeature?.length && (
              <TagSelection 
              itemList={venueData?.feature_list} 
              selectedItemList={selectedFeature} 
              onSelectionChange={handleFeatureSelectionChange} 
              />
            )}
        </div>
        
            </div>
             
        </div>

        <div className="tab-pane padded-tab" id="themes">
        <div className='row'>
         <div className="col-md-12 fst py-1"> 
           
            { selectedTheme?.length && (
            <TagSelection 
            itemList={venueData?.theme_list} 
            selectedItemList={selectedTheme} 
            onSelectionChange={handleThemeSelectionChange} 
            />
            )}
            </div>
            </div>
             
        </div>

        <div className="tab-pane padded-tab" id="cuisine">
        <div className='row'>
         <div className="col-md-12 fst py-1"> 
           

                  { selectedCuisine?.length && (
                <TagSelection 
                itemList={venueData?.cuisine_list} 
                selectedItemList={selectedCuisine} 
                onSelectionChange={handleCuisineSelectionChange} 
                />
              )}
            </div>
            </div>             
        </div>

        <div className="tab-pane padded-tab" id="offers">
        <TableContainer component={Paper}>
  <Table className={classes.table} aria-label="simple table">
    <TableHead>
      <TableRow>
      <TableCell className={classes.tableHeader}>#</TableCell>
        <TableCell className={classes.tableHeader}>Title</TableCell>
        <TableCell className={classes.tableHeader}>Description</TableCell>
        <TableCell className={classes.tableHeader}>Days</TableCell>
        <TableCell className={classes.tableHeader}>Start Time</TableCell>
        <TableCell className={classes.tableHeader}>End Time</TableCell>      
        <TableCell className={classes.tableHeader}>Delete</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {venueData?.offers?.map((offer, index) => (
        <TableRow key={offer._id}>
           <TableCell>{index+1}</TableCell>
          <TableCell><Typography variant="subtitle1">{offer.title}</Typography></TableCell>
          <TableCell><Typography variant="body2" className={classes.descriptionText}>{offer.description}</Typography></TableCell>
          <TableCell>{offer.days}</TableCell>
          <TableCell>{format(new Date(offer.startTime), "PPpp")}</TableCell>
          <TableCell>{format(new Date(offer.endTime), "PPpp")}</TableCell>         
          <TableCell>
            <DeleteIcon onClick={ () => handlerdelete(offer._id, 'offer/delete', {offerId: offer._id }, 'offers')  } className={classes.deleteIcon} />
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>
          </div>

        <div className="tab-pane padded-tab" id="special-offers">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="special offers table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>#</TableCell>
                <TableCell className={classes.tableHeader}>Title</TableCell>
                <TableCell className={classes.tableHeader}>Description</TableCell>
                <TableCell className={classes.tableHeader}>Type</TableCell>
                <TableCell className={classes.tableHeader}>Claim Code</TableCell>
                <TableCell className={classes.tableHeader}>Created At</TableCell>
                <TableCell className={classes.tableHeader}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {venueData?.offers_specials?.map((offer, index) => (
                <TableRow key={offer._id}>

                  <TableCell>{index+1}</TableCell>
                  <TableCell><Typography variant="subtitle1">{offer.title}</Typography></TableCell>
                  <TableCell><Typography variant="body2" className={classes.descriptionText}>{offer.description}</Typography></TableCell>
                  <TableCell>{offer.type}</TableCell>
                  <TableCell>{offer.claimCode}</TableCell>
                  <TableCell>{format(new Date(offer.createdAt), "PPpp")}</TableCell>
                  <TableCell>
                    <DeleteIcon onClick={ () => handlerdelete(offer._id, 'offer/special/delete', {specialOfferId: offer._id }, 'offers_specials')  } className={classes.deleteIcon} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>


        <div className="tab-pane padded-tab" id="cliams">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="special offers table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>#</TableCell>
                <TableCell className={classes.tableHeader}>User</TableCell>
                <TableCell className={classes.tableHeader}>Offer Title</TableCell>
                <TableCell className={classes.tableHeader}>offer Description</TableCell>
                <TableCell className={classes.tableHeader}>Type</TableCell>
                <TableCell className={classes.tableHeader}>Claim Code</TableCell>
                <TableCell className={classes.tableHeader}>Cliamed At</TableCell>
                <TableCell className={classes.tableHeader}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {venueData?.cliams?.map((offer, index) => (
                <TableRow key={offer._id}>

                  <TableCell>{index+1}</TableCell>
                  <TableCell>                    
                  <div style={{display: "flex"}}>
                      <Avatar src={offer?.userId?.image} className={classes.roundImageSm} />
                      <p style={{ margin: "10px 15px"  }}>{`${offer?.userId?.first_name} ${offer?.userId?.last_name}`}</p>
                    </div>
                    </TableCell>
                  <TableCell><Typography variant="subtitle1">{offer?.specialOfferId?.title}</Typography></TableCell>
                  <TableCell><Typography variant="body2" className={classes.descriptionText}>{offer?.specialOfferId?.description}</Typography></TableCell>
                  <TableCell>{offer?.specialOfferId?.type}</TableCell>
                  <TableCell>{offer?.specialOfferId?.claimCode}</TableCell>
                  <TableCell>{format(new Date(offer.createdAt), "PPpp")}</TableCell>
                  <TableCell>
                  <DeleteIcon onClick={ () => handlerdelete(offer._id, 'offer/special/claim/delete', {cliamId: offer._id }, 'cliams')  } className={classes.deleteIcon} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>



        <div className="tab-pane padded-tab" id="gallery">
          {/* <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="galleries table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>#</TableCell>
                <TableCell className={classes.tableHeader}>Title</TableCell>
                <TableCell className={classes.tableHeader}>Image</TableCell>
                <TableCell className={classes.tableHeader}>Status</TableCell>
                <TableCell className={classes.tableHeader}>Created At</TableCell>
                <TableCell className={classes.tableHeader}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {venueData?.galleries?.map((gallery, index) => (
                <TableRow key={gallery._id}>
                  <TableCell><Typography variant="subtitle1">{index+1}</Typography></TableCell>
                  <TableCell><Typography variant="subtitle1">{gallery.title}</Typography></TableCell>
                  <TableCell>
                    <img src={gallery.image} className={classes.squareImage} alt={gallery.title} width="80" />
                  </TableCell>
                  <TableCell>{gallery.status ? "Active" : "Inactive"}</TableCell>
                  <TableCell>{format(new Date(gallery.createdAt), "PPpp")}</TableCell>
                  <TableCell>
                  <DeleteIcon onClick={ () => handlerdelete(gallery._id, 'gallery/delete', {galleryId: gallery._id }, 'galleries')  } className={classes.deleteIcon} />                  
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> */}

          <GridList cellHeight={160} className={classes.gridList} cols={3}>
            {venueData?.galleries?.map((gallery, index) => (
              <GridListTile onClick={() => showLightBox(gallery.image)} key={gallery._id} cols={1}>
                <img src={gallery.image} alt={gallery.title} />
                <GridListTileBar
                  title={gallery.title}
                  subtitle={<span>Uploaded On: { formatDate(gallery.createdAt) }</span>}
                  actionIcon={
                    <IconButton
                      className={classes.icon}
                      onClick={() =>
                        handlerdelete(gallery._id, 'gallery/delete', {galleryId: gallery._id }, 'galleries')
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                />
              </GridListTile>
            ))}
          </GridList>

            {/* <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column">
              {venueData?.galleries?.map((gallery, index) => (
                <div key={gallery._id}>
                  <img src={gallery.image} alt={gallery.title} className={classes.galleryImage} />
                  <GridListTileBar
                    title={gallery.title}
                    subtitle={<span>Uploaded On: { formatDate(gallery.createdAt) }</span>}
                    actionIcon={
                      <IconButton
                        className={classes.icon}
                        onClick={() =>
                          handlerdelete(gallery._id, 'gallery/delete', {galleryId: gallery._id }, 'galleries')
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    }
                  />
                </div>
              ))}
            </Masonry> */}
        </div>

        <div className="tab-pane padded-tab" id="notifications">
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="special offers cliams table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>#</TableCell>
                <TableCell className={classes.tableHeader}>Title</TableCell>
                <TableCell className={classes.tableHeader}>Description</TableCell>
                <TableCell className={classes.tableHeader}>Image</TableCell>
                <TableCell className={classes.tableHeader}>Type</TableCell>
                <TableCell className={classes.tableHeader}>Content Name</TableCell>
                <TableCell className={classes.tableHeader}>Target User</TableCell>
                <TableCell className={classes.tableHeader}>Created At</TableCell>
                <TableCell className={classes.tableHeader}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {venueData?.notifications?.map((offer, index) => (
                <TableRow key={offer._id}>

                  <TableCell>{index+1}</TableCell>
                  <TableCell><Typography variant="subtitle1">{offer?.title}</Typography></TableCell>
                  <TableCell><Typography variant="body2" className={classes.descriptionText}>{offer?.description}</Typography></TableCell>
                  <TableCell>
                    <img src={offer?.image} alt="Image Not Available" style={{ width: "80px" }} />
                  </TableCell>
                  <TableCell>{offer?.type}</TableCell>
                  <TableCell>{offer?.type  == 'General' || offer?.type  == 'general'  ? '' : offer?.type  == 'Link' ? offer?.link :  offer?.type  == 'Venue' ? offer?.venueData?.name : offer?.type  == 'Offer' ? offer?.offerData?.title : offer?.categoryId  }</TableCell>
                  <TableCell>{offer?.targetUser}</TableCell> 
                  <TableCell>{format(new Date(offer.createdAt), "PPpp")}</TableCell>
                  <TableCell>
                     <DeleteIcon onClick={ () => handlerdelete(offer._id, 'notification/delete', {notificationId: offer._id }, 'notifications')} className={classes.deleteIcon} /> 
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>

        <div className="tab-pane padded-tab" id="reviews">
        <TableContainer component={Paper}>
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>#</TableCell>
                <TableCell className={classes.tableHeader}>User</TableCell>
                <TableCell className={classes.tableHeader}>Review</TableCell>
                <TableCell className={classes.tableHeader}>Stars</TableCell>
                <TableCell className={classes.tableHeader}>Reply</TableCell>
                <TableCell className={classes.tableHeader}>Date</TableCell>
                <TableCell className={classes.tableHeader}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {venueData?.reviews?.map((review, index) => (
                <TableRow key={review._id}>
                  <TableCell component="th" scope="row">
                    {index + 1}
                  </TableCell>
                  <TableCell>
                    <div style={{display: "flex"}}>
                      <Avatar src={review.user.image} className={classes.roundImageSm} />
                      <p style={{ margin: "10px 15px"  }}>{`${review.user.first_name} ${review.user.last_name}`}</p>
                    </div>
                  
                  </TableCell>
                  <TableCell>{review.review}</TableCell>
                  <TableCell>
                    <StarComponent count={review.stars} />
                  </TableCell>
                  <TableCell>{review?.reply?.reply}</TableCell>
                  <TableCell>{moment(review.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
                  <TableCell>   

                     <Button variant="outlined" color="primary" onClick={() => handleOpen(review)}>
                      Reply
                    </Button> 


                    <DeleteIcon style={{ marginLeft: "10px"  }} onClick={ () => handlerdelete(review._id, 'rating/delete', {ratingId: review._id }, 'reviews')} className={classes.deleteIcon} />                   
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>

        <div className="tab-pane padded-tab" id="following">
        <TableContainer component={Paper}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeader}>#</TableCell>
            <TableCell className={classes.tableHeader}>User</TableCell>          
            <TableCell className={classes.tableHeader}>Date</TableCell>
            <TableCell className={classes.tableHeader}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {venueData?.follows?.map((follow, index) => (
            <TableRow key={follow._id}>
              <TableCell component="th" scope="row">
                {index + 1}
              </TableCell>
              <TableCell>
                <div style={{display: "flex"}}>
                  <Avatar src={follow.user.image} className={classes.roundImageSm} />
                  <p style={{ margin: "10px 15px"  }}>{`${follow.user.first_name} ${follow.user.last_name}`}</p>
                </div>               
              </TableCell>              
              <TableCell>{moment(follow.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</TableCell>
              <TableCell>
              <DeleteIcon onClick={ () => handlerdelete(follow._id, 'follow/delete', {followId: follow._id }, 'follows')} className={classes.deleteIcon} /> 
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </div>

        <div className="tab-pane padded-tab" id="activeStories">
        <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="galleries table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>#</TableCell>
              <TableCell className={classes.tableHeader}>MediaType</TableCell>
              <TableCell className={classes.tableHeader}>ContentType</TableCell>
              <TableCell className={classes.tableHeader}>Media</TableCell>              
              <TableCell className={classes.tableHeader}>Button Text</TableCell>
              <TableCell className={classes.tableHeader}>Created At</TableCell>
              <TableCell className={classes.tableHeader}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {venueData?.active_stories?.map((story, index) => (
              <TableRow key={story._id}>
                <TableCell><Typography variant="subtitle1">{index+1}</Typography></TableCell>
                <TableCell><Typography variant="subtitle1">{story.mediaType}</Typography></TableCell>
                <TableCell><Typography variant="subtitle1">{story.contentType}</Typography></TableCell>
                <TableCell>

                  {story.mediaType === "photo" ? (
                    <img src={story.mediaUrl} className={classes.roundImage} width="50" />
                  ) : (
                    <video  width="75" height="75" controls>
                      <source src={story.mediaUrl} type="video/mp4" />
                    </video>
                  )}
                  
                </TableCell>
                <TableCell>{story.buttonText}</TableCell>
                <TableCell>{format(new Date(story.createdAt), "PPpp")}</TableCell>
                <TableCell>
                  <DeleteIcon onClick={ () => handlerdelete(story._id, 'story/delete', {storyId: story._id }, 'active_stories')} className={classes.deleteIcon} /> 
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        </div>

        <div className="tab-pane padded-tab" id="stories">
        <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="galleries table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>#</TableCell>
              <TableCell className={classes.tableHeader}>MediaType</TableCell>
              <TableCell className={classes.tableHeader}>ContentType</TableCell>
              <TableCell className={classes.tableHeader}>Media</TableCell>              
              <TableCell className={classes.tableHeader}>Button Text</TableCell>
              <TableCell className={classes.tableHeader}>Created At</TableCell>
              <TableCell className={classes.tableHeader}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {venueData?.stories?.map((story, index) => (
              <TableRow key={story._id}>
                <TableCell><Typography variant="subtitle1">{index+1}</Typography></TableCell>
                <TableCell><Typography variant="subtitle1">{story.mediaType}</Typography></TableCell>
                <TableCell><Typography variant="subtitle1">{story.contentType}</Typography></TableCell>
                <TableCell>

                  {story.mediaType === "photo" ? (
                    <img src={story.mediaUrl} className={classes.roundImage} width="50" />
                  ) : (
                    <video  width="75" height="75" controls>
                      <source src={story.mediaUrl} type="video/mp4" />
                    </video>
                  )}
                  
                </TableCell>
                <TableCell>{story.buttonText}</TableCell>
                <TableCell>{format(new Date(story.createdAt), "PPpp")}</TableCell>
                <TableCell>
                <DeleteIcon onClick={ () => handlerdelete(story._id, 'story/delete', {storyId: story._id }, 'stories')} className={classes.deleteIcon} /> 
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        </div>
    </div>
</div>



        </>}

        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}

      </Row>
      <ToastContainer /> </>
  )

  
}