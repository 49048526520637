import React, {useEffect,useState,Component} from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import { getRequest } from "../../components/Common/Utils.js";



const uData = {
    options: {
        colors: ['#28bbe3', '#7a6fbe', '#28bbe3','#f5b225','#f1734f'],
        chart: {
            toolbar: {
                show: !1,
            },
        },
        dataLabels: {
            enabled: !1
        },
        stroke: {
            curve: 'smooth',
            width: 0.1,
        },
        grid: {
            borderColor: '#f8f8fa',
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
            },
        },
        xaxis: {
            categories: ['2023', '2024'],
            axisBorder: {
                show: !1
            },
            axisTicks: {
                show: !1
            }
        },
        legend: {
            show: !1
        },
    },
    series: [
        {
            name: 'Users',
            data: [0, 150, 60, 180, 90, 75, 30]
        }       
    ],
}

class MonthlyEarnings extends Component {
    constructor(props) {
        super(props);

        this.state = {
           options : uData.options,
            series : uData.series 
        }
    }

    componentDidMount(){
        this.fetchData();
    };

    fetchData = async () => {
        const response = await getRequest(`user/DashboardGraph`);
        const data = response.data;

        const categories = data.map((item) => item.Date);
        const users = data.map((item) => item.Users);
        
        categories.reverse();
        users.reverse();

        const series = [
            {
                name: 'Users',
                data: users
            }
        ];

        this.setState({
            options: {
                ...this.state.options,
                xaxis: {
                    ...this.state.options.xaxis,
                    categories: categories
                }
            },
            series: series
        });

        console.log(data);
    }

    render() {
        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Users Graph</h4>

                        {/* <Row className="text-center mt-4">
                            <Col xs="6">
                                <h5 className="font-size-20">$ 89425</h5>
                                <p className="text-muted">Total Users</p>
                            </Col>
                            <Col xs="6">
                                <h5 className="font-size-20">$ 56210</h5>
                                <p className="text-muted">Total Venues</p>
                            </Col>
                           
                        </Row> */}

                        <div id="morris-area-example" className="morris-charts morris-charts-height" dir="ltr">
                            <ReactApexChart options={this.state.options} series={this.state.series} type="area" height="300" />
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default MonthlyEarnings;