import React, { useState, useEffect } from "react";
import { getRequest, postRequest } from "../Common/Utils.js";
import { Link, NavLink } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Label,
  Badge,
  UncontrolledTooltip,
  Modal,  
  ModalFooter,
  FormGroup,
  Input,
  Button,
  Form,
} from "reactstrap";

const FormComponent = ({updateValue}) => { 

  return (
    <>
      <React.Fragment>
        <Row>
          <Col className="col-12">
            <Card>
              <CardBody className="table-shorting">
                <Form>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                                      <div className="mt-5 mt-lg-4">
                                      <Form>
                        <Row>
                        <Col lg={3}>                           
                          </Col>
                          <Col lg={6}>
                            <FormGroup>
                              <Label for="startDate">Enter feature:</Label>
                              <Input type="text" id="startDate"  onChange={(e) => updateValue(e.target.value)} name="newFeature" />
                            </FormGroup>
                          </Col>
                          <Col lg={3}>                           
                          </Col>                        
                        </Row>
                      </Form>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    </>
  );
};

const FeatureModal = ({ show, closeModal, updateValue, onSubmit }) => {
  return (
    <Modal
      isOpen={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered={true}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Add New Feature
        </h5>
        <button
          onClick={() => {
            closeModal();
          }}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div className="modal-body">
        <FormComponent updateValue={updateValue}/>
      </div>
      <ModalFooter>
        <Button color="info" onClick={onSubmit}>Submit</Button>
      </ModalFooter>
    </Modal>
  );
};

export default FeatureModal;
