import PropTypes from "prop-types";
import MetaTags from "react-meta-tags";
import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import logo from "../../assets/images/logo.png";

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";
import { loginUser, apiError } from "../../store/actions";
// import images
import { postRequest } from "components/Common/Utils";

const Login = (props) => {
  let history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  async function login() {
    
    let data = { loginId: email, password };
    if (rememberMe) {
      localStorage.setItem(
        "rememberedUser",
        JSON.stringify({ loginId: email, password })
      );
    } else {
      localStorage.removeItem("rememberedUser");
    }

    postRequest("admin/login", data).then((data) => {
      let log = data;
      if (log.status) {
        toast.success(log.message);
        localStorage.setItem("authUser", JSON.stringify(log.data));
        localStorage.setItem("authType", "admin");
        localStorage.setItem("token", log.data.token);
        setTimeout(() => {
          history.push("/dashboard");
        }, 1000);
      } else {
        toast.error(log.message);
      }
    });
  }

  useEffect(() => {
    const rememberedUser = localStorage.getItem("rememberedUser");
    if (rememberedUser) {
      const { loginId, password } = JSON.parse(rememberedUser);
      setEmail(loginId);
      setPass(password);
      setRememberMe(true);
      // history.push("/dashboard");
    }
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <div class="text-center" style={{ padding: 20 }}>
                    <img src={logo} alt="" height="40" className="auth-logo-light"  /> 
                  </div>
                  <div className="">
                    <h4 className="text-muted font-size-18 mb-1 text-center">
                      Welcome Back !
                    </h4>
                    <p className="text-muted text-center">
                      Sign in to continue to dashboard
                    </p>
                    <AvForm
                      className="form-horizontal mt-4"
                      onSubmit={(e) => handleValidSubmit(e)}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="loginId"
                          label="Email"
                          value={email}
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          value={password}
                          type="password"
                          required
                          onChange={(e) => setPass(e.target.value)}
                          placeholder="Enter Password"
                        />

                      </div>
                      <div className="mb-3">
                 
                      <Link to="/PasswordReset" className="password-reset" style={{ color: "#EC672C !important" }}>
                          Password Reset
                        </Link>

                      </div>

                      <div className="mb-3 row mt-4">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                              checked={rememberMe}
                              onChange={() => setRememberMe(!rememberMe)}
                            />

                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div className="col-6 text-end">
                          <button
                            className="btn btn-orange w-md waves-effect waves-light"
                            type="submit"
                            onClick={login}
                          >
                            Log In
                          </button>
                        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <a href="agent/login" className="text-muted font-size-14 mb-1 text-center password-reset" >Login As Agent</a>
                      </div>
                      
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
};
