import Swal from "sweetalert2";
import { Button } from "reactstrap";
export const RBButton = Button;

const BaseURL =
  //  "http://:9000/v1/" 
  //  "http://117.97.155.178:9000/v1/"
  //  "http://localhost:9000/v1/"  
  "https://api.yalatorapp.com/v1/"
  // "http://165.22.221.98:9000/v1/"
// "http://192.168.1.5:9000/v1/";

const getheader = async () => {
  let token = localStorage.getItem("token");
  let header = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
  };
  return header;
};

let token = localStorage.getItem("token");
let header = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
};
let headerForm = {
  Authorization: `Bearer ${token}`,
};
export const postRequestForm = async (endpoint, body) => {
  let url = BaseURL + endpoint;
  console.log("POST Request URL : ", url);
  console.log("body", body);

  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "POST", headers: headerForm, body: body })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export const putRequestForm = async (endpoint, body) => {
  let url = BaseURL + endpoint;
  console.log("POST Request URL : ", url);
  console.log("body", body);

  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "PUT", headers: headerForm, body: body })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

// export const postRequest = async (endpoint, body) => {
//   let url = BaseURL + endpoint;
//   console.log("POST Request URL : ", url);
//   const promise = new Promise(async (resolve) => {
//     await fetch(url, {
//       method: "POST",
//       headers: header,
//       body: JSON.stringify(body),
//     })
//       .then((response) => response.json())
//       .then((data) => resolve(data));
//   });
//   return promise;
// };
export const putRequestJson = async (endpoint, body) => {
  let url = BaseURL + endpoint;
  console.log("POST Request URL : ", url);
  const promise = new Promise(async (resolve) => {
    await fetch(url, {
      method: "PUT",
      headers: header,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

// export const postRequest2 = async (endpoint, body) => {
//     let url = BaseURL2 + endpoint;
//     console.log("POST Request URL : ", url)
//     const promise =  new  Promise(async (resolve) => {
//         await fetch(url, { method: 'POST', headers: header, body: JSON.stringify(body) }).then((response) => response.json())
//         .then((data) => resolve(data));
//     });
//     return promise;
// }

export const postRequest = async (endpoint, body) => {
  try {
    let header = await getheader();
    let url = BaseURL + endpoint;
    console.log("POST Request URL : ", url);

    const response = await fetch(url, {
      method: "POST",
      headers: header,
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in POST request:", error);
    throw error;
  }
};

export const getRequest = async (endpoint, body = null) => {
  let url = BaseURL + endpoint;

  if (body != null) {
    const promise = new Promise(async (resolve) => {
      await fetch(url, {
        method: "Get",
        headers: header,
        body: JSON.stringify(body),
      })
        .then((response) => response.json())
        .then((data) => resolve(data));
    });

    return promise;
  }

  console.log("Get Request URL : ", url);
  const promise = new Promise(async (resolve) => {
    await fetch(url, { method: "Get", headers: header })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};
export const deleteRequest = async (endpoint, body) => {
  let url = BaseURL + endpoint;
  console.log("DELETE Request URL : ", url);
  const promise = new Promise(async (resolve) => {
    await fetch(url, {
      method: "DELETE",
      headers: header,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export const putRequest = async (endpoint, body) => {
  let url = BaseURL + endpoint;
  console.log("PUT Request URL : ", url);
  const promise = new Promise(async (resolve) => {
    await fetch(url, {
      method: "PUT",
      headers: header,
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((data) => resolve(data));
  });
  return promise;
};

export function DeleteConfirmDialog() {
  const promise = new Promise(async (resolve, reject) => {
    try {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        resolve(result);
      });
    } catch (msg) {
      reject(msg);
    }
  });
  return promise;
}

export function convertTimeToFormattedString(timestampString) {
  if (!timestampString) {
    return timestampString;
  }

  // Convert to Date object
  const dateObj = new Date(timestampString);

  // Check if the date object is valid
  if (isNaN(dateObj.getTime())) {
    return timestampString;
  }

  // Extract date, month, year, hours, and minutes
  const date = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = dateObj.getFullYear();

  let hours = dateObj.getHours();
  const minutes = String(dateObj.getMinutes()).padStart(2, "0");

  // Determine AM or PM
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Construct formatted string
  return `${date}/${month}/${year} ${hours}:${minutes} ${ampm}`;
}

export const deleteConfirmationWords = ["Delete", "Remove", "Erase"];
