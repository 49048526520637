import React, { useState, useEffect } from "react";
import { useHistory,useParams, useLocation  } from "react-router-dom";
import { Row, Col, Card, CardBody, CardTitle, FormGroup, Form, Table, Spinner } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import {  postRequestForm, postRequest } from "../../components/Common/Utils.js";

export default function CreateVenueStory() {
  const location = useLocation();
  const state = location.state?.row;
  

  const [storyData, setStoryData] = useState(null);  
  const [loading, setLoading] = useState(false); 
  const [previewImage, setPreviewImage] = useState(""); 
  const [selectedImage, setSelectedImage] = useState(""); 
  const [venueList, setVenueList] = useState([]);

 

  let history = useHistory();

  const { id } = useParams(); 
  
  useEffect(() => {
    if(!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result)
    }
    reader.readAsDataURL(selectedImage)
  }, [selectedImage])
  
  useEffect(() => {
    fetchList();
    }, []);

    const fetchList = async () => {
       //  setLoading(true);
      const response = await postRequest("venue/list", { limit: 10000000});
      if (response.status == 1) {
        setVenueList(response.data.list);
      }
      setLoading(false);
    };
  
 
  

  const Createstory = async (e) => {
    e.preventDefault();
    if (!storyData.mediaType) {
      toast.error("Please select media type");
      return;
    }
    //media type is video then add file video
    if (!selectedImage) {
      toast.error("Please select video / image");
      return;
    }

    if (!storyData.contentType) {
      toast.error("Please select content type");
      return;
    }
    if (!storyData.buttonText) {
      toast.error("Please enter button text");
      return;
    }

    //  setLoading(true);
  
    const formData = new FormData();
    formData.append("venueId", id);
    formData.append("mediaUrl", selectedImage);
    formData.append("mediaType", storyData.mediaType); 
    formData.append("contentType", storyData.contentType);
    formData.append("buttonText", storyData.buttonText);
   
   
    let url = "story/create";	
    if(storyData.mediaType == 'video'){
      url = "story/create/video";
    }
    
    const response = await postRequestForm(url, formData);
    setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/story/" + id);
      }, 1000);
      
    } else {
      toast.error(response.message);
    }  
    
  };

  const updateValue = (index, value) => {
    const list = {...storyData}
    list[index] = value
    setStoryData(list)
    
  } 
    
 
    

  return (
    <React.Fragment>

      <ToastContainer />

      <div claaName="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Assign Venue Story</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Venue Story</li>:{" "}
              <li className="breadcrumb-item">
              Assign Story
              </li>
            </ol>
          </div>
        </div>
      </div>
     
      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>

          { loading ? 
                <CardBody style={{height : "100px"}}>
                <Spinner
                    color="info"
                    style={{
                      height: '4rem',
                      width: '4rem',
                      position : "absolute",
                      left: "50%"
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
                :

            <CardBody>
              <h4 className="card-title"> Assign Story </h4>
              <Form onSubmit={Createstory}>
                <Row>
                  <Col lg={12} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">

                         
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Media Type</label>
                            <div className="col-sm-6">
                              <select   value={storyData?.mediaType} onChange={ (e) => updateValue('mediaType', e.target.value) } class="form-select">
                                   <option>  Select</option>
                                   <option value="photo">Photo</option>
                                   <option value="video">Video</option>
                                 </select>
                            </div>                           
                        </div>      

                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Content Type</label>
                            <div className="col-sm-6">
                              <select   value={storyData?.contentType} onChange={ (e) => updateValue('contentType', e.target.value) } class="form-select">
                                   <option>  Select</option>
                                   <option value="business">Business</option>
                                   <option value="offer">Offer</option>
                                   <option value="web">Web</option>
                                 </select>
                            </div>                           
                        </div>                              

                       
                        

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            File
                          </label>
                          <div className="col-sm-6">
                            {storyData?.mediaType == "video" ? (
                              <input
                                type="file"
                                accept="video/*"
                                onChange={(e) =>
                                  setSelectedImage(e.target.files[0])
                                }
                                name="mediaUrl"
                                className="form-control"
                                id="horizontal-password-input"
                                required="required"
                              />
                            ) : (
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) =>
                                  setSelectedImage(e.target.files[0])
                                }
                                name="mediaUrl"
                                className="form-control"
                                id="horizontal-password-input"
                                required="required"
                              />
                            )}

                            <br />
                            {storyData?.mediaType == "video"
                              ? null
                              : previewImage && (
                                  <img
                                    src={previewImage}
                                    style={{ width: "100px", height: "100px" }}
                                  />
                                )}
                          </div>
                        </div>
                        
                        <div className="row mb-4">
                            <label  className="col-sm-3 col-form-label">Button Text</label>
                            <div className="col-sm-6">
                            <input type="text"  onChange={ (e) => updateValue('buttonText', e.target.value) } value={storyData?.buttonText} name='buttonText'  className="form-control"  />
                            </div>
                        </div>                
             
                       
                        <div className="mt-5 mt-lg-4">
                            <div className="row justify-content-end">
                            <div className="col-sm-9">
                            <div>
                                <button type="submit"  className="btn btn-primary w-md">Submit</button>
                            </div>
                            </div>
                            </div>
                        </div>

                    </div>
                  </Col>
                </Row>
              </Form>
            </CardBody> }
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
