import React from "react"
import { Container, Row, Col } from "reactstrap"

const Footer = () => {
  // return (
  //   <React.Fragment>
  //     <footer className="footer">
  //       <Container fluid={true}>
  //         <Row>
  //           <Col sm={12}>
  //             © {new Date().getFullYear()} Howler <span className="d-none d-sm-inline-block"> - Crafted with <i className="mdi mdi-heart text-danger"></i> by Themesbrand.</span>
  //           </Col>
  //         </Row>
  //       </Container>
  //     </footer>
  //   </React.Fragment>
  // )

  return null;
}

export default Footer
