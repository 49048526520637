
import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
} from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { MultiSelect } from "react-multi-select-component";
import {
  DeleteConfirmDialog,
  deleteRequest,
  getRequest,
  postRequest,
  postRequestForm,
  putRequest,
  putRequestForm,
} from "components/Common/Utils";

import ModuleAccessControl from './ModuleAccessControl';
import moduleAccessData from './moduleAccessData.json';


export default function CreateSubAdmin() {

  const [showPassword, setShowPassword] = useState(false);
  const initialAccessSchema = moduleAccessData.moduleAccessSchema;
  const [moduleAccessSchema, setModuleAccessSchema] = useState(initialAccessSchema);

  const handleUpdate = (updatedModuleAccessSchema) => {
    // Handle or send the updated data as per your requirement.
    setModuleAccessSchema(updatedModuleAccessSchema);
    console.log("updatedModuleAccessSchema", updatedModuleAccessSchema);
  };


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  let history = useHistory();

  const updateuserdata = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);

    let dataToSend = {}
    for (let [key, value] of data.entries()) {
      dataToSend[key] = value;
    }
    dataToSend["moduleAccess"] = moduleAccessSchema; 

    console.log(dataToSend);
    postRequest("admin/sub-admin", dataToSend).then((data) => {
      let status = data;
      console.log(data);
      if (status.status == 1) {
        toast.success(status.message);
        setTimeout(() => {
          history.push("/SubAdminlist");
        }, 1000);
      } else {
        toast.error(status.message);
      }
    });
    //  }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="row d-flex flex-row">
        <div className="col-md-10">
          <div className="page-title-box">
            <h4>Sub Admin </h4>
            <div></div>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Sub Admin</li>:{" "}
              <li className="breadcrumb-item">
                <Link to="#">Sub Admin Create</Link>
              </li>
            </ol>
          </div>
        </div>
        <div className="col-md-2 text-end">
          <button
            type="button"
            className="btn btn-primary btn-lg"
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card className="usercard"></Card>
          <Card>
            <CardBody>
              <h4 className="card-title ms-5">Sub Admin Create </h4>
              <form onSubmit={updateuserdata}>
                <Row>
                  <Col lg={5} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          First name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="firstName"
                            className="form-control"
                            id="horizontal-firstname-input"
                          />
                       
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Email
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            id="horizontal-password-input"
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Password
                        </label>
                        <div className="col-sm-9">
                          <input
                              type={showPassword ? 'text' : 'password'}
                            name="password"
                            className="form-control"
                            id="horizontal-password-input"
                          />
                        
                <i className={showPassword ?"fas fa-eye":"fas fa-eye-slash"}  style={{position:"relative",top:"-50%",right:"-90%"}} onClick={togglePasswordVisibility}></i>
                        </div>
                       
                        {/* <button  className="col-sm-3" type="button" onClick={togglePasswordVisibility}>
          {showPassword ? 'Hide' : 'Show'} Password
        </button> */}
                      </div>
                
                    </div>
                  </Col>

                  <Col lg={6} className="ms-lg-auto">
                    <div className="mt-5 mt-lg-4">
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Last Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            name="lastName"
                            className="form-control"
                            id="horizontal-email-input"
                          />
                        </div>
                      </div>
                 

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-password-input"
                          className="col-sm-3 col-form-label"
                        >
                          Phone
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="tel"
                            name="phone"
                            className="form-control"
                            id="horizontal-password-input"
                          />
                        </div>
                      </div>
                 
                    </div>
                  </Col>

                  <div className="row justify-content-end">
                    <div className=" offset-10 col-sm-2">
                      <div>
                        <button type="submit" className="btn btn-primary w-md">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
      </Row>
      
      <Row>    
      <Col lg={3}>  </Col>
        <Col lg={6}>
           <ModuleAccessControl initialAccessSchema={initialAccessSchema} onUpdate={handleUpdate} />
        </Col>
        <Col lg={3}>  </Col>
      </Row>

    </React.Fragment>
  );
}
