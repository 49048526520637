import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ToastContainer, toast } from "react-toastify";
import {
  DeleteConfirmDialog,
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "components/Common/Utils";

import "sweetalert2/src/sweetalert2.scss";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import axios from "axios";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Spinner,
  Button,
} from "reactstrap";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import ReactPaginate from "react-paginate";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import moment from "moment";

//321
const ComplainManagement = (props) => {
  const { SearchBar } = Search;
  const [pageCount, setpageCount] = useState("");
  const [userdata, setuserdata] = useState([]);
  const [loader, setLoader] = useState(false);

  const [search, setSearch] = useState("");
  let token = localStorage.getItem("token");

  const [userid, setUserid] = React.useState("");
  const [value, setValue] = React.useState(10);
  const [Activepage, setActivepage] = React.useState(0);

  const [openLightbox, setOpenLightBox] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [sizePerPageOrg, setSizePerPage] = useState(10);
  const [sort, setsort] = React.useState("");
  const [sortOrder, setsortOrder] = React.useState("desc");
  const [searchText, setSearchText] = useState("");
  const handleChange = (event) => {
    setSizePerPage(event.target.value);
  };
  const handlesorting = (event) => {
    setsort(event.target.value);
  };

  const handlesortOrder = (event) => {
    setsortOrder(event.target.value);
  };
  const showLightBox = (image) => {
    setImage(image);
    setOpenLightBox(true);
  };


  function createdAtdata(cellContent, row) {
    return (
      <React.Fragment>
        <div>
          <p>{moment(row.createdAt).format("DD-MM-YYYY")} </p>
        </div>
      </React.Fragment>
    );
  }

  function SNoFormatter(cellContent, row, i) {
    return (
      <>
        <span style={{ width: "20px" }}>{i + 1}</span>
      </>
    );
  }

  const columns = [
    {
      text: "S.NO",
      formatter: SNoFormatter,
      sort: false,
    },

    {
      dataField: "first_name",
      text: "First Name",
      sort: false,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: false,
    },

    {
      dataField: "email",
      text: "Email",
      sort: false,
    },

    {
      dataField: "phone",
      text: "Phone No.",
      sort: false,
    },

    {
      dataField: "createdAt",
      text: "createdAt",
      sort: true,
      formatter: createdAtdata,
    },

    {
      dataField: "actions",
      text: "Actions",
      formatter: iconFormatter,
      sort: false,
    },
  ];

  function iconFormatter(cellContent, row) {
    return (
      <React.Fragment>
        <div className="d-flex-center">
          <button type="button" className=" btn btn-info mx-2">
            {" "}
            <Link
              to={{
                pathname: `/UpdateSubAdmin`,
                state: { row },
              }}
            >
              {" "}
              <i class="fal fa-pencil fs-5 text-light"></i>
            </Link>{" "}
          </button>
          <button
            type="button"
            className="btn btn-danger mx-2"
            onClick={() => handlerdelete(row._id)}
          >
            <i class="fal fa-trash  fs-5"></i>
          </button>
          &nbsp;&nbsp;
        </div>
      </React.Fragment>
    );
  }

  // Done
  // const limit = 10;
  const ComplainManagementlist = async (page) => {
    // setLoader(true);

    const payload = {
      page: page,
      limit: sizePerPageOrg,
      // page: currentPage,
      sortField: sort,
      sortOrder: sortOrder,
      filterArgs: [
        {
          first_name: searchText,
          type: "regex",
        },
      ],
    };

    console.log({ payload });

    postRequest(`admin/sub-admin/list`, payload).then((response) => {
      const data = response.data.list;
      setuserdata(data);
      const count = response.data.count;
      const totalPage = Math.ceil(count / value);
      setpageCount(totalPage);
      setLoader(false);
      return response.data.list;
    });
  };

  const ComplainManagement = async () => {
    // setLoader(true);

    postRequest(`admin/sub-admin/list`, {}).then((response) => {
      const data = response.data.list;
      setuserdata(data);
      const count = response.data.count;
      const totalPage = Math.ceil(count / value);
      setpageCount(totalPage);
      setLoader(false);
      setSearchText("");
      return response.data.list;
    });
  };

  useEffect(() => {
    ComplainManagementlist();
  }, [sizePerPageOrg, sort, sortOrder]);

  useEffect(() => {
    // ComplainManagement()
  }, []);

  async function handlePageClick(responseData) {
    console.log(" final page", responseData);
    setActivepage(responseData.selected);
    const page = responseData.selected + 1;
    const commentsFormServer = await ComplainManagementlist(page);
    console.log(commentsFormServer);
  }

  const handlerdelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this user",
      icon: "warning",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let body = { userId: id };
        deleteRequest("admin/sub-admin", body).then((data) => {
          let user = data.status;
          console.log(data, "user");
          if (user == 1) {
            Swal.fire("Deleted!", "Deleted successfully");
            ComplainManagementlist();
          }
        });
      }
    });
  };

  return (
    <>
      <React.Fragment>
        <ToastContainer />

        <Row>
          <Col className="12">
            <div className="page-title-box">
              <h4>Complaint Management</h4>

              <ol className="breadcrumb m-0">
                <li className="breadcrumb-item active">
                  {" "}
                  Complaint Management
                </li>
                :{" "}
                <li className="breadcrumb-item"> Complaint Management List</li>
              </ol>
            </div>
          </Col>
          <div className="col-md-2 text-end">
            <Link to="/Complainreplay">
              {" "}
              <Button>
                {" "}
                <i class="fas fa-plus"></i> Create
              </Button>{" "}
            </Link>
            &nbsp;&nbsp;&nbsp;
          </div>
          <Col className="col-12">
            <Card>
              {loader ? (
                <CardBody style={{ height: "100px" }}>
                  <Spinner
                    color="info"
                    style={{
                      height: "4rem",
                      width: "4rem",
                      position: "absolute",
                      left: "50%",
                    }}
                  >
                    Loading...
                  </Spinner>
                </CardBody>
              ) : (
                <CardBody className="table-shorting">
                  {
                    <ToolkitProvider
                      keyField="id"
                      data={userdata}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <Row>
                          <Col className="col-lg-12 d-flex mb-3">
                            <label className="mb-0 text-label">Page Size</label>

                            <select
                              value={sizePerPageOrg}
                              onChange={handleChange}
                              className="form-select"
                              style={{ width: "7%" }}
                            >
                              <option value="10">10</option>

                              <option value="20">20</option>

                              <option value="50">50</option>
                            </select>

                            <label className="mb-0 text-label ms-3">
                              Sort By:
                            </label>
                            <select
                              value={sort}
                              onChange={handlesorting}
                              className="form-select"
                              style={{ width: "10%" }}
                            >
                              <option value="first_name">first Name</option>

                              <option value="last_name"> Last Name</option>

                              <option value="email">Email</option>
                              <option value="phone">Phone</option>
                            </select>

                            <label className="mb-0 text-label ms-3">
                              Sort Order:
                            </label>
                            <select
                              value={sortOrder}
                              onChange={handlesortOrder}
                              className="form-select"
                              style={{ width: "10%" }}
                            >
                              <option value="asc"> Ascending</option>
                              <option value="desc">Descending</option>
                            </select>

                            <div className="filter-item">
                              {/* {selectedItems.length > 0 && (
                                      <button
                                        className="btn btn-danger mx-2"
                                        onClick={() => {
                                          handlerdeleteMultiple(selectedItems);
                                        }}
                                      >
                                        Delete Selected
                                      </button>
                                    )} */}
                              <input
                                type="search"
                                placeholder="Search"
                                value={searchText}
                                class="form-control"
                                onChange={(e) => setSearchText(e.target.value)}
                                style={{ width: "30%" }}
                              />
                              <button
                                onClick={() => {
                                  ComplainManagementlist();
                                }}
                                type="button"
                                className="btn btn-primary mx-2"
                              >
                                Search
                              </button>
                              <button
                                type="button"
                                onClick={ComplainManagement}
                                className="btn btn-danger"
                              >
                                Reset
                              </button>
                              {/* <button
                                      type="button"
                                      // onClick={toggleModel}
                                      className="btn btn-info mx-2"
                                    >
                                      Filter
                                    </button> */}
                            </div>
                          </Col>

                          <Col className="col-lg-12 text-end table-responsive">
                            <BootstrapTable
                              {...props.baseProps}
                              classes="table-striped table-responsive"
                            />
                          </Col>
                        </Row>
                      )}
                    </ToolkitProvider>
                  }

                  <Row>
                    <div className="mt-3">
                      <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-left"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                        forcePage={Activepage}
                      />
                    </div>
                  </Row>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>

        {openLightbox && (
          <Lightbox
            mainSrc={image}
            onCloseRequest={() => setOpenLightBox(false)}
          />
        )}
      </React.Fragment>
    </>
  );
};

export default ComplainManagement;
