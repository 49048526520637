import React, { useState, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  FormGroup,
  Form,
  Table,
  Spinner,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { putRequestForm,postRequestForm } from "../../components/Common/Utils.js";

export default function UpdateCategory() {
  let history = useHistory();
  const location = useLocation();
  const state = location.state?.row;
  console.log({ state });

  const [categoryData, setCategoryData] = useState();
  const [previewImage, setPreviewImage] = useState("");
  const [imageIcon, setImageIcon] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [url, seturl] = useState("");
  const { id } = useParams();

  useEffect(() => {
    
    setCategoryData(state);
    setPreviewImage(state?.image);
    
  }, [state]);

  useEffect(() => {
    if (!selectedImage) return;
    const reader = new FileReader();
    reader.onload = () => {
      setPreviewImage(reader.result);
      setImageIcon("");
    };
    reader.readAsDataURL(selectedImage);
  }, [selectedImage]);

  const Updatecategory = async (e) => {
    e.preventDefault(); 
    const data = new FormData(e.target);
    const FormValues = Object.fromEntries(data.entries());  

    // setLoading(true);

    const formData = new FormData();
    formData.append("categoryId", id);
    formData.append("title", categoryData.title);
    formData.append("titleFrench", categoryData.titleFrench);
    formData.append("titleGerman", categoryData.titleGerman);
    formData.append("description", categoryData.description);
    formData.append("descriptionFrench", categoryData.descriptionFrench);
    formData.append("descriptionGerman", categoryData.descriptionGerman); 
    

    if (selectedImage) {
      formData.append("image", selectedImage);
    }

    const response = await putRequestForm("homepage/category/", formData);
    // setLoading(false);

    if (response.status == 1) {
      toast.success(response.message);
      setTimeout(() => {
        history.push("/category");
      }, 1000);
    } else {
      toast.error(response.message);
    }
  };

  const updateValue = (index, value) => {
    const list = { ...categoryData };
    list[index] = value;
    setCategoryData(list);
  };

  const Imageupload = async (e) => {
    const formData = new FormData();
    formData.append("file", selectedImage);
    const response = await postRequestForm("homepage/upload/image", formData);
    let image = response.data.url;
    console.log({ image });
    seturl(image);
  };

  useEffect(() => {
    Imageupload();
  }, [selectedImage]);

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="row">
        <div className="col-lg-12">
          <div className="page-title-box">
            <h4>Category Management</h4>
            <ol className="breadcrumb m-0">
              <li className="breadcrumb-item active">Category Management</li>:{" "}
              <li className="breadcrumb-item">Update Category</li>
            </ol>
          </div>
        </div>
      </div>

      {/* header */}

      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            {loading ? (
              <CardBody style={{ height: "100px" }}>
                <Spinner
                  color="info"
                  style={{
                    height: "4rem",
                    width: "4rem",
                    position: "absolute",
                    left: "50%",
                  }}
                >
                  Loading...
                </Spinner>
              </CardBody>
            ) : (
              <CardBody>
                <h4 className="card-title"> Update Category </h4>
                <Form onSubmit={Updatecategory}>
                  <Row>
                    <Col lg={12} className="ms-lg-auto">
                      <div className="mt-5 mt-lg-4">

                      <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title (English)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("title", e.target.value)
                              }
                              value={categoryData?.title}
                              name="title"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title (French)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("titleFrench", e.target.value)
                              }
                              value={categoryData?.titleFrench}
                              name="titleFrench"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Title (German)
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="text"
                              onChange={(e) =>
                                updateValue("titleGerman", e.target.value)
                              }
                              value={categoryData?.titleGerman}
                              name="titleGerman"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>


                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description (English)
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("description", e.target.value)
                              }
                              rows="4"
                              cols="50"
                              value={categoryData?.description}
                              name="description"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description (French)
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("descriptionFrench", e.target.value)
                              }
                              rows="4"
                              cols="50"
                              value={categoryData?.descriptionFrench}
                              name="descriptionFrench"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>

                        <div className="row mb-4">
                          <label className="col-sm-3 col-form-label">
                            Description (German)
                          </label>
                          <div className="col-sm-6">
                            <textarea
                              type="textarea"
                              onChange={(e) =>
                                updateValue("descriptionGerman", e.target.value)
                              }
                              rows="4"
                              cols="50"
                              value={categoryData?.descriptionGerman}
                              name="descriptionGerman"
                              className="form-control"
                              required="required"
                            />
                          </div>
                        </div>
                       
                       

                        <div className="row mb-4">
                          <label
                            htmlFor="horizontal-password-input"
                            className="col-sm-3 col-form-label"
                          >
                            Image
                          </label>
                          <div className="col-sm-6">
                            <input
                              type="file"
                              onChange={(e) =>
                                setSelectedImage(e.target.files[0])
                              }
                              name="image"
                              className="form-control"
                              id="horizontal-password-input"
                            />
                            <br />
                            {previewImage && (
                              <img
                                src={previewImage}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}

                            {imageIcon && (
                              <img
                                src={imageIcon}
                                style={{ width: "100px", height: "100px" }}
                              />
                            )}
                          </div>
                        </div>
                       

                        <div className="mt-5 mt-lg-4">
                          <div className="row justify-content-end">
                            <div className="col-sm-6">
                              <div>
                                <button
                                  type="submit"
                                  className="btn btn-primary w-md"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            )}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
